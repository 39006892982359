import React, { useEffect, useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { FormattedMessage, useIntl } from "gatsby-plugin-intl"
import { Form, useFormikContext } from "formik"
import { Tooltip } from "react-tooltip"
import {
  Button,
  Checkbox,
  FieldError,
  LanguageBar,
  PhoneInput,
  TextInput,
} from "@tmu/components/common"
import { useCountries, useSupportedLanguages } from "@tmu/hooks"
import { getAllScreenTypes } from "@tmu/utils/mediaQueries"
import { getFormatPhoneNumber } from "@tmu/utils/formatPhoneNumber"
import theme from "@tmu/global/theme"

import {
  StyledProfileFlagRowContent,
  StyledProfileFlexContainer,
  StyledProfileRowContent,
  StyledProfileSection,
  StyledProfileSectionTitle,
  StyledProfileSpacer,
  StyledPrivacyTooltip,
  StyledVisibleContainer,
} from "./index.styles"
import { Spacer } from "@tmu/src/global/page-addons/detail-page.styles"

const { CLOUDFLARE_IMAGE_URL } = process.env

const GeneralProfileForm = ({
  isMerchantCorporate,
  isEdit,
  onCancel,
  onSave,
}) => {
  const {
    errors,
    initialValues,
    values,
    touched,
    handleChange,
    handleBlur,
    setValues,
    setFieldValue,
    setFieldTouched,
    setFieldError,
  } = useFormikContext()

  const { locale, formatMessage } = useIntl()
  const { getLanguageLabelForLanguageValue } = useSupportedLanguages()
  const { isTablet } = getAllScreenTypes()

  const privacyTooltipText = formatMessage({
    id: "dashboard::forms::privacyTooltip",
    defaultMessage: "Visible on your store page. Click edit to change",
  })

  const unPrivacyTooltipText = formatMessage({
    id: "dashboard::forms::unPrivacyTooltip",
    defaultMessage: "Not visible on your store page. Click edit to change",
  })

  const visibleInvisibleContent = (shouldHide) => {
    if (!isTablet) {
      return <></>
    }

    return (
      <StyledProfileFlagRowContent>
        <div
          data-tooltip-content={
            shouldHide ? unPrivacyTooltipText : privacyTooltipText
          }
          data-tooltip-id="privacy-tooltip">
          <img
            src={
              CLOUDFLARE_IMAGE_URL +
              `/static/assets/images/svg/${
                shouldHide ? "unprivacy.svg" : "privacy.svg"
              }`
            }
            alt={`mai-privacy-image`}
            width={16}
            height={13}
          />
          <StyledPrivacyTooltip>
            <Tooltip
              arrowColor="transparent"
              effect="solid"
              place="top"
              type="info"
              textColor="black"
              backgroundColor="white"
              id="privacy-tooltip"
              delayShow={500}
            />
          </StyledPrivacyTooltip>
        </div>
      </StyledProfileFlagRowContent>
    )
  }

  return (
    <Form className="general-profile-form">
      <fieldset>
        <StyledProfileRowContent>
          <StyledProfileSection>
            <StyledProfileSectionTitle>
              <FormattedMessage
                id="dashboard::forms::legalName"
                defaultMessage="Legal name"
              />
            </StyledProfileSectionTitle>
            <StyledProfileSection>
              {isEdit ? (
                <>
                  <TextInput
                    newDesign
                    data-testid="name"
                    id="name"
                    name="name"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.name}
                  />
                  <FieldError name="name" />
                </>
              ) : (
                (initialValues?.name ?? "") || <>&nbsp;</>
              )}
            </StyledProfileSection>
          </StyledProfileSection>

          <StyledProfileSection>
            <StyledProfileSectionTitle>
              <FormattedMessage
                id="dashboard::forms::displayName"
                defaultMessage="Display name"
              />
            </StyledProfileSectionTitle>
            <StyledProfileSection>
              {isEdit ? (
                <>
                  <TextInput
                    newDesign
                    data-testid="displayName"
                    id="displayName"
                    name="displayName"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values?.displayName}
                  />
                  <FieldError name="displayName" />
                </>
              ) : (
                (initialValues?.displayName ?? "") || <>&nbsp;</>
              )}
            </StyledProfileSection>
          </StyledProfileSection>

          <StyledProfileSection>
            <StyledProfileSectionTitle>
              <FormattedMessage
                id="dashboard::settings::language"
                defaultMessage="Language"
              />
            </StyledProfileSectionTitle>
            <StyledProfileSection>
              {isEdit ? (
                <LanguageBar
                  isDropDown={true}
                  updateOnSelect={false}
                  value={values?.communicationLanguage}
                  onSelect={(val) => {
                    setFieldValue("communicationLanguage", val, true)
                  }}
                />
              ) : (
                getLanguageLabelForLanguageValue(
                  initialValues?.communicationLanguage.length > 0
                    ? initialValues.communicationLanguage.toUpperCase()
                    : locale.toUpperCase()
                ) || <>&nbsp;</>
              )}
            </StyledProfileSection>
          </StyledProfileSection>

          <StyledProfileSection>
            <StyledProfileSectionTitle>
              <FormattedMessage
                id="dashboard::forms::webAddress"
                defaultMessage="Store web address"
              />
            </StyledProfileSectionTitle>
            <StyledProfileSection>
              {isEdit ? (
                <>
                  <TextInput
                    newDesign
                    data-testid="website"
                    id="website"
                    name="website"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.website}
                  />
                  <FieldError name="website" />
                </>
              ) : (
                initialValues?.website || <>-</>
              )}
            </StyledProfileSection>
          </StyledProfileSection>

          <StyledProfileSection>
            <StyledProfileSectionTitle>
              <FormattedMessage
                id="dashboard::forms::vat"
                defaultMessage="VAT Number"
              />
            </StyledProfileSectionTitle>
            <StyledProfileSection>
              {isEdit ? (
                <>
                  <TextInput
                    newDesign
                    data-testid="taxId"
                    id="taxId"
                    name="taxId"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.taxId}
                  />
                  <FieldError name="taxId" />
                </>
              ) : (
                initialValues?.taxId || <>-</>
              )}
            </StyledProfileSection>
          </StyledProfileSection>

          <StyledProfileSection>
            <StyledVisibleContainer className={!isEdit ? "inner" : ""}>
              {!isEdit &&
                visibleInvisibleContent(initialValues?.hidePhoneNumber)}
              <StyledProfileSectionTitle>
                <FormattedMessage
                  id="dashboard::merchantStore::contactNumber"
                  defaultMessage="Contact number"
                />
              </StyledProfileSectionTitle>
            </StyledVisibleContainer>
            <StyledProfileSection>
              {isEdit ? (
                <PhoneInput
                  name="phoneNumber"
                  isRequired={false}
                  value={values.phoneNumber}
                  errors={errors}
                  touched={touched}
                  onChange={(phone) =>
                    setFieldValue("phoneNumber", phone, true)
                  }
                />
              ) : (
                getFormatPhoneNumber(initialValues?.phoneNumber) || (
                  <Spacer bottom={1} />
                )
              )}
            </StyledProfileSection>
          </StyledProfileSection>

          {isEdit && (
            <StyledProfileSection>
              <Checkbox
                label={formatMessage({
                  id: "dashboard::forms::showPhoneNumber",
                  defaultMessag: "Show contact number on your store page",
                })}
                isChecked={!values?.hidePhoneNumber}
                onChange={(e) => {
                  const temp = values?.hidePhoneNumber
                  setFieldValue("hidePhoneNumber", !temp, true)
                }}
                className="profile-form"
              />
            </StyledProfileSection>
          )}
          <StyledProfileSection>
            <StyledVisibleContainer className={!isEdit ? "inner" : ""}>
              {!isEdit && visibleInvisibleContent(initialValues?.hideEmail)}
              <StyledProfileSectionTitle>
                <FormattedMessage
                  id="dashboard::forms::email"
                  defaultMessage="Email"
                />
              </StyledProfileSectionTitle>
            </StyledVisibleContainer>
            <StyledProfileSection>
              {isEdit ? (
                <>
                  <TextInput
                    newDesign
                    data-testid="email"
                    id="email"
                    name="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                  />
                  <FieldError name="email" />
                </>
              ) : (
                initialValues?.email || <Spacer bottom={1} />
              )}
            </StyledProfileSection>
          </StyledProfileSection>
          {isEdit && (
            <>
              <StyledProfileSection>
                <Checkbox
                  label={formatMessage({
                    id: "dashboard::forms::showEmail",
                    defaultMessage: "Show email on your store page",
                  })}
                  isChecked={!values?.hideEmail}
                  onChange={() => {
                    const temp = values?.hideEmail
                    setFieldValue("hideEmail", !temp, true)
                  }}
                  className="profile-form"
                />
              </StyledProfileSection>
            </>
          )}
        </StyledProfileRowContent>
      </fieldset>
      {isEdit && (
        <>
          <StyledProfileSpacer bottom={1.5} />
          <StyledProfileFlexContainer>
            <Button
              className="black-button"
              color="transparent"
              label="cancel"
              onClick={onCancel}>
              <FormattedMessage
                id="dashboard::forms::cancel"
                defaultMessage="Cancel"
              />
            </Button>
            <Button
              data-testid="btn-save"
              className="blue-button button-margin"
              color={theme.colors.pacificBlue}
              type="button"
              disabled={Object.keys(errors)?.length > 0}
              onClick={onSave}>
              <FormattedMessage
                id="dashboard::accountForm::save"
                defaultMessage="Save"
              />
            </Button>
          </StyledProfileFlexContainer>
        </>
      )}
    </Form>
  )
}

export default GeneralProfileForm
