import styled from "@emotion/styled"
import { css } from "@emotion/react"

const GridTemplate = `
  display: grid;
  grid-template-columns: auto 3.025rem 2.25rem 3.675rem 3.325rem 3rem 4rem 1.7rem;
  grid-template-rows: 1fr;
  grid-gap: 0.35rem;
  align-items: center;

  @media only screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
  }

  @media only screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
  }
`

export const StyledCampaignTable = styled.div``

export const StyledCampaignTableHeader = styled.div`
  ${GridTemplate}

  div {
    font-family: ${(p) => p.theme.fontFamilies.headlineBig};
    font-style: ${(p) => p.theme.fontStyles.normal};
    font-weight: ${(p) => p.theme.fontWeights.liteBlack};
    color: ${(p) => p.theme.colors.disabled};
  }
  text-transform: uppercase;

  border-bottom: 0.0625rem solid ${(p) => p.theme.colors.tableItemBorder};
  padding-top: 1rem;
  padding-bottom: 1rem;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    font-size: 0.5rem;
    line-height: 0.75rem;
    letter-spacing: 0.03em;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    font-size: 0.625rem;
    line-height: 1rem;
    grid-template-columns: auto 4.025rem 3.25rem 4.675rem 4.325rem 4rem 5rem 2.7rem;
    grid-gap: 0.75rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 1rem;
    line-height: 1.5rem;
    letter-spacing: 0.02em;
    grid-template-columns: auto 5rem 6.5rem 6.5rem 8rem 6.5rem 5.5rem 4rem;
    grid-gap: 1rem;
  }

  .centered {
    height: 100%;
    justify-self: center;
  }
`

export const StyledCampaignTableRow = styled.div`
  ${GridTemplate}
  color: ${(p) => p.theme.colors.secondaryBrandTextColor};
  border-bottom: 0.0625rem solid ${(p) => p.theme.colors.tableItemBorder};
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  height: 100%;
  box-sizing: border-box;

  *:not(button),
  *:not(i) {
    color: ${(p) => p.theme.colors.gondola};
  }

  strong {
    font-weight: ${(p) => p.theme.fontWeights.bold};
    color: ${(p) => p.theme.colors.gondola};
  }

  .desc * {
    color: ${(p) => p.theme.colors.secondaryBrandTextColor};
  }

  .centered {
    height: 100%;
    display: flex;
    align-items: center;
    justify-self: center;

    button {
      padding: 0;
    }
    .media-box {
      width: 5.8125rem;
      height: 3.875rem;
      img {
        object-fit: cover;
        border-radius: 0.1875rem;
      }
    }
    button svg {
      margin-left: 0;
    }
  }

  .disabled-tooltip {
    color: ${(p) => p.theme.colors.white};
  }
  .toggle {
    height: auto;
    text-align: center;
  }
  a {
    margin: 0;
  }
  ${(p) =>
    p.disabled &&
    css`
      pointer-events: none;
    `}
  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    grid-template-columns: auto 4.025rem 3.25rem 4.675rem 4.325rem 4rem 5rem 2.7rem;
    grid-gap: 0.75rem;
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    grid-template-columns: auto 5rem 6.5rem 6.5rem 8rem 6.5rem 5.5rem 4rem;
    grid-gap: 1rem;
  }
`

export const StyledCampaignContainer = styled.div`
  display: flex;
  align-items: center;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
  }

  .media-box {
    height: auto;
    width: unset;
    margin-right: 1.5rem;
    img {
      height: auto;
    }
  }
`

export const StyledCampaignName = styled.div`
  font-family: ${(p) => p.theme.fontFamilies.regular};
  font-style: ${(p) => p.theme.fontStyles.normal};
  font-weight: ${(p) => p.theme.fontWeights.liteMedium};
  color: ${(p) => p.theme.colors.pitchBlack};
  text-align: left;
  word-break: break-word;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    font-size: 0.5rem;
    line-height: 1rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    font-size: 0.75rem;
    line-height: 1.125rem;
    letter-spacing: 0.01em;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 1.5rem;
    line-height: 2rem;
  }
`

export const StyledCampaignType = styled.div`
  text-align: center;
  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    svg {
      font-size: 1.5rem;
      height: 1.5rem;
    }
  }
`

export const StyledCampaignPrivacy = styled.div`
  cursor: pointer;
  text-align: center;
  ${(p) =>
    p.disabled &&
    css`
      pointer-events: none;
    `}
  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    svg {
      font-size: 1.5rem;
      height: 1.5rem;
    }
  }
`

export const StyledCampaignDate = styled.div`
  font-family: ${(p) => p.theme.fontFamilies.regular};
  font-style: ${(p) => p.theme.fontStyles.normal};
  font-family: ${(p) => p.theme.fontFamilies.regular};
  color: ${(p) => p.theme.colors.pitchBlack};
  text-align: center;
  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    font-size: 0.625rem;
    line-height: 1rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    font-size: 0.75rem;
    line-height: 1.25rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 1rem;
    line-height: 1.375rem;
  }
`

export const StyledCampaignDonation = styled.div`
  font-family: ${(p) => p.theme.fontFamilies.regular};
  font-style: ${(p) => p.theme.fontStyles.normal};
  font-weight: ${(p) => p.theme.fontWeights.liteMedium};
  color: ${(p) => p.theme.colors.pitchBlack};
  text-align: right;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    font-size: 0.625rem;
    line-height: 1rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    font-size: 0.75rem;
    line-height: 1.25rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 1rem;
    line-height: 1.375rem;
  }
`

export const StyledCampaignAmountContainer = styled.div``

export const StyledCampaignFundedAmount = styled.div`
  font-family: ${(p) => p.theme.fontFamilies.regular};
  font-style: ${(p) => p.theme.fontStyles.normal};
  font-weight: ${(p) => p.theme.fontWeights.liteMedium};
  color: ${(p) => p.theme.colors.pitchBlack};
  text-align: right;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    font-size: 0.625rem;
    line-height: 0.875rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    font-size: 0.75rem;
    line-height: 1rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 1rem;
    line-height: 1.5rem;
  }
`

export const StyledCampaignGoalAmount = styled.div`
  font-family: ${(p) => p.theme.fontFamilies.regular};
  font-style: ${(p) => p.theme.fontStyles.normal};
  font-weight: ${(p) => p.theme.fontWeights.liteMedium};
  color: ${(p) => p.theme.colors.disabled} !important;
  text-align: right;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    font-size: 0.625rem;
    line-height: 0.875rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    font-size: 0.75rem;
    line-height: 1rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 1rem;
    line-height: 1.5rem;
  }
`
