import styled from "@emotion/styled"
import { css } from "@emotion/react"

export const StyledText = styled.div`
  overflow: hidden;
  z-index: 999;
  margin: auto;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    margin: 0;
    margin-top: 2rem;
    padding-bottom: 2rem;
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    margin-top: 0;
  }
`

export const StyledImage = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  img {
    height: 100%;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    left: auto;
    width: 50%;
    -webkit-mask-image: linear-gradient(to right, transparent 0%, black 100%);
    mask-image: linear-gradient(to right, transparent 0%, black 50%);
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    width: 60%;
    -webkit-mask-image: linear-gradient(to right, transparent 0%, black 10%);
    mask-image: linear-gradient(to right, transparent 0%, black 50%);
  }
`

export const StyledDescription = styled.div`
  color: ${(p) => p.theme.colors.black};
  font-size: 0.75rem;
  line-height: 1.125rem;
  font-family: ${(p) => p.theme.fontFamilies.regular};
  font-weight: ${(p) => p.theme.fontWeights.liteMedium};
  margin-top: 1rem;
  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    font-size: 1rem;
    line-height: 1.375rem;
    max-width: 30rem;
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    font-size: 1rem;
    line-height: 1.375rem;
    max-width: 35rem;
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 1.5rem;
    line-height: 2.25rem;
    max-width: 53.125rem;
  }
`
export const StyledTitle = styled.p`
  font-size: 1.125rem;
  line-height: 1.5rem;
  font-weight: ${(p) => p.theme.fontWeights.semiBolder};
  font-family: ${(p) => p.theme.fontFamilies.headline};
  color: ${(p) => p.theme.colors.gondola};
  margin-bottom: 1rem;
  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    margin-bottom: 1.5rem;
    font-size: 1.125rem;
    line-height: 1.5rem;
    max-width: 29.125rem;
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 2rem;
    line-height: 2.625rem;
    max-width: 53.125rem;
  }
`

export const StyledOnlinePageHeader = styled.div`
  font-family: ${(p) => p.theme.fontFamilies.headline};
  font-weight: ${(p) => p.theme.fontWeights.semiBolder};
  text-align: center;
  max-width: 35.5625rem;
  font-size: 1.5rem;
  color: ${(p) => p.theme.colors.whiteSmoke};

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    font-size: 2rem;
    text-align: left;
    color: ${(p) => (p.carrot ? p.theme.colors.carrot : p.theme.colors.blue)};
    margin: 3rem 0 1.5rem 0;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    font-size: 2rem;
    margin-bottom: 1.5rem;
    max-width: 35.5625rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 3.5rem;
    max-width: 62.6875rem;
    margin: 8rem 0 1.5rem 0;
  }
`

export const StyledOnlinePageTitle = styled.p`
  font-size: 1.25rem;
  line-height: 2rem;
  font-weight: ${(p) => p.theme.fontWeights.semiBolder};
  font-family: ${(p) => p.theme.fontFamilies.headline};
  color: ${(p) => p.theme.colors.gondola};
  margin-bottom: 2rem;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    font-size: 1.25rem;
    line-height: 2rem;
    max-width: 30.25rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    max-width: 32.1875rem;
    font-size: 1.25rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 2rem;
    line-height: 2.75rem;
    max-width: 55.8125rem;
  }
`

export const StyledStoryBox = styled.div`
  // TODO: styles of this slice will be refactored
  position: relative;
  display: flex;
  align-items: ${(p) => (p.blue || p.carrot ? "flex-start" : "center")};
  margin: auto;
  height: 21.875rem;
  min-height: 23rem;
  padding: 0 1rem;
  img {
    width: 100%;
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    padding: 0 1.5rem;
    min-height: ${(p) =>
      p.small || p.carrot ? "25rem" : p.blue ? "28rem" : "35rem"};
    height: auto;
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    padding: 0 2rem;
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    padding: 6rem 6rem 3rem;
    min-height: 50rem;
  }
`
export const StyledHeader = styled.div`
  cursor: pointer;
  color: ${(p) => p.theme.colors.white};
  font-size: 1.5rem;
  font-family: ${(p) => p.theme.fontFamilies.headline};
  font-weight: ${(p) => p.theme.fontWeights.semiBolder};
  line-height: 2rem;
  text-align: center;
  margin: auto;
  margin-top: 1rem;
  max-width: 20.5rem;
  z-index: 999;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    margin: 0 0 1.5rem 0;
    max-width: none;
    font-size: 2rem;
    text-align: left;
    color: ${(p) =>
      p.online ? p.theme.colors.carrot : p.theme.colors.pacificBlue};
    ${(p) =>
      p.online &&
      css`
        max-width: 30.5625rem;
        margin-top: 3.5rem;
      `}
    
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    font-size: 2rem;
    line-height: 2.5rem;
    ${(p) =>
      p.online &&
      css`
        max-width: 36rem;
      `}
  }
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 4rem;
    line-height: 5.4375rem;
    ${(p) =>
      p.online &&
      css`
        max-width: 60rem;
      `}
  }
  }
`

export const StyledWrapper = styled.div`
  margin: 5.125rem auto 0;
  .form-style {
    text-align: left;
    h3 {
      font-size: 1.125rem;
    }

    p {
      font-size: 0.75rem;
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
      text-align: center;
      h3 {
        font-size: 2rem;
      }
      p {
        font-size: 1rem;
      }
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
      h3 {
        font-size: 3rem;
      }
      p {
        font-size: 1.5rem;
      }
    }
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    max-width: ${(p) => p.theme.breakpoints.tablet};
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    max-width: ${(p) => p.theme.breakpoints.desktop};
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    max-width: ${(p) => p.theme.breakpoints.wide};
  }
`
export const StyledMobileTextWrapper = styled.div`
  margin: 2.5rem 1rem 3rem 1rem;
`
export const StyledBenefits = styled.div`
  background-color: ${(p) => p.theme.colors.whiteSmoke};

  .benefits-module {
    padding: 2.5rem 0;
    > div {
      padding-bottom: 0;
    }
    span {
      color: ${(p) => p.theme.colors.black};
    }
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    .benefits-module {
      padding: 3rem 0;
      margin: 0 0.5rem;
    }
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    .benefits-module {
      margin: 0 1rem;
      padding: 4rem 0;
    }
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    .benefits-module {
      margin: 0 5rem;
      padding: 6rem 0;
    }
  }
`
export const StyledOverlay = styled.div`
  margin-left: -1rem;
  z-index: 899;
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: ${(p) => p.theme.colors.pitchBlack};
  opacity: 40%;
`
export const StyledNgoList = styled.div`
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(2, 9.75rem);
  grid-template-rows: repeat(4, 5.4375rem);

  ${(p) =>
    (p.sender === "PARTNER" || p.sender === "CORPORATE") &&
    css`
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(4, 5.4375rem);
    `}
  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    grid-template-columns: repeat(4, 10.5rem);
    grid-template-rows: repeat(2, 5.9375rem);
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    grid-template-columns: repeat(4, 13.9375rem);
    grid-template-rows: repeat(2, 7.875rem);
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    grid-template-columns: repeat(4, 25.8125rem);
    grid-template-rows: repeat(2, 14.5625rem);
  }
`

export const StyledNgoListItem = styled.div`
  img {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
  }
`

export const StyledNgoListTitle = styled.div`
  text-align: center;
  font-size: 1.125rem;
  font-weight: ${(p) => p.theme.fontWeights.semiBolder};
  font-family: ${(p) => p.theme.fontFamilies.headline};
  margin-bottom: 1.5rem;
  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    text-align: left;
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    margin-bottom: 2rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 2rem;
  }
`

export const StyledHowToJoin = styled.div`
  position: relative;
`

export const StyledSliceHeader = styled.div`
  font-size: 1.125rem;
  line-height: 1.5rem;
  color: ${(p) => p.theme.colors.pitchBlack};
  font-family: ${(p) => p.theme.fontFamilies.headline};
  font-weight: ${(p) => p.theme.fontWeights.semiBolder};
  text-align: left;
  margin-bottom: 1rem;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    font-size: 1.5rem;
    line-height: 2rem;
    margin-bottom: 1.5rem;
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    font-size: 2rem;
    line-height: 2.625rem;
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 3rem;
    line-height: 4.0625rem;
    margin-bottom: 2rem;
  }
`

export const StyledNgoBullets = styled.div`
  margin-top: 1.5rem;

  ul {
    text-indent: -0.625rem;
    margin-left: 0.625rem;
  }

  ul > li {
    color: ${(p) => p.theme.colors.pitchBlack};
    font-size: 0.625rem;
    line-height: 1rem;
    margin-bottom: 0.5rem;
  }

  ul > li::marker {
    font-size: 0.4rem;
    vertical-align: middle;
    @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
      font-size: 0.75rem;
    }
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    margin-top: 0;
    ul > li {
      line-height: 2rem;
      font-size: 1rem;
      margin-bottom: 0;
    }
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    ul > li {
      font-size: 1.5rem;
      margin-bottom: 1rem;
    }
  }
`

export const StyledHowToJoinImage = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;

  img {
    width: 100%;
  }

  -webkit-mask-image: linear-gradient(to right, transparent 0%, black 80%);
  mask-image: linear-gradient(to right, transparent 0%, black 80%);

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    -webkit-mask-image: linear-gradient(to right, transparent 0%, black 50%);
    mask-image: linear-gradient(to right, transparent 0%, black 50%);
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    -webkit-mask-image: linear-gradient(to right, transparent 0%, black 80%);
    mask-image: linear-gradient(to right, transparent 0%, black 80%);
  }
`

export const StyledNgoText = styled.div`
  z-index: 1;
  max-width: 13rem;
  > p {
    font-size: 0.625rem;
    line-height: 1rem;
    a {
      color: ${(p) => p.theme.colors.gondola};
    }
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    max-width: none;
    > p {
      font-size: 1rem;
      line-height: 1.5rem;
    }
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    > p {
      line-height: 2rem;
    }
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    > p {
      font-size: 1.5rem;
      line-height: 2rem;
    }
  }
`
export const StyledLogoSlice = styled.div`
  padding: 1rem;
  margin-top: 2rem;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    padding: 1.5rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    padding: 2rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    padding: 6rem;
  }

  &.join-offline-merchant,
  &.join-internal-merchant {
    margin-top: unset;
    margin-bottom: 3.375rem;
  }
`
