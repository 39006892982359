import React, { useEffect, useState } from "react"
import { faBars } from "@fortawesome/free-solid-svg-icons/faBars"
import { faXmark } from "@fortawesome/free-solid-svg-icons/faXmark"
import { faChevronRight } from "@fortawesome/pro-light-svg-icons/faChevronRight"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { CustomModal, TextInput } from "@tmu/components/common"
import { useAllOfferCategories } from "@tmu/hooks"
import { Spacer } from "@tmu/global/page-addons/detail-page.styles"
import { getValueForLocale } from "@tmu/utils/string"
import { getAllScreenTypes } from "@tmu/utils/mediaQueries"
import { useIntl } from "gatsby-plugin-intl"
import {
  StyledCategoryName,
  StyledCategoryNameArrow,
  StyledCategoryNameContainer,
  StyledCategoryNameIcon,
  StyledCategoryTitle,
  StyledCategoryTitleMobile,
  StyledCloseIcon,
  StyledFilter,
  StyledFilterItem,
  StyledIcon,
  StyledLabel,
  StyledLeftContent,
  StyledLeftSide,
  StyledPageContainer,
  StyledPageContainerMobile,
  StyledRightContent,
  StyledRightSide,
  StyledSelectedCategoryMobile,
  StyledSubCategoryName,
  StyledSubcategoryNameContainer,
} from "./index.styles"
import { StyledLine } from "../Table/index.styles"

const CategorySubcategorySelect = ({ selected, onSelect }) => {
  const [modalStatus, setModalStatus] = useState(false)
  const { organizedCategories } = useAllOfferCategories()
  const [selectedCategory, setSelectedCategory] = useState()
  const [selectedSubCategory, setSelectedSubCategory] = useState()
  const [modalContent, setModalContent] = useState({})
  const [initialSelected, setInitialSelected] = useState()
  const [step, setStep] = useState(0)
  const [categories, setCategories] = useState([])
  const [tempSelected, setTempSelected] = useState()
  const [highlighted, setHighlighted] = useState()
  const [isInitialized, setIsInitialized] = useState(false)
  const { locale, defaultLocale, formatMessage } = useIntl()
  const { isTablet } = getAllScreenTypes()

  const allOption = {
    name: "All",
    id: "-1",
  }

  const allOptionSub = {
    name: "All",
    id: "-11",
  }

  useEffect(() => {
    if (!organizedCategories?.length) {
      return
    }
    let cats = organizedCategories
    if (cats?.length > 0) {
      cats = [allOption, ...cats]

      cats = cats.map((item) => {
        if (item?.children?.length > 0) {
          return { ...item, children: [allOptionSub, ...item?.children] }
        }

        return item
      })
    }

    setCategories(cats)
  }, [organizedCategories])

  const init = () => {
    if (!highlighted && categories?.length > 1) {
      setHighlighted(categories?.[1])
    }

    if (!isTablet && selectedSubCategory) {
      setStep(1)
    }
  }

  useEffect(() => {
    if (initialSelected && !isInitialized) {
      setInitialSelected(true)
      handleCategorySelect(initialSelected)
    }
  }, [initialSelected])

  useEffect(() => {
    if (initialSelected) {
      return
    }

    init()

    if (!selectedCategory && !selectedSubCategory) {
      const selectedOne = selected?.[0]
      const category = categories?.find(
        (item) =>
          item?.id !== "-1" && item?.id !== "-11" && item?.slug === selectedOne
      )
      setStep(0)
      if (category) {
        setSelectedCategory(category)

        setInitialSelected(category)
        if (!isTablet && category?.children?.length > 0) {
          setStep(1)
        }
      }

      categories?.forEach((item) =>
        item?.children?.forEach((sub) => {
          if (
            sub?.id !== "-1" &&
            sub?.id !== "-11" &&
            sub?.slug === selectedOne
          ) {
            setSelectedSubCategory(sub)
            setSelectedCategory(item)
            setInitialSelected(sub)
            setStep(1)
          }
        })
      )
    }

    if (!initialSelected && !selected?.length && isTablet) {
      const category = categories?.[1]
      setTempSelected(category)
    }

    if (selectedSubCategory) {
      handleCategorySelect(selectedSubCategory)
    } else if (selectedCategory) {
      handleCategorySelect(selectedCategory)
    }
  }, [categories, selected])

  const style = { padding: "0 1rem 1rem", overflowY: "hidden" }

  const closeModal = () => {
    setModalStatus(false)
  }

  const openModal = () => {
    setModalStatus(true)
    init()
  }

  const contentByItem = (category) => {
    const selectedClassName =
      (initialSelected ?? selectedCategory ?? tempSelected)?.id === category?.id
        ? "selected"
        : " " + !isTablet && selectedSubCategory?.id === category?.id
        ? "sub-selected"
        : ""

    const highlightedClassName =
      isTablet && (highlighted ?? selectedCategory)?.id === category?.id
        ? "highlighted"
        : ""
    return (
      <>
        {!isTablet && <StyledLine key={category?.id} />}
        <StyledCategoryNameContainer
          key={category?.id}
          onClick={() => {
            if (category?.id === "-1") {
              setSelectedSubCategory(null)
              setSelectedCategory(null)
              setTempSelected(null)
              handleCategorySelect(null, true)
            } else if (category?.id === "-11") {
              setSelectedSubCategory(null)
              handleCategorySelect(selectedCategory)
              setStep(1)
            } else if (category?.id === "-1" && !selectedCategory?.id) {
              setStep(0)
              setSelectedCategory(null)
              setSelectedSubCategory(null)
              handleCategorySelect(null)
            } else {
              setStep(1)
              setSelectedCategory(category)
              setSelectedSubCategory(null)
              if (!category?.children) {
                setTempSelected(null)
                handleCategorySelect(category)
              }
            }

            setHighlighted(null)
          }}
          className={`${selectedClassName} ${highlightedClassName}`}>
          <StyledCategoryNameIcon>
            {((!isTablet && !selectedCategory) || isTablet) && (
              <StyledIcon>
                {category?.icon && <i className={category?.icon} />}
              </StyledIcon>
            )}

            <StyledCategoryName>
              {getValueForLocale(category, "name", locale, defaultLocale)}
            </StyledCategoryName>
          </StyledCategoryNameIcon>
          {category?.hasChildren && category?.children?.length > 0 && (
            <StyledCategoryNameArrow>
              <FontAwesomeIcon icon={faChevronRight} />
            </StyledCategoryNameArrow>
          )}
        </StyledCategoryNameContainer>
      </>
    )
  }

  const fullContent = (
    <StyledPageContainer>
      <StyledLeftSide>
        <StyledLeftContent>
          {categories?.map((category) => contentByItem(category))}
        </StyledLeftContent>
      </StyledLeftSide>
      <StyledRightSide>
        <StyledRightContent>
          <StyledCloseIcon onClick={closeModal}>
            <FontAwesomeIcon icon={faXmark} />
          </StyledCloseIcon>
          {(highlighted ?? selectedCategory ?? tempSelected)?.children?.length >
            0 && (
            <StyledSubcategoryNameContainer>
              <StyledCategoryTitle>
                {getValueForLocale(
                  highlighted ?? selectedCategory ?? tempSelected,
                  "name",
                  locale,
                  defaultLocale
                )}
              </StyledCategoryTitle>
              <Spacer bottom={1.5} />
              {(highlighted ?? selectedCategory ?? tempSelected)?.children?.map(
                (subcategory) => (
                  <>
                    <StyledSubCategoryName
                      key={subcategory?.id}
                      className={
                        selectedSubCategory?.id === subcategory?.id
                          ? "selected"
                          : ""
                      }
                      onClick={() => {
                        if (subcategory?.id !== "-11") {
                          setSelectedSubCategory(subcategory)
                          handleCategorySelect(subcategory)
                        } else {
                          setSelectedSubCategory(null)
                          handleCategorySelect(highlighted ?? selectedCategory)
                        }
                      }}>
                      {getValueForLocale(
                        subcategory,
                        "name",
                        locale,
                        defaultLocale
                      )}
                    </StyledSubCategoryName>
                    <Spacer top={0.75} />
                  </>
                )
              )}
            </StyledSubcategoryNameContainer>
          )}
        </StyledRightContent>
      </StyledRightSide>
    </StyledPageContainer>
  )

  const allCategoriesText = formatMessage({
    id: "categorySelect::filters::allCategories",
    defaultMessage: "All Categories",
  })

  const handleCategorySelect = (selected, discardTempData) => {
    const temp = discardTempData ? null : tempSelected
    const selectedItem = selected ?? temp
    if (typeof onSelect === "function") {
      onSelect(selectedItem?.id, selectedItem?.slug, selectedItem)
    }

    closeModal()
    setInitialSelected(selectedItem)
  }

  const currentSelected =
    initialSelected ?? selectedSubCategory ?? selectedCategory

  const categoryLabel = getValueForLocale(
    currentSelected,
    "name",
    locale,
    defaultLocale
  )

  useEffect(() => {
    const content = {
      children: (
        <StyledPageContainerMobile>
          <StyledCategoryTitleMobile>
            {getValueForLocale(currentSelected, "name", locale, defaultLocale)}
          </StyledCategoryTitleMobile>
          <Spacer bottom={2} />
          {(selectedCategory?.children ?? categories)?.map((category) =>
            contentByItem(category)
          )}
          <StyledLine />
        </StyledPageContainerMobile>
      ),
      onClose: () => {
        if (step === 1) {
          setSelectedCategory(null)
          setStep(0)
        } else {
          closeModal()
        }
      },
    }
    setModalContent(content)
  }, [selectedCategory, selectedSubCategory, initialSelected, categories])

  const selectedLabel =
    categoryLabel?.length > 0 ? categoryLabel : allCategoriesText
  return (
    <>
      {isTablet ? (
        <StyledFilter onClick={openModal}>
          <StyledFilterItem>
            <div>
              <FontAwesomeIcon icon={faBars} />
            </div>
            <StyledLabel long={selectedLabel?.length > 30}>
              {selectedLabel}
            </StyledLabel>
          </StyledFilterItem>
        </StyledFilter>
      ) : (
        <StyledSelectedCategoryMobile onClick={openModal}>
          <TextInput newDesign value={selectedLabel} disabled={true} />
        </StyledSelectedCategoryMobile>
      )}

      {!isTablet && (
        <CustomModal
          isModalOpen={modalStatus}
          style={style}
          children={modalContent?.children}
          isCloseIcon={true}
          isCloseBackButton={true}
          headerStyle={{ paddingTop: "1rem" }}
          cancelButtonAction={modalContent?.onClose}
          isMobile={true}
          isFull={true}
        />
      )}

      {isTablet && (
        <CustomModal
          style={{ padding: 0, lineHeight: 0 }}
          isModalOpen={modalStatus}
          children={fullContent}
          isWithoutHeader={true}
        />
      )}
    </>
  )
}

export default CategorySubcategorySelect
