import React, { useEffect, useRef, useState } from "react"
import {
  StyledCardList,
  StyledCardCheckboxContainer,
  StyledDeleteButton,
  StyledRadioButton,
  StyledMobileContent,
  StyledNewCardText,
  StyledNewCardForm,
  StyledButtonContainer,
  StyledInoutContainer,
} from "./index.styles"
import {
  Button,
  CreditCard,
  CustomModal,
  FormErrors,
  PaymentMethods,
  RadioInput,
  TextInput,
} from "@tmu/components/common"
import { faTrash } from "@fortawesome/pro-regular-svg-icons/faTrash"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useAuth, useCreditCards, useToast } from "@tmu/hooks"
import { useIntl, FormattedMessage } from "gatsby-plugin-intl"
import { Tooltip } from "react-tooltip"
import { CardElement } from "@stripe/react-stripe-js"
import { cardElementStyles } from "@tmu/global/GlobalStyle"
import { StyledFormRow } from "../../forms/DonationForm/index.styles"
import { Form, Formik } from "formik"
import { getAllScreenTypes } from "@tmu/utils/mediaQueries"
import { cardholderName } from "@tmu/utils/validation"
import * as Yup from "yup"
import { PAYMENT_TYPES } from "@tmu/src/apollo/constants"
import { Spacer } from "@tmu/src/global/page-addons/detail-page.styles"

const CreditCardSelector = ({
  cards,
  onChange,
  onClick,
  onDeleteError,
  onDeleteComplete,
  onAllCards,
  withDeleteButton,
  withSetDefault,
  withNewCard,
  cardToExclude,
  stripe,
  elements,
  cardToDeleteParam,
  subscriptionsParam,
  onTransactionComplete,
  amount,
  ...rest
}) => {
  const params = rest?.stripeParams
  const setupIntentClientSecret = params?.setup_intent_client_secret

  const { success, error } = useToast()

  const processPaypal = async (paramsData) => {
    const redirectStatus = paramsData?.redirect_status
    const replaceCardId = paramsData?.replaceCardId

    const result = await stripe.retrieveSetupIntent(setupIntentClientSecret)
    if (redirectStatus === "succeeded") {
      if (result.setupIntent) {
        const paymentMethod = result?.setupIntent?.payment_method
        if (paymentMethod) {
          await replaceSubscriptions(paymentMethod, replaceCardId)
          if (typeof onDeleteComplete === "function") {
            onDeleteComplete()
          }
        }
      }
    }
    const errorData =
      result?.error ?? result?.setupIntent?.last_setup_error?.message

    if (errorData) {
      if (typeof onDeleteError === "function") {
        onDeleteError(errorData)
      }

      if (typeof onDeleteComplete === "function") {
        onDeleteComplete()
      }

      error(errorData)
    }
  }

  if (stripe && setupIntentClientSecret) {
    delete params.setup_intent_client_secret
    processPaypal(params).then((r) => {
      const cleanedUrl = new URL(location.origin + location.pathname)
      window?.history?.pushState({}, null, cleanedUrl.toString())
    })
  }
  const [selected, setSelected] = useState(null)
  const [modalStatus, setModalStatus] = useState(false)
  const [cardToDelete, setCardToDelete] = useState(cardToDeleteParam)
  const [cardList, setCardList] = useState([])

  const { formatMessage } = useIntl()
  const [newCard, setNewCard] = useState({ cardId: "" })
  const [stripeErrorData, setStripeErrorData] = useState(null)
  const [cardError, setCardError] = useState()
  const [selectedPaymentType, setSelectedPaymentType] = useState(
    PAYMENT_TYPES.CARD
  )
  const isPayPal = selectedPaymentType === PAYMENT_TYPES.PAYPAL
  const creditCardRefError = useRef()
  const [subscriptionList, setSubscriptionList] = useState(subscriptionsParam)
  const { user } = useAuth()
  let stripeProcessing = false

  const validationObject = {
    cardholderName: cardholderName({ formatMessage }),
  }

  const validationSchema = Yup.object().shape(validationObject)

  const { isTablet } = getAllScreenTypes()

  const {
    deleteCardData,
    deleteCard,
    callSubscription,
    updateSubscription,
    cancelSubscription,
    callCreateClientSecret,
    clientSecret,
    callAllSavedAccounts,
    allSavedAccountsData,
    allCards,
  } = useCreditCards({
    callImmediate: false,
  })

  const replaceSubscriptions = async (paymentMethod, cardId) => {
    const result = await callSubscription({
      variables: {
        stripePaymentMethodId: cardId,
      },
    })

    const subscriptionArr = result?.data?.allSubscriptions?.edges?.filter(
      (item) => item?.node?.status === "ACTIVE"
    )
    if (subscriptionArr?.length) {
      setCardToDelete({ cardId })
      return updateSubscriptions(paymentMethod, subscriptionArr)
    }
    return true
  }

  const deleteCardItem = () => {
    if (cardToDelete) {
      callSubscription({
        variables: {
          stripePaymentMethodId: cardToDelete?.cardId,
        },
      }).then((result) => {
        const subscriptionArr = result?.data?.allSubscriptions?.edges?.filter(
          (item) => item?.node?.status === "ACTIVE"
        )

        if (subscriptionArr?.length) {
          setSubscriptionList(subscriptionArr)
          if (typeof onDeleteError === "function") {
            onDeleteError(cardToDelete, subscriptionArr)
          }
        } else {
          deleteCard(cardToDelete?.cardId).then((result) => {
            setCardToDelete(null)
            setSubscriptionList([])
            if (typeof onDeleteComplete === "function") {
              onDeleteComplete()
            }
          })
        }
      })
    }
  }

  const onDeleteClick = (card) => {
    callSubscription({
      variables: {
        stripePaymentMethodId: card?.cardId,
      },
    }).then((result) => {
      const subscriptionArr = result?.data?.allSubscriptions?.edges?.filter(
        (item) => item?.node?.status === "ACTIVE"
      )

      if (subscriptionArr?.length) {
        setSubscriptionList(subscriptionArr)
        if (typeof onDeleteError === "function") {
          onDeleteError(card, subscriptionArr)
        }
      } else {
        setModalStatus(true)
      }
    })
  }

  const cardDeletedSuccess = formatMessage({
    id: "dashboard::profile::cardDeleteSuccess",
    defaultMessage: "Card deleted successfully.",
  })

  const deleteCardTitle = formatMessage({
    id: "dashboard::profile::deleteCardTtile",
    defaultMessage: "Delete Saved Card?",
  })

  const deleteCardDescription = formatMessage({
    id: "dashboard::profile::deleteCardDescription",
    defaultMessage:
      "If you want to use it later you will have to provide all the details again",
  })

  const deleteCardText = formatMessage({
    id: "dashboard::profile::deleteCardText",
    defaultMessage: "DELETE CARD",
  })

  const tooltipText = formatMessage({
    id: "dashboard::profile::setAsDefault",
    defaultMessage: "Set as default",
  })

  useEffect(() => {
    if (withNewCard) {
      callCreateClientSecret()
    }

    if (!cards?.length && user?.id) {
      callAllSavedAccounts()
    }
  }, [])

  useEffect(() => {
    if (deleteCardData?.deleteCard?.success) {
      callAllSavedAccounts()
      success(cardDeletedSuccess)
    }

    const errosData = deleteCardData?.deleteCard?.errors
    if (errosData?.length) {
      const errorMessage = errosData
        ?.map((item) => item?.messages?.at(0))
        ?.at(0)
      error(errorMessage)
    }
  }, [deleteCardData])

  const closeModal = () => {
    setModalStatus(false)
  }

  const excludePaypals = (cardsData) => {
    cardsData = cardsData?.filter(
      (item) => item?.brand !== PAYMENT_TYPES.PAYPAL
    )

    return cardsData
  }

  useEffect(() => {
    if (selected) {
      return
    }

    let cardsData = cards?.length ? cards : allCards

    if (amount < 15) {
      cardsData = excludePaypals(cardsData)
    }

    if (!cardsData?.length) {
      return
    }

    const cardsTemp = cardToExclude
      ? cardsData?.filter((item) => item?.cardId !== cardToExclude?.cardId)
      : cardsData
    setCardList(cardsTemp)

    if (withNewCard && (!cardsTemp || cardsTemp?.length === 0)) {
      setSelected(newCard)
    }

    if (typeof onAllCards === "function") {
      onAllCards(cardsData)
    }
    let defaultCard = cardsTemp?.filter((item) => item?.isDefault)?.at(0)

    if (!defaultCard) {
      defaultCard = cardsTemp?.at(0)
    }
    if (defaultCard) {
      setSelected(defaultCard)
      if (typeof onChange === "function") {
        onChange(defaultCard)
      }
    }
  }, [allCards, cards])

  const mobileModalContent = (
    <StyledMobileContent>
      <h2>{deleteCardTitle}</h2>
      <p>{deleteCardDescription}</p>
      <Button
        color={"blue"}
        onClick={() => {
          deleteCardItem()
          closeModal()
        }}
        data-testid="btn-confirm">
        {deleteCardText}
      </Button>
    </StyledMobileContent>
  )

  const handleCardChange = (card, formErrors) => {
    const { error } = card
    setStripeErrorData(null)
    const stripeError = error?.code
      ? formatMessage({
          id: `forms::stripe::${error?.code}`,
          defaultMessage: `${error?.message}`,
        })
      : null
    const cardError = stripeError || ""

    if (cardError) {
      formErrors.card = cardError
    } else {
      delete formErrors.card
    }
    setCardError(cardError)
  }

  const createNewCardAndDelete = async (cardHolderName) => {
    if (!stripe || !elements || !clientSecret || stripeProcessing) {
      return
    }
    stripeProcessing = true
    const cardElement = elements.getElement("card")

    const confirmSetup = isPayPal
      ? stripe?.confirmPayPalSetup
      : stripe?.confirmCardSetup

    const newUrl = new URL(location.origin + location.pathname)
    newUrl.searchParams.append("replaceCardId", cardToDelete?.cardId)
    const result = await confirmSetup(clientSecret, {
      payment_method: {
        card: cardElement,
        billing_details: {
          name: cardHolderName,
          email: user?.email,
        },
      },
      return_url: newUrl?.toString(),
    })
    if (result?.error) {
      error(result?.error?.message)
    } else {
      updateSubscriptions(result?.setupIntent?.payment_method)
    }

    stripeProcessing = false
  }

  const generalErrorText = (err) =>
    formatMessage({
      id: "dashboard::userForm::errorMessage",
      defaultMessage: err?.message || "An error occurred",
    })

  const updateSubscriptions = (paymentMethodId, subscriptions) => {
    const promiseArr = []
    const subList = subscriptions ?? subscriptionList
    subList?.forEach((subscription) => {
      const promise = updateSubscription(
        subscription?.node?.id,
        paymentMethodId
      )
      promiseArr.push(promise)
    })

    return Promise.all(promiseArr)
      .then((result) => {
        const errorList = result?.flatMap(
          (item) => item?.data?.updateSubscription?.errors
        )

        if (errorList?.length === 0) {
          return deleteCardItem()
        }
        errorList?.forEach((item) => error(item?.messages[0]))

        return errorList
      })
      .catch((err) => {
        error(generalErrorText(err))
        return err
      })
  }

  const cancelSubscriptions = () => {
    const promiseArr = []
    subscriptionList?.forEach((subscription) => {
      const promise = cancelSubscription(subscription?.node?.id)
      promiseArr.push(promise)
    })

    Promise.all(promiseArr)
      .then((result) => {
        const errorList = result
          ?.flatMap((item) => item?.data?.cancelSubscription?.errors)
          ?.filter(
            (item) =>
              item && !item?.toLowerCase()?.includes("already cancelled")
          )

        if (errorList?.length === 0) {
          deleteCardItem()
        } else {
          errorList?.forEach((item) => error(item?.messages[0]))
        }
      })
      .catch((err) => {
        error(generalErrorText(err))
      })
  }

  const newCardForm = withNewCard && (
    <>
      <StyledCardCheckboxContainer
        onClick={(evt) => {
          evt.stopPropagation()
          evt.preventDefault()

          setSelected(newCard)
        }}>
        <StyledRadioButton>
          <RadioInput
            label="."
            isChecked={selected?.cardId === newCard?.cardId}
          />
        </StyledRadioButton>

        <StyledNewCardText>
          <FormattedMessage
            id="dashboard::profile::newCard"
            defaultMessage="New Card"
          />
        </StyledNewCardText>
      </StyledCardCheckboxContainer>
      <StyledInoutContainer>
        <PaymentMethods
          disabled={selected?.cardId != newCard?.cardId}
          onSelect={(val) => {
            setSelectedPaymentType(val)
            callCreateClientSecret({
              variables: { stripePaymentMethodType: val },
            })
          }}
          hideBankTransfer={true}
        />
        <Spacer bottom={2} />
      </StyledInoutContainer>
      <StyledNewCardForm>
        <Formik
          enableReinitialize="true"
          initialValues={{
            cardholderName: "",
          }}
          validateOnMount
          validationSchema={validationSchema}
          onSubmit={(
            { paymentAmount, cardholderName, agreeTerms, saveCard },
            form
          ) => {
            handleSubmit({
              paymentAmount,
              cardholderName,
              agreeTerms,
              saveCard,
            })
          }}>
          {({
            values,
            touched,
            errors,
            handleChange,
            handleBlur,
            setFieldTouched,
            setFieldValue,
          }) => (
            <Form>
              {!isPayPal && (
                <>
                  <StyledInoutContainer>
                    <StyledFormRow>
                      <p className="caption">
                        <FormattedMessage
                          id="campaign::donationForm::cardholderName"
                          defaultMessage="Cardholder Name"
                        />
                      </p>
                      <TextInput
                        newDesign
                        id="cardholderName"
                        name="cardholderName"
                        value={newCard.cardholderName}
                        placeholder={formatMessage({
                          id: "campaign::donationForm::cardholderNamePlaceHolder",
                          defaultMessage: "Name on the card",
                        })}
                        error={errors.cardholderName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        touched={touched.cardholderName}
                        disabled={selected?.cardId !== newCard?.cardId}
                      />
                    </StyledFormRow>
                    <StyledFormRow className="full-width credit_card">
                      <p className="caption">
                        <FormattedMessage
                          id="campaign::donationForm::cardDetails"
                          defaultMessage="Card Details"
                        />
                      </p>
                      <CardElement
                        cardError={!!cardError || !!stripeErrorData}
                        onChange={(card) => handleCardChange(card, errors)}
                        options={{
                          style: cardElementStyles,
                          disabled: selected?.cardId !== newCard?.cardId,
                        }}
                      />
                      <p ref={creditCardRefError} className="input-feedback">
                        {cardError}
                      </p>
                      {stripeErrorData && (
                        <p>
                          <FormErrors errors={stripeErrorData} />
                        </p>
                      )}
                    </StyledFormRow>
                  </StyledInoutContainer>
                </>
              )}
              <StyledFormRow>
                <StyledButtonContainer>
                  <Button
                    color="blue"
                    variant="outlined"
                    label="delete the card, use selected"
                    data-testid="btn-cancel"
                    disabled={
                      (selected?.cardId === "" &&
                        Object.keys(errors)?.length &&
                        !isPayPal) ||
                      !selected
                    }
                    onClick={() => {
                      if (!selected) {
                        return
                      }
                      if (selected?.cardId !== "") {
                        updateSubscriptions(selected?.cardId)
                      } else {
                        createNewCardAndDelete(values?.cardholderName)
                      }
                    }}>
                    <FormattedMessage
                      id="campaign::creditCardSelector::deleteCardUseSelected"
                      defaultMessage="delete the card, use selected"
                    />
                  </Button>
                  <Button
                    color="transparent"
                    variant="outlined"
                    label="delete the card, cancel recurring donation"
                    data-testid="btn-cancel"
                    onClick={() => {
                      if (!selected) {
                        return
                      }

                      cancelSubscriptions()
                    }}>
                    <FormattedMessage
                      id="campaign::creditCardSelector::deleteCardCancelRecurring"
                      defaultMessage="delete the card, cancel recurring donation"
                    />
                  </Button>
                </StyledButtonContainer>
              </StyledFormRow>
            </Form>
          )}
        </Formik>
      </StyledNewCardForm>
    </>
  )

  return (
    <>
      <StyledCardList>
        {cardList?.length > 0 &&
          cardList?.map((card, index) => (
            <StyledCardCheckboxContainer
              key={index}
              onClick={(evt) => {
                evt.stopPropagation()
                evt.preventDefault()

                setSelected(card)

                if (typeof onChange === "function") {
                  onChange(card)
                }
                if (typeof onClick === "function") {
                  onClick(card)
                }
              }}>
              <StyledRadioButton
                data-tooltip-id={`card_${index}`}
                data-tooltip-content={tooltipText}>
                <RadioInput
                  data-testid={"test" + index}
                  label="."
                  isChecked={selected?.cardId === card?.cardId}
                  index={index}
                />
                {withSetDefault && selected?.cardId !== card?.cardId && (
                  <Tooltip
                    arrowColor="transparent"
                    effect="solid"
                    place="left"
                    type="info"
                    textColor="black"
                    backgroundColor="white"
                    className="tooltip"
                    id={`card_${index}`}
                    delayShow={500}
                    offset={{ top: 20, right: 10 }}
                  />
                )}
              </StyledRadioButton>
              <CreditCard
                cardType={card?.brand}
                lastFour={card?.last4}
                email={card?.paypalEmail}
                className={selected?.cardId === card?.cardId ? "active" : ""}
                expiry={
                  card?.expMonth && card?.expYear
                    ? `${card?.expMonth}/${card?.expYear}`
                    : ""
                }
              />
              {withDeleteButton && (
                <StyledDeleteButton
                  onClick={(evt) => {
                    evt.stopPropagation()
                    evt.preventDefault()
                    setCardToDelete(card)
                    onDeleteClick(card)
                  }}>
                  <FontAwesomeIcon icon={faTrash} />
                </StyledDeleteButton>
              )}
            </StyledCardCheckboxContainer>
          ))}
        {withNewCard && newCardForm}
      </StyledCardList>
      <CustomModal
        isModalOpen={modalStatus}
        header={isTablet && deleteCardTitle}
        children={isTablet ? deleteCardDescription : mobileModalContent}
        isCloseIcon={true}
        cancelButtonAction={() => {
          closeModal()
          setCardToDelete(null)
        }}
        confirmButtonText={isTablet && deleteCardText}
        confirmButtonAction={() => {
          deleteCardItem()
          closeModal()
        }}
        isMobile={!isTablet}
      />
    </>
  )
}

export default CreditCardSelector
