import styled from "@emotion/styled"
import { css } from "@emotion/react"

export const StyledCard = styled.div`
  cursor: pointer;
  background: ${(p) => p.theme.colors.white};
  border-radius: 0;
  overflow: hidden;
  display: grid;

  box-shadow: rgba(60, 64, 67, 0.3) 0 0.0625rem 0.125rem 0,
    rgba(60, 64, 67, 0.15) 0 0.125rem 0.375rem 0.125rem;

  &:hover {
    box-shadow: rgba(0, 0, 0, 0.15) 0 0.9375rem 1.5625rem,
      rgba(0, 0, 0, 0.05) 0 0.3125rem 0.625rem;
  }

  ${(p) =>
    p.size === "default"
      ? css`
          //mainpage default mobile view
          width: 9.5rem;
          height: 12.6875rem;
          grid-template-rows: 4.6875rem auto 2.25rem;
        `
      : css`
          // campaigns page mobile list view
          width: 100%;
          height: 9rem;
          padding: 0.75rem 1rem;
          grid-template-rows: 4rem auto;
          grid-template-columns: 7.1875rem 3.75rem auto;
          grid-template-areas: "image info info" "stats stats details";
        `}

  .caption {
    font-size: 0.5rem;
    line-height: 0.75rem;
    white-space: nowrap !important;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    border-radius: 0.25rem;
    .caption {
      font-size: 0.625rem;
      line-height: 1rem;
      white-space: nowrap !important;
    }

    ${(p) =>
      p.size === "default"
        ? css`
            //mainpage default tablet view
            width: 14.5rem;
            height: 17.5rem;
            padding: 0;
            grid-template-columns: unset;
            grid-column-gap: unset;
            grid-template-areas: unset;
            grid-template-rows: 7rem auto 3.125rem;
          `
        : css`
            // campaigns page tablet view
            padding: 0.75rem;
            grid-template-columns: 10.75rem auto;
            grid-template-rows: 3.25rem 2.8125rem auto;
            grid-column-gap: 0.75rem;
            grid-template-areas: "image info" "image details" ". stats";
            width: 100%;
            height: 9.9375rem;
          `}
    ${(p) =>
      p.variant === "summary" &&
      css`
        //checkout summary page tablet view
        padding: 0;
        grid-template-columns: unset;
        grid-column-gap: unset;
        grid-template-areas: unset;
        grid-template-rows: 9.1875rem auto 3.25rem;
        width: 15rem;
        height: 22.0625rem;
      `}
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    border-radius: 0.25rem;

    .rc-progress-line {
      bottom: 0.75rem;
    }
    ${(p) =>
      p.size === "list"
        ? css`
            //campaign page desktop list view
            width: 100%;
            height: 10.1875rem;
            padding: 0;
            grid-template-columns: 18.125rem auto;
            grid-template-rows: 3.25rem 2.8125rem auto;
            grid-column-gap: 0.75rem;
            grid-template-areas: "image info" "image details" "image stats";
          `
        : p.size === "tile"
        ? css`
            //campaign page desktop tile view
            padding: 0;
            grid-template-columns: unset;
            grid-column-gap: unset;
            grid-template-areas: unset;
            grid-template-rows: 9.1875rem auto 3.25rem;
            width: 15rem;
            height: 22.0625rem;
          `
        : css`
            //mainpage default desktop view
            padding: 0;
            grid-template-columns: unset;
            grid-column-gap: unset;
            grid-template-areas: unset;
            width: 19.3125rem;
            height: 21.75rem;
            grid-template-rows: 10.875rem auto 3.125rem;
          `}
    ${(p) =>
      p.variant === "summary" &&
      css`
        padding: 0;
        grid-template-columns: unset;
        grid-column-gap: unset;
        grid-template-areas: unset;
        grid-template-rows: 9.1875rem auto 3.25rem;
        width: 15rem;
        height: 22.0625rem;
      `}
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    .caption {
      font-size: 1rem;
      line-height: 1.5rem;
      margin-top: 0.75rem;
    }
    ${(p) =>
      p.size === "list"
        ? css`
            //campaigns page wide list view
            width: 100%;
            height: 14.375rem;
            padding: 0;
            grid-template-columns: 26.375rem auto;
            grid-template-rows: 3.875rem 4.6875rem auto;
            grid-column-gap: 1rem;
            grid-template-areas: "image info" "image details" "image stats";
          `
        : p.size === "tile"
        ? css`
            width: 25.875rem;
            height: 31.9375rem;
            grid-template-rows: 14.5625rem auto 4.375rem;
          `
        : css`
            //mainpage default wide view
            width: 25.875rem;
            height: 31.9375rem;
            grid-template-rows: 14.5625rem auto 4.375rem;
          `}
    ${(p) =>
      p.variant === "summary" &&
      css`
        width: 25.875rem;
        height: 31.9375rem;
        grid-template-rows: 14.5625rem auto 4.375rem;
      `}
  }
`

export const StyledImageBox = styled.div`
  overflow: hidden;
  position: relative;
  ${(p) =>
    p.isCorporate &&
    css`
      text-align: center;
    `}
  > div > img {
    width: 100%;
    height: 100%;
    ${(p) =>
      p.isCorporate &&
      css`
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 100%;
      `}
  }

  .rc-progress-line {
    position: absolute;
    bottom: 0.5rem;
    margin: 0 0.75rem;
  }

  ${(p) =>
    p.size === "default"
      ? css`
          //mainpage default mobile view
          grid-area: unset;
        `
      : p.size === "tile"
      ? css`
          //tile view
          grid-area: unset;
        `
      : css`
          // campaigns page mobile list view
          grid-area: image;
        `}

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    ${(p) =>
      p.size === "default"
        ? css`
            //mainpage default tablet view
            grid-area: unset;
          `
        : css`
            // campaigns page tablet view
          `}
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    ${(p) =>
      p.size === "list"
        ? css`
            //campaign page desktop list view
          `
        : p.size === "tile"
        ? css`
            //campaign page desktop tile view
            grid-area: unset;
            border: none;
          `
        : css`
            //mainpage default view
            grid-area: unset;
          `}
  }
`

export const StyledCardInfo = styled.div`
  border: none;
  padding: 0.5rem;
  word-break: break-word;
  ${(p) =>
    p.isComingSoon &&
    css`
      p,
      span {
        color: ${p.theme.colors.disabled};
      }
    `}
  ${(p) =>
    (p.offer || p.store) &&
    css`
      padding: 0;
    `}
  ${(p) =>
    p.size === "default"
      ? css`
          //mainpage default mobile view
          grid-area: unset;
        `
      : p.size === "tile"
      ? css`
          //tile view
          grid-area: unset;
        `
      : css`
          // campaigns page mobile view
          grid-area: info;
          display: grid;
          grid-template-rows: auto 1.5rem;
        `}

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    ${(p) =>
      p.size === "default"
        ? css`
            //mainpage default tablet view
            grid-area: unset;
            padding: 0.75rem;
          `
        : p.size === "tile"
        ? css`
            //tile view
            padding: 0.25rem 0.75rem 0;
          `
        : css`
            // campaigns page tablet list view
            padding: 0;
            border: none;
          `}
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    ${(p) =>
      p.size === "list"
        ? css`
            //campaign page desktop list view
            border: none;
            padding: 0.75rem 0.75rem 0 0;
          `
        : p.size === "tile"
        ? css`
            //campaign page desktop tile view
            display: block;
            grid-area: unset;
            padding: 0.75rem;
            padding-bottom: 0;
          `
        : css`
            //mainpage default view
            grid-area: unset;
          `}
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    ${(p) =>
      p.size === "list"
        ? css`
            //campaign page desktop list view
            border: none;
            padding: 1rem 1rem 0 0;
          `
        : p.size === "tile"
        ? css`
            //campaign page desktop tile view
            grid-area: unset;
            padding: 1rem;
          `
        : css`
            //mainpage default view
            grid-area: unset;
            padding: 1.5rem;
          `}
  }
`

export const StyledCardTitle = styled.p`
  margin-bottom: 0.25rem;
  cursor: pointer;
  ${(p) =>
    p.offer &&
    css`
      margin-bottom: 0.5rem;
    `}
  ${(p) =>
    p.store &&
    css`
      margin-bottom: 0;
    `}
  span {
    font-family: ${(p) => p.theme.fontFamilies.headline};
    color: ${(p) => p.theme.colors.black};
    font-style: ${(p) => p.theme.fontStyles.normal};
    font-weight: ${(p) => p.theme.fontWeights.semiBolder};
    font-size: 1.125rem;
    line-height: 1.5rem;
    word-break: break-word;
    ${(p) =>
      p.offer &&
      css`
        // height: 2.25rem;
      `}
    @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
      font-size: 1.125rem;
      line-height: 1.5rem;
      ${(p) =>
        p.store &&
        css`
          margin-bottom: 0.5rem;
        `}
      ${(p) => p.size === "tile" && css``}
    }
    @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
      ${(p) =>
        p.offer &&
        css`
          height: 3rem;
        `}
      ${(p) =>
        p.size === "list"
          ? css`
              //campaign page desktop list view
            `
          : p.size === "tile"
          ? css``
          : css`
              //mainpage default view
            `}
    }
    @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
      font-size: 1.5rem;
      line-height: 2rem;
      ${(p) =>
        p.offer &&
        css`
          height: 5.625rem;
          font-size: 2rem;
          line-height: 2.625rem;
        `}
    }
  }
`

export const StyledCardSubtitle = styled.p``

export const StyledStats = styled.div`
  display: flex;
  align-items: center;
  align-self: end;
  .amount {
    display: block;
    margin-bottom: 0.2rem;
    font-family: ${(p) => p.theme.fontFamilies.headline};
    font-weight: ${(p) => p.theme.fontWeights.semiBolder};
    color: ${(p) => p.theme.colors.black};
    font-size: 1rem;
  }
  .right-eight-digit {
    font-size: 0.75rem;
  }
  .caption {
    text-align: right;
  }

  ${(p) =>
    p.size === "default"
      ? css`
          //mainpage default mobile view
          grid-area: unset;
        `
      : css`
          // campaigns page mobile view
          grid-area: stats;
        `}

  ${(p) =>
    p.size === "list"
      ? css`
          //mobile list view
          padding: 0;
          p:first-of-type {
            margin-right: 1.5rem;
          }
          .caption {
            text-align: left;
          }
        `
      : p.size === "tile"
      ? css``
      : css`
          //mainpage default view
          grid-area: unset;
          justify-content: space-between;
          padding-right: 0.2rem;
          padding-left: 0.2rem;
          padding-bottom: 0.2rem;
          margin: 0;
          p.caption:first-of-type {
            text-align: left;
          }
        `}

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    .amount {
      font-size: 1.125rem;
    }

    ${(p) =>
      p.size === "default"
        ? css`
            //mainpage default tablet view
            grid-area: unset;
            padding: 0 0.75rem 0.75rem;
            margin: 0;
          `
        : css`
            // campaigns page tablet list view
            padding: 0;
            p:first-of-type {
              margin-right: 4rem;
            }
          `}
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    border: none;
    ${(p) =>
      p.size === "list"
        ? css`
            //campaign page desktop list view
            padding-bottom: 0.75rem;
            p:first-of-type {
              margin-right: 5.375rem;
            }
          `
        : p.size === "tile"
        ? css`
            //campaign page desktop tile view
            grid-area: unset;
            padding: 0 0.75rem 0.75rem;
            justify-content: space-between;
            p:first-of-type {
              margin: 0;
              text-align: left;
            }
          `
        : css`
            //mainpage default view
            grid-area: unset;
            border-top: none;
            border-radius: 0 0 0.25rem 0.25rem;
            margin: 0;
          `}
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    ${(p) =>
      p.size === "list"
        ? css`
            //campaign page desktop list view
            padding-bottom: 1rem;
            p:first-of-type {
              margin-right: 8.75rem;
            }
          `
        : p.size === "tile"
        ? css`
            //campaign page desktop tile view
            grid-area: unset;
            padding: 0 1rem 1rem;
          `
        : css`
            //mainpage default view
            grid-area: unset;
            padding: 0 1.5rem 1.5rem;
            .amount {
              font-size: 2rem;
            }
          `}
  }
`

export const StyledPartnerTitle = styled.div`
  grid-area: details;
  justify-self: right;
  display: flex;
  align-items: center;
  margin-top: 0.125rem;
  text-transform: initial;
  img {
    height: 2rem;
    width: 2rem;
    margin: 0 0.5rem 0 0;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    ${(p) =>
      p.size === "default"
        ? css`
            //mainpage default tablet view
            grid-area: unset;
          `
        : css`
            // campaigns page tablet list view
          `}
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    grid-area: unset;

    ${(p) =>
      p.size === "list"
        ? css`
            //campaign page desktop list view
          `
        : p.size === "tile"
        ? css`
            //campaign page desktop tile view
            margin-top: 0.5rem;
          `
        : css`
            //mainpage default view
          `}
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    p {
      font-size: 1rem;
      line-height: 1.5rem;
    }
    img {
      height: 3.375rem;
      width: 3.375rem;
      margin: 0 1.25rem 0 0;
    }
  }
`

export const StyledPartnerDetails = styled.div`
  //campaigns page mobile view
  grid-area: details;
  justify-self: end;
  align-self: end;
  > div {
    display: flex;
    img {
      height: 2rem;
      width: 2rem;
      margin: 0 0 0 0.75rem;
    }
  }
  p {
    text-align: right;
  }
`

export const StyledPartnerLogo = styled.img`
  object-fit: contain;
  border-radius: 0.25rem;
  height: 2.5rem;
  margin-right: 0.75rem;
  align-self: center;
`

export const StyledPartnerTitleText = styled.p`
  font-family: ${(p) => p.theme.fontFamilies.headline};
  font-style: ${(p) => p.theme.fontStyles.normal};
  font-weight: ${(p) => p.theme.fontWeights.semiBolder};
  font-size: 0.75rem;
  line-height: 1rem;
  display: flex;
  align-items: center;
  color: ${(p) => p.theme.colors.black};
`

export const StyledCardDiscount = styled.p`
  font-family: ${(p) => p.theme.fontFamilies.headline};
  font-style: ${(p) => p.theme.fontStyles.normal};
  font-weight: ${(p) => p.theme.fontWeights.semiBolder};
  font-size: 0.75rem;
  line-height: 1.125rem;
  color: ${(p) => p.theme.colors.blue};
  border-radius: 0 0.5rem 0.5rem 0;
  display: flex;
  align-items: center;
  padding: 0;
  img {
    height: 1.3125rem;
    width: 1.3125rem;
    margin-right: 0.25rem;
  }
  span {
    font-family: ${(p) => p.theme.fontFamilies.headline};
    font-style: ${(p) => p.theme.fontStyles.normal};
    font-weight: ${(p) => p.theme.fontWeights.semiBolder};
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    ${(p) =>
      p.size === "tile" &&
      css`
        padding: 0 0.75rem 0.3125rem;
        font-size: 0.75rem;
        line-height: 1.125rem;
        img {
          height: 0.8125rem;
          width: 1rem;
        }
      `}
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    ${(p) =>
      p.size === "tile" &&
      !p.isOfflineStore &&
      css`
        padding: 0.3rem 0.75rem 0.4rem 0.75rem;
        font-size: 1.125rem;
        line-height: 1.5rem;
        img {
          height: 1.3125rem;
          width: 1.3125rem;
        }
      `}

    ${(p) =>
      p.size === "tile" &&
      p.isOfflineStore &&
      css`
        padding: 0.3rem 0.75rem 0.4rem 0.75rem;
        font-size: 0.75rem;
        line-height: 1.125rem;
        img {
          height: 1.3125rem;
          width: 1.3125rem;
        }
      `}
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 2rem;
    line-height: 2.625rem;
    padding: 1rem 0 1rem 1rem;
    img {
      height: 1.875rem;
      width: 2.25rem;
      margin-right: 0.5rem;
    }
    ${(p) =>
      p.size === "tile" &&
      p.isOfflineStore &&
      css`
        padding: 1rem;
      `}
    ${(p) =>
      p.size === "tile" &&
      !p.isOfflineStore &&
      css`
        padding: 1rem;
      `}
  }
`

export const StyledCardDescription = styled.p`
  font-size: 0.625rem;
  line-height: 1rem;
  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    font-size: 0.75rem;
    line-height: 1.125rem;
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 1rem;
    line-height: 1.375rem;
  }
`
export const StyledPACMultiplier = styled.div`
  position: absolute;
  font-family: ${(p) => p.theme.fontFamilies.headline};
  font-style: ${(p) => p.theme.fontStyles.normal};
  font-weight: ${(p) => p.theme.fontWeights.semiBolder};
  background: ${(p) => p.theme.colors.carrot};
  color: ${(p) => p.theme.colors.white};

  top: 0;
  right: 0;
  min-width: 2.5rem;
  height: 1.125rem;
  padding: 0.125rem 0.375rem;
  border-radius: 0 0.25rem;
  font-size: 0.625rem;
  line-height: 1.5;
  img {
    width: 0.625rem;
    height: 0.625rem;
    position: relative;
    top: 0.1rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    font-size: 0.75rem;
    min-width: 3.0625rem;
    height: 1.5625rem;
    padding: 0.25rem 0.5rem;

    img {
      width: 0.75rem;
      height: 0.75rem;
      top: 0.125rem;
    }
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 1.5rem;
    min-width: 6.1875rem;
    height: 2.6875rem;
    border-radius: 0 0.5rem;
    padding: 0.375rem 1rem;

    img {
      width: 1.5625rem;
      height: 1.5625rem;
      top: 0.15rem;
    }
  }
`
