import React, { useState } from "react"
import { FormattedMessage, useIntl, navigate } from "gatsby-plugin-intl"
import { useQuery } from "@apollo/client"
import { useAuth, useDefaultMerchant } from "@tmu/hooks"
import { faPlus } from "@fortawesome/pro-solid-svg-icons/faPlus"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useApolloApiClients } from "@tmu/apollo/client"
import {
  Button,
  NewCard,
  Spinner,
  Tabs,
  TabList,
  Tab,
  VoucherChecker,
} from "@tmu/components/common"
import { SliceCampaigns } from "@tmu/components/home"
import { getDateWithZeroHours, getValueForLocale } from "@tmu/utils/string"
import { addDays } from "date-fns"
import { getAllScreenTypes } from "@tmu/utils/mediaQueries"
import { MERCHANT_CAMPAIGN_LISTING_QUERY } from "@tmu/apollo/storefront/queries/merchant"
import {
  API_PERMISSIONS,
  CAMPAIGNS_TABS_MENU,
  CARDS_PER_PAGE,
  LIST_TYPES,
} from "@tmu/apollo/constants"
import { OFFER_TYPE } from "@tmu/apollo/constants"

import {
  StyledMainContainer,
  StyledMobileContainer,
  StyledTitle,
  StyledContent,
  StyledMerchantCampaignsList,
  StyledNonMobileContainer,
  StyledMerchantCampaignsCard,
} from "./index.styles"

const MerchantCampaigns = () => {
  const { merchantClient } = useApolloApiClients()
  const { locale, defaultLocale, formatMessage } = useIntl()
  const { isLoading, user, apiPermissions } = useAuth()
  const [tabIndex, setTabIndex] = useState(0)
  const { defaultMerchant, isMerchantCorporate, isMerchantOffline } =
    useDefaultMerchant()
  const { isTablet } = getAllScreenTypes()

  const tabMenu = CAMPAIGNS_TABS_MENU(formatMessage)

  const getVariables = () => {
    if (!user?.id || user?.id.length === 0 || isLoading) {
      return
    }

    const variables = { first: CARDS_PER_PAGE, orderBy: "-created" }

    return tabIndex === 0
      ? {
          ...variables,
          startsAt_Lte: getDateWithZeroHours(addDays(new Date(), 1)),
          visibilityStatus: ["1", "2", "3"],
          status_In: ["APPROVED"],
        }
      : tabIndex === 1
      ? {
          ...variables,
          startsAt_Gt: getDateWithZeroHours(addDays(new Date(), 1)),
          status_In: ["APPROVED"],
        }
      : tabIndex === 2
      ? {
          ...variables,
          status_In: ["ENDED"],
        }
      : { ...variables }
  }

  const { loading, data } = useQuery(MERCHANT_CAMPAIGN_LISTING_QUERY, {
    client: merchantClient,
    variables: getVariables(),
    fetchPolicy: "network-only",
  })

  const campaigns = data?.allCampaigns?.edges

  const hasCampaigns = campaigns?.length > 0

  const hasAllCampaigns = data?.allCampaignsTotalCount?.totalCount > 0

  const tabs = tabMenu

  const handleCreateCampaign = () => {
    if (isMerchantOffline) {
      navigate("/dashboard/campaign/new/?type=collection&offline=true")
    } else {
      navigate("/dashboard/campaign?corporate=true")
    }
  }

  const merchantUsers = user?.merchantUsers?.edges

  const activeMerchantTemp =
    merchantUsers?.length === 1
      ? merchantUsers?.[0]
      : merchantUsers?.find(
          (merchant) =>
            merchant?.node?.store?.id === user?.activeStore?.id ||
            merchant?.node?.store?.id === defaultMerchant?.id
        )

  const permission = (type) =>
    apiPermissions?.includes(type) ||
    activeMerchantTemp?.node?.finalPermissions?.includes(type)

  return (
    <StyledMainContainer>
      <div className="top-bar">
        <FormattedMessage
          id={isTablet ? "dashboard::nav::merchantsCampaigns" : " "}
          defaultMessage={isTablet ? "Merchant's Campaigns" : " "}
          tagName={isTablet ? "h1" : "h4"}
        />
        <VoucherChecker isCampaign={true} apiType={{ merchant: true }} />
        {isTablet && permission(API_PERMISSIONS.MERCHANT_CREATE_CAMPAIGN) && (
          <Button
            data-testid="btn-create-campaign"
            variant="text"
            onClick={handleCreateCampaign}
            type="button">
            <FontAwesomeIcon icon={faPlus} />
            {isMerchantOffline ? (
              <FormattedMessage
                id="supporter::campaigns::offlineButton"
                defaultMessage="Create a campaign"
              />
            ) : (
              <FormattedMessage
                id="supporter::campaigns::button"
                defaultMessage="Create a campaign/event"
              />
            )}
          </Button>
        )}
      </div>

      <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
        <TabList>
          {tabs.map(({ key, label, dataTestId }) => (
            <Tab key={key} data-testid={"test-id-" + dataTestId}>
              {label}
            </Tab>
          ))}
        </TabList>
      </Tabs>

      {!isTablet && (
        <StyledMobileContainer>
          {loading ? (
            <Spinner condensed />
          ) : (
            <>
              {!hasCampaigns && !hasAllCampaigns ? (
                <>
                  <StyledTitle>
                    <FormattedMessage
                      id="supporter::campaigns::title1"
                      defaultMessage="Create your first campaign"
                    />
                  </StyledTitle>
                  <StyledContent>
                    <FormattedMessage
                      id="supporter::campaigns::content1"
                      defaultMessage="Click to choose a charity you want to support"
                    />
                  </StyledContent>
                </>
              ) : tabIndex === 0 && !hasCampaigns ? (
                <>
                  <StyledTitle>
                    <FormattedMessage
                      id="supporter::campaigns::title2"
                      defaultMessage="You don't have active campaigns"
                    />
                  </StyledTitle>
                  <StyledContent>
                    <FormattedMessage
                      id="supporter::campaigns::content1"
                      defaultMessage="Click to choose a charity you want to support"
                    />
                  </StyledContent>
                </>
              ) : tabIndex === 1 && !hasCampaigns ? (
                <StyledContent className="mobile-message-content">
                  <FormattedMessage
                    id="supporter::campaigns::content3"
                    defaultMessage="You don't have upcoming campaigns"
                  />
                </StyledContent>
              ) : tabIndex === 2 && !hasCampaigns ? (
                <StyledContent className="mobile-message-content">
                  <FormattedMessage
                    id="supporter::campaigns::content2"
                    defaultMessage="You don't have finished campaigns."
                  />
                </StyledContent>
              ) : loading ? (
                <Spinner condensed />
              ) : (
                <StyledMerchantCampaignsList>
                  {campaigns?.map(({ node: campaign }) => {
                    return (
                      <NewCard
                        variant="campaign"
                        key={campaign?.id}
                        imageSrc={
                          campaign?.image?.url ||
                          campaign?.images?.edges?.[0]?.node?.image
                        }
                        title={getValueForLocale(
                          campaign,
                          "name",
                          locale,
                          defaultLocale
                        )}
                        type={campaign?.campaignType}
                        totalDonors={campaign?.donationCount}
                        totalSupport={campaign?.fundedAmount}
                        size={LIST_TYPES.LIST}
                        cardLink={`/campaigns/${campaign?.slug}`}
                        goalAmount={campaign?.goalAmount}
                        progress={campaign?.progress}
                        fundedAmount={campaign?.fundedAmount}
                        partnerLogo={getValueForLocale(
                          campaign?.partner,
                          "logo",
                          locale,
                          defaultLocale
                        )}
                        storeLogo={getValueForLocale(
                          campaign?.store,
                          "logo",
                          locale,
                          defaultLocale
                        )}
                        partnerName={
                          campaign?.partner?.displayName ||
                          campaign?.partner?.name
                        }
                        taxId={campaign?.partner?.taxId}
                        pacMultiplier={campaign?.pacMultiplier}
                        isMerchantCorporate={
                          campaign?.store?.defaultOffer?.offerType ===
                          OFFER_TYPE.BRAND
                        }
                        isStoreSupporter
                      />
                    )
                  })}
                </StyledMerchantCampaignsList>
              )}
              <div className="sticky-bottom-bar">
                <Button
                  data-testid="btn-create-campaign"
                  color="transparent"
                  type="button"
                  onClick={handleCreateCampaign}>
                  <FontAwesomeIcon icon={faPlus} />
                  <FormattedMessage
                    id="merchant::campaigns::button"
                    defaultMessage="Create a campaign"
                  />
                </Button>
              </div>
            </>
          )}
        </StyledMobileContainer>
      )}

      {isTablet && (
        <StyledNonMobileContainer>
          {loading ? (
            <Spinner condensed />
          ) : !hasCampaigns && !hasAllCampaigns ? (
            permission(API_PERMISSIONS.MERCHANT_CREATE_CAMPAIGN) ? (
              <StyledMerchantCampaignsCard onClick={handleCreateCampaign}>
                <FontAwesomeIcon icon={faPlus} />
                <StyledTitle>
                  <FormattedMessage
                    id="merchant::campaigns::title1"
                    defaultMessage="Create your first campaign"
                  />
                </StyledTitle>
                <StyledContent>
                  <FormattedMessage
                    id="supporter::campaigns::content1"
                    defaultMessage="Click to choose a charity you want to support"
                  />
                </StyledContent>
              </StyledMerchantCampaignsCard>
            ) : (
              <FormattedMessage
                id="supporter::campaigns::content4"
                defaultMessage="You don't have permission to create a campaign. Please contact your Administrator to create campaign."
              />
            )
          ) : tabIndex === 0 && !hasCampaigns ? (
            permission(API_PERMISSIONS.MERCHANT_CREATE_CAMPAIGN) ? (
              <StyledMerchantCampaignsCard onClick={handleCreateCampaign}>
                <FontAwesomeIcon icon={faPlus} />
                <StyledTitle>
                  <FormattedMessage
                    id="supporter::campaigns::title2"
                    defaultMessage="You don't have active campaigns"
                  />
                </StyledTitle>
                <StyledContent>
                  <FormattedMessage
                    id="supporter::campaigns::content1"
                    defaultMessage="Click to choose a charity you want to support"
                  />
                </StyledContent>
              </StyledMerchantCampaignsCard>
            ) : (
              <FormattedMessage
                id="supporter::campaigns::content4"
                defaultMessage="You don't have permission to create a campaign. Please contact your Administrator to create campaign."
              />
            )
          ) : tabIndex === 1 && !hasCampaigns ? (
            <StyledContent className="non-mobile-message-content">
              <FormattedMessage
                id="supporter::campaigns::content3"
                defaultMessage="You don't have upcoming campaigns"
              />
            </StyledContent>
          ) : tabIndex === 2 && !hasCampaigns ? (
            <StyledContent className="non-mobile-message-content">
              <FormattedMessage
                id="supporter::campaigns::content2"
                defaultMessage="You don't have finished campaigns."
              />
            </StyledContent>
          ) : loading ? (
            <Spinner condensed />
          ) : (
            <SliceCampaigns
              headline=" "
              campaigns={campaigns}
              isStoreSupporter
              isMerchantCorporate={isMerchantCorporate}
              isMerchantOffline={isMerchantOffline}
            />
          )}
        </StyledNonMobileContainer>
      )}
    </StyledMainContainer>
  )
}

export default MerchantCampaigns
