import { faDove } from "@fortawesome/free-solid-svg-icons/faDove"
import { faBahai } from "@fortawesome/pro-solid-svg-icons/faBahai"
import { faPencil } from "@fortawesome/pro-solid-svg-icons/faPencil"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { FOOTER_TYPE } from "@tmu/apollo/constants"
import { LazyImage, Spinner } from "@tmu/components/common"
import { HomePageContext } from "@tmu/context/homePageContext"
import { useFooterType } from "@tmu/hooks"
import { StyledJoinPageWrapper } from "@tmu/global/page-addons/how-it-works.styles"
import { useIntl } from "gatsby-plugin-intl"
import { useContext, useEffect, useState } from "react"
import { SliceCampaigns } from "@tmu/components/home"
import { HowItWorksForm } from "@tmu/components/how-it-works"
import { getAllScreenTypes } from "@tmu/utils/mediaQueries"
import {
  StyledBenefitCard,
  StyledBenefits,
  StyledBenefitsList,
  StyledCorporateDescription,
  StyledCorporateHeader,
  StyledCorporateImageWrapper,
  StyledCorporateSVGWrapper,
  StyledCorporateStoryBox,
  StyledCorporateTitle,
  StyledDescription,
  StyledFontIcon,
  StyledImage,
  StyledMobileTextWrapper,
  StyledOverlay,
  StyledText,
  StyledTitle,
  StyledWrapper,
  SliceCampaignsWrapper,
  StyledContactFormDescription,
  StyledContactFormTitle,
} from "./index.style"

const { CLOUDFLARE_IMAGE_URL } = process.env

const Corporate = ({ corporateCampaigns }) => {
  const { formatMessage, locale } = useIntl()
  const { setHeaderConfigData } = useContext(HomePageContext)
  const [firstLoad, setFirstLoad] = useState(false)
  const { isTablet, isDesktop, isWide } = getAllScreenTypes()

  const imageHeight = isWide ? 800 : isDesktop ? 500 : isTablet ? 420 : 350

  useFooterType({ footerType: FOOTER_TYPE.WIDE })

  useEffect(() => {
    setHeaderConfigData({ transparent: true })
  }, [])

  const benefitCards = [
    {
      title: formatMessage({
        id: "join::corporate::title1",
        defaultMessage: "Create a collection or an event page",
      }),
      desc: formatMessage({
        id: "join::corporate::description1",
        defaultMessage:
          "Choose charities you would like to support for creating your campaign",
      }),
      fontIcon: faPencil,
    },
    {
      title: formatMessage({
        id: "join::corporate::title2",
        defaultMessage: "Use your campaign in marketing activities",
      }),
      desc: formatMessage({
        id: "join::corporate::description2",
        defaultMessage:
          "Share the link to the page on social networks and spread your brand‘s image as charity friendly",
      }),
      fontIcon: faDove,
    },
    {
      title: formatMessage({
        id: "join::corporate::title3",
        defaultMessage: "Grow your business as charity friendly",
      }),
      desc: formatMessage({
        id: "join::corporate::description3",
        defaultMessage:
          "Put your brand name alongside with big and small Non-Profit organisations as a charity friendly",
      }),
      fontIcon: faBahai,
    },
  ]

  const sliceBannerData = {
    header: formatMessage({
      id: "join::corporate::heroHeader",
      defaultMessage: "Corporate fundraiser for your brand",
    }),
    title: formatMessage({
      id: "join::corporate::heroTitle",
      defaultMessage:
        "Create campaigns supporting charities of your choice, increase awareness of your customers and build a strong charity friendly brand",
    }),
    description: formatMessage({
      id: "join::corporate::heroDescription",
      defaultMessage:
        "You will receive charity friendly brand badge for your profile page",
    }),
    image:
      CLOUDFLARE_IMAGE_URL + "/static/assets/images/corporate/children2.png",
  }

  const pageData = {
    contactFormTitle: formatMessage({
      id: "join::forms::contactForm::corporateTitle",
      defaultMessage: "Join as a Corporate Fundraiser",
    }),
    contactFormDescription: formatMessage({
      id: "join::forms::contactForm::corporateDescription",
      defaultMessage: "Tell us about your store so we can help you to onboard.",
    }),

    sliceBannerData,
    benefitCards,
    termsLink: "/legal/merchant-terms/20",
    sender: "MERCHANT",
  }

  const contactFormTitle = (
    <StyledContactFormTitle>
      {pageData?.contactFormTitle}
    </StyledContactFormTitle>
  )
  const contactFormDescription = (
    <StyledContactFormDescription>
      {pageData?.contactFormDescription}
    </StyledContactFormDescription>
  )

  useEffect(() => {
    setFirstLoad(true)
  }, [])

  const textData = (
    <>
      <StyledCorporateTitle>
        {pageData?.sliceBannerData?.title}
      </StyledCorporateTitle>
      <StyledCorporateSVGWrapper>
        <StyledCorporateImageWrapper>
          <img
            src={
              CLOUDFLARE_IMAGE_URL +
              "/static/assets/images/corporate/social_Impact.png"
            }
            alt={`Trust Me Up`}
            width={isWide ? 160 : 80}
            height={isWide ? 160 : 80}
          />
        </StyledCorporateImageWrapper>
        <StyledCorporateDescription>
          {pageData?.sliceBannerData?.description}
        </StyledCorporateDescription>
      </StyledCorporateSVGWrapper>
    </>
  )

  if (!firstLoad) return <Spinner />

  return (
    <>
      <StyledJoinPageWrapper>
        <StyledWrapper>
          <div>
            <StyledCorporateStoryBox>
              <StyledText>
                <StyledCorporateHeader>
                  {pageData?.sliceBannerData?.header}
                </StyledCorporateHeader>
                {isTablet && textData}
              </StyledText>
              {!isTablet && <StyledOverlay></StyledOverlay>}
              <StyledImage>
                <LazyImage
                  altName="join as non-profit"
                  src={pageData?.sliceBannerData?.image}
                  width="auto"
                  height={imageHeight}
                  fit="cover"
                />
              </StyledImage>
            </StyledCorporateStoryBox>
            {!isTablet && (
              <StyledMobileTextWrapper>{textData}</StyledMobileTextWrapper>
            )}
          </div>

          <StyledBenefits>
            <StyledBenefitsList>
              {pageData?.benefitCards?.map((e, i) => (
                <StyledBenefitCard key={i}>
                  <StyledFontIcon>
                    <FontAwesomeIcon icon={e?.fontIcon} />
                  </StyledFontIcon>

                  {e?.title && <StyledTitle>{e?.title}</StyledTitle>}
                  {e?.desc && (
                    <StyledDescription locale={locale || "en"}>
                      {e?.desc}
                    </StyledDescription>
                  )}
                </StyledBenefitCard>
              ))}
            </StyledBenefitsList>
          </StyledBenefits>
          <SliceCampaignsWrapper>
            <SliceCampaigns
              title={formatMessage({
                id: "corporate::campaigns::corporateCampaigns",
                defaultMessage: "Charity friendly brands that joined us",
              })}
              campaigns={corporateCampaigns?.edges ?? []}
              isCorporate={true}
              isCorporateStore
            />
          </SliceCampaignsWrapper>

          <HowItWorksForm
            title={contactFormTitle}
            desc={contactFormDescription}
            termsLink={pageData?.termsLink}
            sender={pageData?.sender}
            isWebsite={true}
            corporate
          />
        </StyledWrapper>
      </StyledJoinPageWrapper>
    </>
  )
}
export default Corporate
