import styled from "@emotion/styled"
import { css } from "@emotion/react"

export const StyledOtherPartnerTitle = styled.div`
  font-weight: 700;
  text-align: left;
  font-family: ${(p) => p.theme.fontFamilies.headline};
  font-weight: ${(p) => p.theme.fontWeights.semiBolder};
  color: ${(p) => p.theme.colors.pitchBlack};
  font-size: 1.125rem;
  line-height: 1.75rem;
  margin-top: 2rem;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    font-size: 1.5rem;
    line-height: 2.125rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 3rem;
    margin-top: 3.375rem;
    line-height: 4.0856rem;
  }
`

export const StyledSlice = styled.div`
  margin: 0 auto;
  margin-top: 1rem;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    .chevron {
      height: 23.5rem;
    }
    margin-top: 1.5rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    margin-top: 2.625rem;
    .chevron {
      height: 33.5rem;
    }
  }
`

export const StyledOtherPartnersWrapper = styled.div`
  background-color: ${(p) => p.theme.colors.white};
  margin-left: -1rem;
  padding-left: 1rem;
  width: 110%;

  margin-top: -2rem;
  padding-top: 2rem;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    margin-top: 0;
    padding-top: 0;

    margin-left: 0;
    width: 100%;
  }
`

export const StyledOtherPartner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 4.75rem !important;
  height: 2.625rem !important;

  img {
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
    opacity: 0.6;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    width: 5.125rem !important;
    height: 2.875rem !important;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    width: 6.875rem !important;
    height: 3.8125rem !important;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    width: 12.375rem !important;
    height: 6.875rem !important;
  }
`
export const StyledChevronWrapper = styled.div`
  position: absolute;
  display: none;
  align-items: center;
  justify-content: center;
  height: 18.375rem;
  top: 0;
  width: 5rem;
  z-index: 2;
  ${(p) =>
    p.left
      ? css`
          left: -1rem;
          background: linear-gradient(
            90deg,
            #ffffff 42.13%,
            rgba(255, 255, 255, 0) 100%
          );
        `
      : css`
          right: -1.5rem;
          background: linear-gradient(
            270deg,
            #ffffff 42.13%,
            rgba(255, 255, 255, 0) 100%
          );
        `}
  svg {
    color: ${(p) => p.theme.colors.pacificBlue};
    background: ${(p) => p.theme.colors.white};
    border-radius: 50%;
    height: 2.5rem;
    width: 2.5rem;
    padding: 0.5rem;
    cursor: pointer;
    box-shadow: 0 0 0.75rem rgba(0, 0, 0, 0.15);
  }

  ${(p) =>
    (p.isStoreCampaign || p.isSmall) &&
    css`
      svg {
        height: 1.5rem;
        width: 1.5rem;
      }
    `}

  ${(p) =>
    p.isStoreCampaign &&
    p.left &&
    css`
      background: linear-gradient(
        90deg,
        #f4f4f2 42.13%,
        rgba(244, 244, 242, 0) 100%
      );
    `}

  ${(p) =>
    p.isStoreCampaign &&
    !p.left &&
    css`
      background: linear-gradient(
        270deg,
        #f4f4f2 42.13%,
        rgba(244, 244, 242, 0) 100%
      );
    `}

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    display: ${(p) => (p.hide ? "none" : "flex")};
    height: 4.25rem
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    height: 12.3125rem !important;
    width: 6.5rem;

    svg {
      height: 2.5rem !important;
      width: 2.5rem !important;
    }
  }
`
