import { gql } from "@apollo/client"

export const GENERATE_MERCHANT_CAMPAIGN_REPORT_MUTATION = gql`
  mutation ($input: GenerateMerchantCampaignReportMutationInput!) {
    generateMerchantCampaignReport(input: $input) {
      merchantCampaignReport {
        id
        report
        requestedUser {
          fullName
        }
      }
      errors {
        field
        messages
      }
    }
  }
`
