import React, { useEffect, useState } from "react"
import { FormattedMessage, useIntl } from "gatsby-plugin-intl"
import { Form, useFormikContext } from "formik"

import {
  Button,
  Checkbox,
  CountrySelectorFilter,
  FieldError,
  GroupedCategorySubcategorySelect,
  TextInput,
} from "@tmu/components/common"
import { useCountries } from "@tmu/hooks"
import { getValueForLocale } from "@tmu/utils/string"
import theme from "@tmu/global/theme"

import {
  StyledProfileFlexContainer,
  StyledProfileFlexRow,
  StyledProfileRowContent,
  StyledProfileSection,
  StyledProfileSectionTitle,
  StyledProfileSpacer,
} from "./index.styles"
import { Spacer } from "@tmu/src/global/page-addons/detail-page.styles"

const DetailsProfileForm = ({
  offer,
  isMerchantCorporate,
  isMerchantInternal,
  isMerchantOffline,
  isMerchantOnline,
  isEdit,
  onCancel,
  onSave,
}) => {
  const {
    errors,
    initialValues,
    values,
    handleChange,
    handleBlur,
    setValues,
    setFieldValue,
    setFieldTouched,
    setFieldError,
  } = useFormikContext()

  const [selectedCountries, setSelectedCountries] = useState([])
  const [selectedCities, setSelectedCities] = useState([])

  const { locale, defaultLocale, formatMessage } = useIntl()
  const { callCountry } = useCountries()

  const categoryItems = offer?.categories?.edges?.map(({ node }) => node)

  const selectRegionRequiredText = formatMessage({
    id: "dashboard::forms::regionRequired",
    defaultMessage: "Region is required",
  })

  const validateAvailableCountries = () => {
    const cities = values?.availableCities || []
    const countries = values?.availableCountries || []
    const all = [...cities, ...countries]

    if (!values?.isWorldwide && !all?.length && isEdit) {
      setFieldError("availableCountries", selectRegionRequiredText)
    }
  }

  useEffect(() => {
    if (!isMerchantCorporate) validateAvailableCountries()
  }, [values, errors])

  const handleCountrySelect = (countries, cities) => {
    if (countries && Array.isArray(countries)) {
      const tempCountries = countries
        ?.map((item) => item?.value)
        ?.filter((item) => item !== null)
      setFieldValue("availableCountries", tempCountries, true)
    }
    if (cities && Array.isArray(cities)) {
      const tempCities = cities
        ?.map((item) => item?.value)
        ?.filter((item) => item !== null)
      setFieldValue("availableCities", tempCities, true)
    }

    validateAvailableCountries()
  }

  useEffect(() => {
    if (initialValues?.availableCities) {
      const selectedCitiesOptions =
        initialValues?.availableCities?.map((item) => ({
          ...item,
          value: item?.id,
          label: `${item?.country?.name}, ${item?.region?.name}, ${item?.name}`,
        })) ?? []

      setSelectedCities(selectedCitiesOptions)
    }

    if (initialValues?.availableCountries) {
      let tempSelected = []
      const promiseArr = []
      initialValues?.availableCountries?.forEach((item) => {
        promiseArr.push(callCountry({ variables: { code: item } }))
      })
      Promise.all(promiseArr).then((results) => {
        results?.forEach((result) => {
          const item = result?.data?.country
          const countryResult = {
            ...item,
            value: item?.code,
            label: item?.name,
          }
          tempSelected = [...tempSelected, countryResult]
        })
        setSelectedCountries(tempSelected)
      })
    }
  }, [])

  const availableAreasMap = new Map()

  const areas = [...selectedCountries, ...selectedCities]
  areas.forEach((item) => {
    availableAreasMap.set(item?.id, item)
  })

  const availableAreas = Array.from(availableAreasMap?.values())

  return (
    <Form className="general-profile-form">
      <fieldset>
        <StyledProfileRowContent>
          <StyledProfileSection>
            <StyledProfileSectionTitle>
              <FormattedMessage
                id="dashboard::campaignForm::category"
                defaultMessage="Category"
              />
            </StyledProfileSectionTitle>
            <StyledProfileSection>
              {isEdit ? (
                <div data-testid="offer-input-categories">
                  <GroupedCategorySubcategorySelect
                    placeholder={formatMessage({
                      id: "categoryList::placeholder",
                      defaultMessage: "Choose category",
                    })}
                    onSelect={(selectedCategories) => {
                      setFieldTouched("categories")
                      setFieldValue("categories", selectedCategories, true)
                    }}
                    selectedCategoryItems={categoryItems}
                    hideLine={true}
                    onBlur={(e) => {
                      setFieldTouched("categories")
                      handleBlur(e)
                    }}
                    cities={values?.availableCities}
                    countries={values?.availableCountries}
                  />
                  <div data-testid="error-category">
                    <FieldError name="categories" autoFocus={true} />
                  </div>
                </div>
              ) : (
                (getValueForLocale(
                  offer?.categories?.edges?.[0]?.node,
                  "name",
                  locale,
                  defaultLocale
                ) ??
                  "") || <>&nbsp;</>
              )}
            </StyledProfileSection>
          </StyledProfileSection>

          {(isMerchantInternal || isMerchantOffline || isMerchantOnline) && (
            <>
              <StyledProfileSection>
                <StyledProfileSectionTitle>
                  <FormattedMessage
                    id="dashboard::forms::regions"
                    defaultMessage="Regions where you provide services"
                  />
                </StyledProfileSectionTitle>
                <StyledProfileSection>
                  {isEdit ? (
                    <>
                      <StyledProfileFlexRow
                        className={
                          errors?.availableCountries
                            ? "form-error-react-select"
                            : ""
                        }>
                        <CountrySelectorFilter
                          isOfferList={true}
                          handleSelect={handleCountrySelect}
                          isDropdown={false}
                          searchable={true}
                          inlineSearchableComponent={true}
                          activeCity={null}
                          activeCountry={null}
                          dontShowSelected={true}
                          withTagsBelow={!values?.isWorldwide}
                          cities={values?.availableCities}
                          countries={values?.availableCountries}
                          countriesFullData={selectedCountries}
                          disabled={values?.isWorldwide}
                          onChange={(e) => {
                            handleChange(e)
                            validateAvailableCountries()
                          }}
                          onBlur={(e) => {
                            onBlur(e)
                            validateAvailableCountries()
                          }}
                        />
                      </StyledProfileFlexRow>
                      {errors?.availableCountries && (
                        <>
                          <div className="input-feedback">
                            {errors?.availableCountries}
                          </div>
                          <Spacer bottom={0.5} />
                        </>
                      )}
                      <Spacer top={0.5} />
                      <div>
                        <Checkbox
                          label={formatMessage({
                            id: "dashboard::service::isWorldwide",
                            defaultMessage: "Worldwide",
                          })}
                          id="isWorldwide"
                          isChecked={values?.isWorldwide}
                          onChange={(e) => {
                            handleChange(e)
                            const temp = values?.isWorldwide
                            setValues({
                              ...values,
                              isWorldwide: !temp,
                            })
                          }}
                          onBlur={(e) => {
                            handleBlur(e)
                            validateAvailableCountries()
                          }}
                        />
                      </div>
                      <FieldError name="country" />
                    </>
                  ) : values?.isWorldwide ? (
                    formatMessage({
                      id: "dashboard::service::isWorldwide",
                      defaultMessage: "Worldwide",
                    })
                  ) : availableAreas?.length ? (
                    availableAreas?.map((item) => (
                      <StyledProfileSection>{item?.label}</StyledProfileSection>
                    ))
                  ) : (
                    <>&nbsp;</>
                  )}
                </StyledProfileSection>
              </StyledProfileSection>
            </>
          )}

          {isMerchantOnline && (
            <>
              <StyledProfileSection>
                <StyledProfileSectionTitle>
                  <FormattedMessage
                    id="dashboard::forms::maxPacDiscount"
                    defaultMessage="General PAC Discount"
                  />
                </StyledProfileSectionTitle>
                <StyledProfileSection>
                  {isEdit ? (
                    <>
                      <TextInput
                        newDesign
                        data-testid="maxPacDiscount"
                        id="maxPacDiscount"
                        name="maxPacDiscount"
                        type="number"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values?.maxPacDiscount}
                      />
                      <FieldError name="maxPacDiscount" />
                    </>
                  ) : Number(values?.maxPacDiscount) > -1 ? (
                    `${values?.maxPacDiscount}%`
                  ) : (
                    <Spacer bottom={1} />
                  )}
                </StyledProfileSection>
              </StyledProfileSection>
            </>
          )}
        </StyledProfileRowContent>
      </fieldset>
      {isEdit && (
        <>
          <StyledProfileSpacer bottom={1.5} />
          <StyledProfileFlexContainer>
            <Button
              className="black-button"
              color="transparent"
              label="cancel"
              onClick={onCancel}>
              <FormattedMessage
                id="dashboard::forms::cancel"
                defaultMessage="Cancel"
              />
            </Button>
            <Button
              data-testid="btn-save"
              className="blue-button button-margin"
              color={theme.colors.pacificBlue}
              type="button"
              disabled={Object.keys(errors)?.length > 0}
              onClick={onSave}>
              <FormattedMessage
                id="dashboard::accountForm::save"
                defaultMessage="Save"
              />
            </Button>
          </StyledProfileFlexContainer>
        </>
      )}
    </Form>
  )
}

export default DetailsProfileForm
