import React, { useState, useEffect } from "react"
import { useMutation, useQuery } from "@apollo/client"
import { useIntl, FormattedMessage } from "gatsby-plugin-intl"
import { isSafari } from "react-device-detect"
import {
  Button,
  DatePicker,
  Dropdown,
  VoucherChecker,
} from "@tmu/components/common"
import { Formik, Form } from "formik"
import { DonationTable } from "@tmu/components/dashboard/dashboardCommon"
import {
  StyledPage,
  StyledPageContent,
  StyledPageTitle,
  StyledDonationReportSection,
  StyledReportOptions,
} from "@tmu/global/page-addons/dashboard.styles"
import { useAuth, useToast, useDefaultPartner } from "@tmu/hooks"
import { useMediaQuery } from "beautiful-react-hooks"
import { GENERATE_PARTNER_REPORT_MUTATION } from "@tmu/apollo/dashboard/mutations/partner"
import { FEATURED_CAMPAIGN_QUERY } from "@tmu/apollo/storefront/queries/campaign"
import { PARTNER_CAMPAIGN_LISTING_REPORT_QUERY } from "@tmu/apollo/dashboard/queries/campaign"
import { useApolloApiClients } from "@tmu/apollo/client"
import { MAX_PER_PAGE } from "@tmu/apollo/constants"
import * as Yup from "yup"
import { formatDate } from "@tmu/utils/date"
import { startDate, endDate } from "@tmu/utils/validation"
import { getValueForLocale } from "@tmu/utils/string"
import { isBrowser } from "@tmu/utils/auth"
import { getAllScreenTypes } from "@tmu/src/utils/mediaQueries"
import { StyledTopBarWrapper } from "./index.styles"
import { Spacer } from "@tmu/src/global/page-addons/detail-page.styles"
import { API_PERMISSIONS } from "@tmu/apollo/constants"

export default function Donations({ dashboardType = "donors", location }) {
  const { isAuthenticated, apiPermissions } = useAuth()
  const { defaultPartner } = useDefaultPartner()
  const { success, error } = useToast()
  const { storefrontClient, partnerClient } = useApolloApiClients()
  const [campaignListReportData, setCampaignListReportData] = useState([])
  const { formatMessage, locale, defaultLocale } = useIntl()
  const [selectedDate, setSelectedDate] = useState(null)
  const [filters, setFilters] = useState({})
  const { isWide } = getAllScreenTypes()

  const [generatePartnerReport, { loading: loadingPartnerReport }] =
    useMutation(GENERATE_PARTNER_REPORT_MUTATION, {
      client: partnerClient,
    })

  const [first, setFirst] = useState(3)
  const isSmall = isBrowser && useMediaQuery("(max-width: 36rem)")
  const isMedium = isBrowser && useMediaQuery("(max-width: 90.8125rem)")

  const {
    loading: loadingCampaignData,
    data: campaignData,
    called: campaignCalled,
  } = useQuery(FEATURED_CAMPAIGN_QUERY, {
    variables: {
      first,
    },
    client: storefrontClient,
    skip: campaignCalled || !isAuthenticated,
  })

  const {
    loading: loadingCampaignListData,
    data: campaignListData,
    called: partnerCalled,
  } = useQuery(PARTNER_CAMPAIGN_LISTING_REPORT_QUERY, {
    client: partnerClient,
    skip: !isAuthenticated || dashboardType !== "partners" || partnerCalled,
    variables: { status_In: ["APPROVED", "ENDED"], first: MAX_PER_PAGE },
  })

  useEffect(() => {
    if (dashboardType === "partners") {
      !loadingCampaignListData &&
        setCampaignListReportData(
          campaignListData?.allCampaigns?.edges?.map(({ node }) => ({
            id: node.id,
            name: getValueForLocale(node, "name", locale, defaultLocale),
          })) ?? []
        )
    }
  }, [loadingCampaignListData, dashboardType, campaignListData])

  const initialValues = {
    startDate: null,
    endDate: null,
    campaign: "",
  }

  const validationSchema = Yup.object().shape({
    startDate: startDate({ formatMessage }),
    endDate: endDate({ formatMessage }),
  })

  useEffect(() => {
    if (isSmall) {
      setFirst(1)
    } else if (isMedium) {
      setFirst(2)
    } else {
      setFirst(3)
    }
  }, [isSmall, isMedium])

  const handleSubmit = (values) => {
    if (values.campaign) {
      values.campaign = values?.campaign?.value || values?.campaign || ""
    }
    generatePartnerReport({
      variables: {
        input: {
          ...values,
        },
      },
      client: partnerClient,
    })
      .then((res) => {
        const generatePartnerReport = res?.data?.generatePartnerReport

        if (
          generatePartnerReport?.partnerReport?.report &&
          !loadingPartnerReport
        ) {
          if (isBrowser) {
            window.open(
              res?.data?.generatePartnerReport?.partnerReport?.report,
              "_blank"
            )
          }

          success(
            formatMessage({
              id: "dashboard::donations::reportSuccess",
              defaultMessage:
                "Your request is sent successfully! Also a copy of the report send to your email address.",
            })
          )
        }

        if (generatePartnerReport?.errors.length > 0) {
          error(
            formatMessage({
              id: "dashboard::donations::reportError",
              defaultMessage: "An error occurred while processing your request",
            })
          )
        }
      })
      .catch((err) => {
        err &&
          error(
            formatMessage({
              id: "dashboard::donations::reportError",
              defaultMessage: "An error occurred while processing your request",
            })
          )
      })
  }

  const isDataLoadComplete = dashboardType && !loadingCampaignListData
  return (
    <StyledPage>
      <StyledTopBarWrapper>
        <StyledPageTitle className="page-title">
          <FormattedMessage
            id="dashboard::nav::donations"
            defaultMessage="Donations"
            tagName="h1"
          />
        </StyledPageTitle>
        {dashboardType === "partners" && (
          <VoucherChecker
            isCampaign={true}
            apiType={{ partner: true }}
            dashboardType={dashboardType}
          />
        )}
      </StyledTopBarWrapper>
      <Spacer top={1} />
      {dashboardType === "partners" &&
        defaultPartner &&
        defaultPartner?.status !== "IN_PROGRESS" &&
        apiPermissions?.includes(API_PERMISSIONS.PARTNER_VIEW_REPORTS) && (
          <>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}>
              {({ values, setFieldValue }) => {
                return (
                  <Form onSubmit={handleSubmit}>
                    <StyledDonationReportSection data-testid="donation-report-section">
                      <FormattedMessage
                        id="dashboard::donations::reporting"
                        defaultMessage="Reporting"
                        tagName="h3"
                      />
                      <FormattedMessage
                        id="dashboard::donations::report"
                        defaultMessage="If you'd like to generate a report please click generate report button.You can get a report without choosing the time interval."
                        tagName="p"
                      />

                      <FormattedMessage
                        id="dashboard::donations::reportTimeWarning"
                        defaultMessage="All date related fields are in UTC timezone."
                        tagName="p"
                      />
                      <StyledReportOptions>
                        <div
                          data-testid="donation-report-campaigns"
                          className="campaigns-select"
                          style={{
                            marginLeft: isSafari && "2.1875rem",
                          }}>
                          <Dropdown
                            isClearable={true}
                            defaultSelectStyles={{
                              option: {
                                color: "black",
                                background: "white",
                                wordBreak: "break-word !important",
                              },
                              control: {
                                height: isWide ? "3.375rem" : "2.5rem",
                                paddingTop: 0,
                                fontSize: isWide ? "1.5rem" : "1rem",
                              },
                            }}
                            placeholder={formatMessage({
                              id: "dashboard::donation::allCampaignsDropdown",
                              defaultMessage: "All Campaigns",
                            })}
                            options={campaignListReportData?.map(
                              ({ id, name }) => ({
                                value: id,
                                label: name,
                              })
                            )}
                            defaultValue=""
                            onChange={(campaign) => {
                              const campaignId = campaign?.value ?? campaign

                              setFieldValue("campaign", campaignId, true)
                              setFilters({
                                ...filters,
                                campaign: campaignId,
                              })
                            }}
                          />
                        </div>
                        <DatePicker
                          showRange
                          minDate={new Date("2020-01-01")}
                          dataTestId="donation-report-date-picker"
                          startDate={selectedDate?.[0]}
                          endDate={selectedDate?.[1]}
                          onDateChange={(date) => {
                            let [startDate, endDate] = date
                            if (
                              startDate &&
                              endDate &&
                              startDate.getTime() === endDate.getTime()
                            ) {
                              endDate = new Date(endDate)
                              endDate.setHours(23, 59, 0, 0)
                            }

                            setSelectedDate([startDate, endDate])
                            const formattedStartDate = formatDate(
                              startDate,
                              "yyyy-MM-dd"
                            )
                            const formattedEndDate = formatDate(
                              endDate,
                              "yyyy-MM-dd"
                            )
                            setFieldValue("startDate", formattedStartDate, true)
                            setFieldValue("endDate", formattedEndDate, true)

                            setFilters({
                              ...filters,
                              created_Gte: startDate,
                              created_Lte: endDate,
                            })
                          }}
                          placeholderText={formatMessage({
                            id: "dashboard::campaignForm::startEndDate",
                            defaultMessage: "Start - End Date",
                          })}
                        />

                        <div
                          className="generate-report"
                          data-testid="generate-report">
                          <Button
                            color="red"
                            label="Generate Report"
                            onClick={() => handleSubmit(values)}
                            style={{
                              marginLeft: isSafari ? "2.1875rem" : "1rem auto",
                            }}>
                            <FormattedMessage
                              id="dashboard::donation::generateReport"
                              defaultMessage="Generate Report"
                            />
                          </Button>
                        </div>
                      </StyledReportOptions>
                    </StyledDonationReportSection>
                  </Form>
                )
              }}
            </Formik>

            <hr />
          </>
        )}

      <StyledPageContent data-testid="donations-page-content">
        {dashboardType === "partners" && (
          <FormattedMessage
            id="dashboard::donations::lastDonations"
            defaultMessage="Last Donations"
            tagName="h3"
          />
        )}
        <DonationTable dashboardType={dashboardType} filters={filters} />
        <br />
      </StyledPageContent>
    </StyledPage>
  )
}
