import React, { useContext, useState, useEffect } from "react"
import { Formik, Field, useFormikContext } from "formik"
import { FormattedMessage, useIntl } from "gatsby-plugin-intl"
import * as Yup from "yup"
import { faCheck } from "@fortawesome/free-solid-svg-icons/faCheck"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useLazyQuery } from "@apollo/client"
import { Button, TextInput, FieldError, Dropdown } from "@tmu/components/common"
import { email, role } from "@tmu/utils/validation"
import { StyledModalActions } from "@tmu/global/page-addons/dashboard.styles"
import { UserManagementContext } from "@tmu/components/dashboard/dashboardPages/Users"
import { SEARCH_PARTNER_USER_QUERY } from "@tmu/apollo/dashboard/queries/partner"
import { SEARCH_MERCHANT_USER_QUERY } from "@tmu/apollo/dashboard/queries/merchant"
import { getAllScreenTypes } from "@tmu/utils/mediaQueries"
import { StyledTitle } from "./index.styles"
import { StyledForm } from "../index.styles"
import {
  StyledRoleList,
  StyledRoleItem,
  StyledRoleNameContainer,
  StyledCheckIcon,
} from "../UserTable/index.styles"
import { StyledCategoryHr } from "@tmu/components/dashboard/dashboardPages/Settings/index.styles"

const InviteUserForm = ({ users, onFoundUser }) => {
  const { formatMessage } = useIntl()
  const [userFoundError, setUserFoundError] = useState(null)
  const [selectedCodeName, setSelectedCodeName] = useState("")
  const { isTablet } = getAllScreenTypes()
  const {
    values,
    errors,
    handleChange,
    handleBlur,
    isSubmitting,
    setFieldValue,
    setValues,
    setTouched,
    touched,
  } = useFormikContext()

  const { client, dashboardType, roles, referrals } = useContext(
    UserManagementContext
  )

  let searchTimeout = null

  const SEARCH_QUERY =
    dashboardType === "partners"
      ? SEARCH_PARTNER_USER_QUERY
      : SEARCH_MERCHANT_USER_QUERY

  const userExistsMessage = formatMessage({
    id: "dashboard::users::emailExists",
    defaultMessage: "This email address already belongs to a member.",
  })

  const referralExistsMessage = formatMessage({
    id: "dashboard::users::referralExists",
    defaultMessage: "This email address is already invited.",
  })

  const onSearchQuery = (data) => {
    if (data) {
      const { user } = data

      const existingReferral = referrals.find(
        ({ email }) => email === values.email
      )

      if (existingReferral) {
        setUserFoundError(referralExistsMessage)
        return
      } else {
        setUserFoundError(null)
      }

      if (!user) {
        return
      }

      const { email: existingEmail, id: existingUserId } = user
      const alreadyMemberUser = users.find(
        ({ email }) => email === existingEmail
      )

      if (!alreadyMemberUser) {
        onFoundUser(existingUserId)
        setUserFoundError(null)
        return
      } else {
        setUserFoundError(userExistsMessage)
        return
      }
    }
  }

  const [callSearchQuery, { data, refetch, called }] = useLazyQuery(
    SEARCH_QUERY,
    {
      client,
      variables: {
        email: values.email,
      },
    }
  )

  useEffect(() => {
    onSearchQuery(data)
  }, [data])

  useEffect(() => {
    if (
      !values.email ||
      errors?.email ||
      (errors?.email && errors.email?.length === 0)
    ) {
      return
    }

    clearTimeout(searchTimeout)

    searchTimeout = setTimeout(() => {
      if (!called) {
        callSearchQuery()
      } else {
        refetch()
      }
    }, 3000)
  }, [values?.email, errors?.email])

  const getRoleOptionLabel = (option) =>
    formatMessage({
      id: `apiRoles::role::${option.codename}`,
      defaultMessage: option.name,
    })

  return (
    <StyledForm noValidate>
      <StyledTitle>
        <FormattedMessage
          id="dashboard::users::modal::email"
          defaultMessage="Email"
          tagName="p"
        />
        <Field
          data-testid="input-email"
          id="email"
          name="email"
          type="email"
          component={TextInput}
          className="float-container user-name"
          value={values?.email ?? ""}
          onChange={(e) => {
            const lowerCaseEmail = e.target.value.toLowerCase()

            setValues({
              ...values,
              email: lowerCaseEmail,
            })
          }}
          onKeyUp={() =>
            setTouched({ ...touched, email: true, apiRole: true }, true)
          }
          onBlur={handleBlur}
        />
        <FieldError name="email" />
        {!errors?.email && userFoundError && (
          <div className="input-feedback" data-testid="input-feedback">
            {userFoundError}
          </div>
        )}
      </StyledTitle>
      <StyledTitle>
        <FormattedMessage
          id="dashboard::users::modal::chooseRole"
          defaultMessage="Choose Role"
          tagName="p"
        />
        {isTablet ? (
          <>
            <Dropdown
              name="apiRole"
              value={roles.find((r) => r.id === values.apiRole.id)}
              defaultSelectStyles={{
                control: {
                  textAlign: "left",
                  borderWidth: "0.0625rem",
                  borderRadius: "0.25rem",
                  borderColor: "rgba(13,8,9,0.15)",
                  marginBottom: "1rem",
                },
                singleValue: {
                  padding: 2,
                },
                option: {
                  padding: "0.25rem 1rem",
                },
              }}
              id="api-role-select"
              classNamePrefix="react-select"
              defaultValue=""
              autoFocus={true}
              isSearchable={false}
              options={roles}
              onChange={(apiRole) =>
                setFieldValue("apiRole", apiRole?.id, true)
              }
              getOptionLabel={(option) =>
                formatMessage({
                  id: `apiRoles::role::${option.codename}`,
                  defaultMessage: option.name,
                })
              }
              getOptionValue={(option) => option.id}
            />
            <FieldError name="apiRole" />
          </>
        ) : (
          <StyledRoleList>
            {roles.map((role, index) => {
              return (
                <>
                  <StyledRoleItem
                    key={index}
                    onClick={() => {
                      const newApiRole =
                        role?.codename !== selectedCodeName ? role?.id : ""
                      const newSelectedCodeName =
                        role?.codename !== selectedCodeName
                          ? role?.codename
                          : ""
                      setValues({
                        ...values,
                        apiRole: newApiRole,
                      })
                      setSelectedCodeName(newSelectedCodeName)
                    }}>
                    <StyledRoleNameContainer>
                      {getRoleOptionLabel(role)}
                    </StyledRoleNameContainer>
                    {selectedCodeName === role?.codename ? (
                      <StyledCheckIcon>
                        <FontAwesomeIcon icon={faCheck} />
                      </StyledCheckIcon>
                    ) : null}
                  </StyledRoleItem>
                  <StyledCategoryHr />
                </>
              )
            })}
          </StyledRoleList>
        )}
      </StyledTitle>
      <StyledModalActions>
        <Button
          data-testid="btn-authorize"
          type="submit"
          label="Authorize"
          className={isTablet ? "not-mobile-btn-user" : "mobile-btn-user"}
          disabled={
            isSubmitting || errors.email || errors.apiRole || userFoundError
          }
          color="blue">
          <FormattedMessage
            id="dashboard::users::addUser"
            defaultMessage="Add User"
          />
        </Button>
      </StyledModalActions>
    </StyledForm>
  )
}

const InviteUserFormPage = ({
  users,
  onReferEmail,
  onInviteUser,
  onCancel,
}) => {
  const initialValues = {
    email: "",
    apiRole: "",
    user: "",
  }
  const { formatMessage } = useIntl()
  const validationSchema = Yup.object().shape({
    email: email({ formatMessage }),
    apiRole: role({
      formatMessage,
    }),
  })
  const [foundUser, setFoundUser] = useState(null)

  const onFoundUser = (user) => {
    setFoundUser(user)
  }

  const handleSubmit = async (values, form) => {
    if (values.user.length > 0) {
      onInviteUser({ user: values.user, apiRole: values.apiRole }, form)
    } else {
      onReferEmail({ email: values.email, apiRole: values.apiRole }, form)
    }
  }

  return (
    <Formik
      enableReinitialize={true}
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={handleSubmit}>
      {() => (
        <InviteUserForm
          onCancel={onCancel}
          onFoundUser={onFoundUser}
          users={users}
          foundUser={foundUser}
        />
      )}
    </Formik>
  )
}

export default InviteUserFormPage
