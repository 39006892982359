import React, { useState, useEffect, useContext, useRef } from "react"
import { FormattedMessage, navigate, useIntl } from "gatsby-plugin-intl"
import { faSearch } from "@fortawesome/pro-solid-svg-icons/faSearch"
import { faBars } from "@fortawesome/pro-solid-svg-icons/faBars"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useWindowScroll, useThrottledFn } from "beautiful-react-hooks"
import {
  useAuth,
  useOnClickOutside,
  //useNotifications,
  useIntercom,
  useFooterType,
} from "@tmu/hooks"
import { useLocation } from "@reach/router"
import { Button, Typeahead, CustomModal, Spinner } from "@tmu/components/common"
import queryString from "query-string"
import {
  StyledHeaderWrapper,
  StyledLogoSearch,
  DesktopMenuContainer,
  StyledNavbarContent,
  StyledDigitalBankingDropdown,
  StyledGlobalSpinner,
} from "./index.styles"
import {
  NavbarLogo,
  LeftMenu,
  HeaderPacBalance,
  HeaderSigninSignUp,
  HeaderLanguage,
  MobileMenu,
} from "@tmu/components/common/Header/Nav"
import { HomePageContext } from "@tmu/context/homePageContext"
import { FOOTER_TYPE } from "@tmu/apollo/constants"
import { isBrowser, isTokenExpired } from "@tmu/utils/auth"
import { get, set } from "@tmu/utils/storage"
import { getAllScreenTypes } from "@tmu/utils/mediaQueries"
import { useDefaultMerchant, useReferral } from "@tmu/hooks"
import { isAfter } from "date-fns"

const Header = () => {
  const SECONDARY_INTERCOM_APP_ID = "v1felkiu"
  const INTERCOM_APP_ID = "dt7elb43"
  const { locale, formatMessage } = useIntl()
  const { pathname } = useLocation()
  const [modalStatus, setModalStatus] = useState(false)
  const [isUnlistedSearch, setIsUnlistedSearch] = useState(false)
  const [shouldHideHeader, setShouldHideHeader] = useState(false)
  const [shouldShowShadow, setShouldShowShadow] = useState(false)
  const [isLeftMenuVisible, setIsLeftMenuVisible] = useState(false)
  const [scrollY, setScrollY] = useState(isBrowser ? window.scrollY : 0)
  const [isDashboard, setIsDashboard] = useState(false)
  const {
    headerConfigData,
    setHeaderConfigData,
    intercomStatus,
    setIntercomStatus,
    menuConfig,
    setMenuConfig,
    setDefaultOfferType,
    globalSpinnerStatus,
  } = useContext(HomePageContext)
  const { callReferral } = useReferral()
  const { defaultOffer, defaultMerchant, isMerchantInternal } =
    useDefaultMerchant()

  let toggleTimeout = null

  const tokenExpired = isTokenExpired(get("token"))
  const { isTablet, isDesktop, isWide } = getAllScreenTypes()

  useEffect(() => {
    const isDashboardTemp = pathname.includes("dashboard")
    setIsDashboard(isDashboardTemp)
    if (isDashboardTemp && isTablet && menuConfig?.hideOnDashboard !== true) {
      setIsLeftMenuVisible(true)
    } else {
      setIsLeftMenuVisible(false)
    }
  }, [pathname, menuConfig?.hideOnDashboard])

  const {
    user,
    isAuthenticated,
    signOut,
    calledPartnerApiPermissions,
    callPartnerApiPermissions,
    calledMerchantApiPermissions,
    callMerchantApiPermissions,
  } = useAuth()

  useFooterType({ footerType: FOOTER_TYPE.TIGHT })
  const digitalBankingType = pathname.includes(`/personal`)
    ? "personal"
    : pathname.includes(`/business`)
    ? "business"
    : null

  const merchantUsers = user?.merchantUsers?.edges

  useEffect(() => {
    if (isTablet) {
      return
    }
    if (user?.isPartner && !calledPartnerApiPermissions) {
      callPartnerApiPermissions()
    }

    const activeMerchantTemp =
      merchantUsers?.length === 1
        ? merchantUsers[0]
        : merchantUsers?.find(
            (merchant) =>
              merchant?.node?.store?.id === user?.activeStore?.id ||
              merchant?.node?.store?.id === defaultMerchant?.id
          )

    if (
      user?.isMerchant &&
      activeMerchantTemp?.node?.id &&
      !calledMerchantApiPermissions
    ) {
      callMerchantApiPermissions({
        variables: { id: activeMerchantTemp?.node?.id },
      })
    }
  }, [user])

  const closeModal = () => {
    setModalStatus(false)
    resetOverflowY()
  }

  const openModal = () => {
    setModalStatus(true)
  }

  const closeLeftMenu = () => {
    setIsLeftMenuVisible(false)
  }

  const toggleLeftMenu = () => {
    clearTimeout(toggleTimeout)
    toggleTimeout = setTimeout(() => {
      if (!isTablet) {
        setIsLeftMenuVisible(false)
        openModal()
        return
      }
      if (isDashboard) {
        setIsLeftMenuVisible(true)
        return
      }
      setIsLeftMenuVisible(!isLeftMenuVisible)
    }, 100)
  }

  const typeHeadRef = useRef()
  useOnClickOutside(typeHeadRef, () => {
    setIsUnlistedSearch(false)
    closeLeftMenu()
  })

  const { callIntercom, closeIntercom } = useIntercom()
  const [_, setSelectedDigital] = useState({
    label: (
      <FormattedMessage
        id="nav::digital::banking"
        defaultMessage="Digital Banking"
      />
    ),
  })

  const usePrevious = (value) => {
    const ref = useRef()
    useEffect(() => {
      ref.current = value
    })

    return ref.current
  }

  let location = useLocation()
  const prevLocation = usePrevious(location)

  const resetOverflowY = () => {
    if (isBrowser) {
      window.document.body.style["overflow-y"] = "unset"
    }
  }

  resetOverflowY()

  const checkReferral = () => {
    const params = queryString.parse(location?.search, { arrayFormat: "comma" })

    const code = params?.referral

    if (code) {
      callReferral({ variables: { code } }).then((result) => {
        const referralData = result?.data?.referral
        const validDate = new Date(referralData?.validUntil)
        if (isAfter(validDate, Date.now())) {
          set("referral", referralData, validDate)
        }
      })
    }
  }

  useEffect(() => {
    setDefaultOfferType(defaultOffer?.offerType)
  }, [defaultOffer])

  useEffect(() => {
    try {
      checkReferral()
    } catch (e) {
      console.error(e)
    }
    try {
      if (pathname !== prevLocation?.pathname) {
        setHeaderConfigData({ ...headerConfigData, whiteText: false })
        setMenuConfig({
          ...menuConfig,
          hideOnDashboard: false,
          forceOverlay: false,
        })
      }
      const digitalPersonalLink = "/" + locale + "/digital-banking/personal"
      const digitalBusinessLink = "/" + locale + "/digital-banking/business"
      let appId = INTERCOM_APP_ID
      if (
        prevLocation?.pathname !== digitalPersonalLink &&
        prevLocation?.pathname !== digitalBusinessLink &&
        (location?.pathname === digitalPersonalLink ||
          location?.pathname === digitalBusinessLink)
      ) {
        appId = SECONDARY_INTERCOM_APP_ID
      }

      if (appId) {
        callIntercom({ user, appId })
      }

      if (!isTablet && intercomStatus?.hide) {
        closeIntercom()
      }
    } catch (e) {
      console.error(e)
    }
  }, [user, location, intercomStatus, isTablet])

  // TODO: temporarily removed notification. Will be implemented when necessary
  // const { firstNotification, markAsRead } = useNotifications()

  /* useEffect(() => {
    if (!firstNotification) {
      return
    }
  
    success(firstNotification?.content, () => {}, false)
    markAsRead(firstNotification?.id)
  }, [firstNotification]) */

  const scrollHandler = useThrottledFn(() => {
    if (!isBrowser) {
      return
    }

    let isScrolledDown = false
    const currentScrollTop = window.scrollY
    if (currentScrollTop > 0) {
      isScrolledDown = scrollY < currentScrollTop
    }

    setShouldShowShadow(currentScrollTop > 5)
    const hideLimit = isWide ? 370 : 100
    setShouldHideHeader(
      isScrolledDown &&
        currentScrollTop > hideLimit &&
        !isLeftMenuVisible &&
        !isDashboard &&
        !isUnlistedSearch
    )
    setScrollY(window.scrollY)
  }, 250)

  useWindowScroll(scrollHandler)

  useEffect(() => {
    if (tokenExpired) signOut()
  }, [])

  useEffect(() => {
    return scrollHandler.flush()
  }, [])

  const transparencyStyle =
    shouldShowShadow ||
    isLeftMenuVisible ||
    isUnlistedSearch ||
    (isDashboard && isTablet)
      ? ""
      : headerConfigData?.transparent === false
      ? ""
      : "transparent"

  const hiddenStyle = shouldHideHeader ? "hidden" : ""
  const style = {
    padding: isWide ? "3rem" : isTablet ? "2rem" : "1.5rem",
  }

  const whiteStyle =
    !isLeftMenuVisible &&
    !isDashboard &&
    !shouldShowShadow &&
    !isUnlistedSearch &&
    headerConfigData?.whiteText
      ? "white-text"
      : ""

  const handleSearch = () => {
    setIsUnlistedSearch(true)
  }

  const handleTrackAndNavigate = (path) => {
    navigate(path)
  }

  const handleLogoClick = () => {}

  const handleUserSpaceClick = () => {
    handleTrackAndNavigate("/user-space")
  }

  const handleExplore = () => {
    handleTrackAndNavigate("/campaigns?campaign=true&charity=true&event=true")
  }

  const handleFundraise = () => {
    handleTrackAndNavigate("/fundraise")
  }

  const handlePacStore = () => {
    handleTrackAndNavigate("/offers?offer=true&store=true")
  }

  const hideSearch = () => setIsUnlistedSearch(false)

  const handleSignup = () => {
    navigate("/signup")
    closeModal()
  }

  useEffect(() => {
    setSelectedDigital({
      id: digitalBankingType || "title",
      label: (
        <StyledDigitalBankingDropdown hasChild={!!digitalBankingType}>
          <span className="digital-banking-title">
            <FormattedMessage
              id="nav::digital::banking"
              defaultMessage="Digital Banking"
            />
          </span>
          {digitalBankingType ? (
            <span className={`digital-banking-sub-title ${digitalBankingType}`}>
              <FormattedMessage
                id={`nav::digitalBanking::${digitalBankingType}`}
                defaultMessage={digitalBankingType}
              />
            </span>
          ) : null}
        </StyledDigitalBankingDropdown>
      ),
    })
  }, [digitalBankingType])

  const handleProfile = () => {
    navigate("/dashboard/profile")
  }

  const mySpaceText = formatMessage({
    id: "dashboard::nav::mySpace",
    defaultMessage: "My Space",
  })

  const supporterCampaignsText = formatMessage({
    id: "dashboard::nav::campaignsLink",
    defaultMessage: "Campaigns",
  })

  const campaignsText = formatMessage({
    id: "dashboard::nav::campaigns",
    defaultMessage: "Donate",
  })

  const fundraiseText = formatMessage({
    id: "fundraise::common::pageTitle",
    defaultMessage: "Fundraise",
  })

  const offersText = formatMessage({
    id: "nav::PACStore",
    defaultMessage: "Shop",
  })

  const myAccountText = formatMessage({
    id: "nav::profile",
    defaultMessage: "Profile",
  })

  const pacWalletText = formatMessage({
    id: "nav::pacWallet",
    defaultMessage: "PAC Wallet",
  })

  const donationSearchText = formatMessage({
    id: "dashboard::nav::donationSearch",
    defaultMessage: "Donation Search",
  })

  const ordersText = formatMessage({
    id: "nav::purchases",
    defaultMessage: "Purchases",
  })

  const myDonationsText = formatMessage({
    id: "nav::donations",
    defaultMessage: "Donations",
  })

  const mysettingsText = formatMessage({
    id: "nav::settings",
    defaultMessage: "Settings",
  })
  const downloadsText = formatMessage({
    id: "dashboard::nav::download",
    defaultMessage: "Download",
  })
  const referralText = formatMessage({
    id: "dashboard::nav::referralCodes",
    defaultMessage: "Referral Codes",
  })

  const mysalesText = formatMessage({
    id: "dashboard::nav::sales",
    defaultMessage: "Sales",
  })

  const languageText = formatMessage({
    id: "nav::language",
    defaultMessage: "Language",
  })

  const signOutText = formatMessage({
    id: "nav::signout",
    defaultMessage: "Sign out",
  })

  const menuItems = [
    {
      label: mySpaceText,
      handler: handleUserSpaceClick,
      public: false,
      mobile: true,
      desktop: true,
    },
    {
      label: myAccountText,
      handler: handleProfile,
      public: false,
      mobile: true,
      desktop: true,
    },
    {
      label: supporterCampaignsText,
      handler: null,
      public: false,
      link: "/dashboard/user/campaigns",
      mobile: true,
      desktop: true,
    },
    {
      label: myDonationsText,
      handler: null,
      public: false,
      link: "/dashboard/donors/donations",
      mobile: true,
      desktop: true,
    },
    !isAuthenticated && {
      label: campaignsText,
      handler: handleExplore,
      public: true,
      mobile: true,
      desktop: false,
    },
    !isAuthenticated && {
      label: offersText,
      handler: handlePacStore,
      public: true,
      mobile: true,
      desktop: false,
    },
    !isAuthenticated && {
      label: fundraiseText,
      handler: handleFundraise,
      public: true,
      mobile: true,
      desktop: false,
    },
    {
      label: pacWalletText,
      handler: null,
      public: false,
      link: "/dashboard/donors/wallet",
      mobile: true,
      desktop: true,
    },
    {
      label: donationSearchText,
      handler: null,
      public: false,
      link: "/dashboard/donors/donation-search",
      mobile: false,
      desktop: true,
    },
    {
      label: ordersText,
      handler: null,
      public: false,
      link: "/dashboard/donors/orders",
      mobile: true,
      desktop: true,
    },
    {
      label: downloadsText,
      handler: null,
      public: false,
      link: "/dashboard/donors/download-center",
      mobile: true,
      desktop: true,
    },
    {
      label: referralText,
      handler: null,
      public: false,
      link: "/dashboard/donors/referral-codes",
      mobile: true,
      desktop: true,
    },
    {
      label: mysettingsText,
      handler: null,
      public: false,
      link: "/dashboard/settings",
      mobile: true,
      desktop: true,
    },
    {
      label: languageText,
      rawText: "language",
      handler: null,
      public: false,
      mobile: true,
      desktop: true,
    },
    {
      label: signOutText,
      handler: signOut,
      public: false,
      mobile: true,
      desktop: true,
    },
  ]
  if (isMerchantInternal) {
    menuItems.splice(-3, 0, {
      label: mysalesText,
      handler: null,
      public: false,
      link: "/dashboard/merchants/sales/",
      mobile: true,
      desktop: false,
    })
  }
  useEffect(() => {
    setIntercomStatus({ hide: modalStatus && !isTablet })
  }, [modalStatus && !isTablet])

  const mobileVersion = (
    <MobileMenu
      menuItems={menuItems}
      closeModal={closeModal}
      handleSignup={handleSignup}
    />
  )
  return (
    <StyledHeaderWrapper>
      <nav
        className={`navbar ${transparencyStyle} ${hiddenStyle} `}
        data-testid="header-navbar">
        <StyledNavbarContent>
          <StyledLogoSearch>
            <li>
              <Button
                label="menu"
                variant="icon"
                color="black"
                onClick={() => {
                  if (!isLeftMenuVisible || !isTablet) {
                    toggleLeftMenu()
                  }
                }}
                className={`navbar__bars ignore-react-onclickoutside ${transparencyStyle} ${hiddenStyle} ${whiteStyle}`}
                data-testid="navbar-button-mobile">
                <FontAwesomeIcon icon={faBars} />
              </Button>
            </li>
            <li
              className={`navbar-logo-container ${whiteStyle}`}
              onClick={handleLogoClick}>
              <NavbarLogo
                flat={!shouldShowShadow && whiteStyle?.length > 0}
                width={isWide ? 165 : isDesktop || !isAuthenticated ? 130 : 100}
                height={isWide ? 40 : isDesktop || !isAuthenticated ? 25 : 22}
                color={whiteStyle?.length > 0 ? "white" : false}
              />
            </li>

            <li
              className={`navbar__search ${whiteStyle}`}
              data-testid="navbar_search">
              <Button
                label="search"
                data-testid="navbar_search_btn"
                variant="icon"
                onClick={handleSearch}>
                <FontAwesomeIcon icon={faSearch} />
              </Button>
            </li>
          </StyledLogoSearch>
          {isTablet && (
            <DesktopMenuContainer className={!isDesktop && "desktop"}>
              <>
                {isDesktop && (
                  <ul className="header-items">
                    {isAuthenticated && (
                      <li className={`navbar__explore ${whiteStyle}`}>
                        <Button
                          label="user-space"
                          variant="text"
                          onClick={handleUserSpaceClick}
                          data-testid="btn-user-space-navbar">
                          <a href="/user-space">{mySpaceText}</a>
                        </Button>
                      </li>
                    )}
                    <li className={`navbar__explore ${whiteStyle}`}>
                      <Button
                        label="campaigns"
                        variant="text"
                        onClick={handleExplore}
                        data-testid="btn-campaigns-navbar">
                        <a href="/campaigns?campaign=true&charity=true&event=true">
                          {campaignsText}
                        </a>
                      </Button>
                    </li>
                    <li className={`navbar__explore ${whiteStyle}`}>
                      <Button
                        label="campaigns"
                        variant="text"
                        onClick={handleFundraise}
                        data-testid="btn-campaigns-navbar">
                        <a href="/fundraise">{fundraiseText}</a>
                      </Button>
                    </li>
                    <li
                      className={`navbar__store ${whiteStyle}`}
                      data-testid="link-offers">
                      <Button
                        label="Pac Store"
                        variant="text"
                        onClick={handlePacStore}>
                        <a href="/offers?offer=true&store=true">{offersText}</a>
                      </Button>
                    </li>
                  </ul>
                )}
                <ul>
                  <li
                    className={`header-change-language ${whiteStyle}`}
                    data-testid="header-change-language">
                    <HeaderLanguage whiteStyle={whiteStyle} />
                  </li>
                  {isAuthenticated ? (
                    <HeaderPacBalance
                      transparencyStyle={transparencyStyle}
                      customClass={whiteStyle}
                    />
                  ) : (
                    <HeaderSigninSignUp customClass={whiteStyle} />
                  )}
                </ul>
              </>
            </DesktopMenuContainer>
          )}
          {isUnlistedSearch && (
            <div
              isAuthenticated={isAuthenticated}
              className={`navbar-typeahead ${
                !isDesktop && isAuthenticated && "navbar-typeahead-not-logged"
              }`}>
              <Typeahead
                ref={typeHeadRef}
                isUnlisted={isUnlistedSearch}
                onHideClick={hideSearch}
              />
            </div>
          )}
        </StyledNavbarContent>
      </nav>
      {modalStatus && !isTablet && (
        <CustomModal
          isModalOpen={modalStatus}
          style={style}
          children={mobileVersion}
          closeIconStyle={{ fontSize: "1.75rem !important", height: "1.75rem" }}
          isCloseIcon={true}
          isCloseXButton={true}
          cancelButtonAction={closeModal}
          isMobile={true}
        />
      )}
      {isTablet && (
        <LeftMenu
          isUnlisted={
            isLeftMenuVisible ||
            (isDashboard && menuConfig?.hideOnDashboard !== true)
          }
          onClose={() => {
            if (isLeftMenuVisible) {
              closeLeftMenu()
            }
          }}
        />
      )}
      {globalSpinnerStatus === true && (
        <StyledGlobalSpinner>
          <Spinner condensed={true} />
        </StyledGlobalSpinner>
      )}
    </StyledHeaderWrapper>
  )
}

export default Header
