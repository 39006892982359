import React, { Fragment, useCallback, useEffect, useState } from "react"
import { useIntl } from "gatsby-plugin-intl"
import PropTypes from "prop-types"
import { components } from "react-select"
import {
  StyledCloseIcon,
  StyledCountryList,
  StyledCountryListItem,
  StyledCountrySelectorFilter,
  StyledGroupHeading,
  StyledInlineWrapper,
  StyledLine,
  StyledOption,
  StyledOptionLabel,
  StyledOptionNumbers,
  StyledRegionContainer,
  StyledSearchOptionLabel,
  StyledSelectedCountry,
  StyledTagItem,
  StyledTagItemContainer,
  StyledTagsContainer,
} from "./index.styles"
import { faCheck } from "@fortawesome/free-solid-svg-icons/faCheck"
import { faXmark } from "@fortawesome/free-solid-svg-icons/faXmark"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { CustomModal, Dropdown } from "@tmu/components/common"
import { useTypeConstants } from "@tmu/hooks"
import { useCountries } from "@tmu/hooks"
import { getAllScreenTypes } from "@tmu/utils/mediaQueries"
import theme from "@tmu/global/theme"
import { Spacer } from "@tmu/global/page-addons/detail-page.styles"

const CountrySelectorFilter = ({
  handleSelect,
  isOfferList,
  activeCountry,
  activeCity,
  cities,
  countries,
  countriesFullData,
  isDropdown = false,
  searchable = false,
  inlineSearchableComponent = false,
  dontShowSelected = false,
  withTagsBelow = false,
  customStyle,
  disabled,
}) => {
  const { formatMessage } = useIntl()
  const allRegionsText = formatMessage({
    id: "countrySelect::modal::allRegions",
    defaultMessage: "All Regions",
  })

  const allCountriesText = formatMessage({
    id: "filter::all",
    defaultMessage: "All countries",
  })
  const defaultRegionOption = {
    label: allRegionsText,
    value: -1,
  }
  const [selectedCountry, setSelectedCountry] = useState(
    searchable ? allRegionsText : allCountriesText
  )
  const [modalStatus, setModalStatus] = useState(false)
  const [selectedData, setSelectedData] = useState(null)
  const [selectedCountries, setSelectedCountries] = useState([])
  const [selectedCities, setSelectedCities] = useState([])

  const isDataSelected = selectedData?.value && selectedData?.value !== -1
  const { operatingCountries } = useTypeConstants({
    filter: isOfferList ? "BY_STORES" : "BY_CHARITIES",
    callImmediately: !searchable,
  })
  let searchTimeout = null

  const {
    callRegions,
    regionsData,
    callCity,
    callCountry,
    cityData,
    countryData,
    regionsCalled,
  } = useCountries()
  const { isTablet, isDesktop, isWide } = getAllScreenTypes()
  const isMobile = !isWide && !isDesktop && !isTablet

  const handleSelectAction = (selected) => {
    setSelectedCountry(selected?.description || selected?.label)
    handleSelect(selected)
    if (selected?.value === -1) {
      setSelectedData(null)
    } else {
      setSelectedData(selected)
    }

    if (selected?.value !== -1) {
      closeModal()
    }
  }

  const countryClick = () => {
    openModal()
  }

  const closeModal = () => {
    setModalStatus(false)
  }

  const openModal = () => {
    setModalStatus(true)
  }

  const handleCountrySearch = (searchText) => {
    if (searchText?.length > 0) {
      clearTimeout(searchTimeout)

      searchTimeout = setTimeout(() => {
        callRegions({ variables: { search: searchText } })
      }, 1000)
    }
  }

  const numField = isOfferList ? "numOffers" : "numCampaigns"

  const countryList = (
    <StyledCountryList>
      {operatingCountries
        ?.sort((a, b) => b[numField] - a[numField])
        ?.map((item, index) => {
          return (
            <Fragment key={index}>
              {index === 0 && isMobile && <hr />}
              <StyledCountryListItem
                key={index}
                onClick={() => handleSelectAction(item)}
                isOfferList={isOfferList}>
                <div>
                  {item?.description} <span>{item[numField]}</span>
                </div>
                {isMobile && selectedCountry === item?.description && (
                  <div>
                    <FontAwesomeIcon icon={faCheck} />
                  </div>
                )}
              </StyledCountryListItem>
              {isMobile && <hr />}
            </Fragment>
          )
        })}
    </StyledCountryList>
  )

  const countryOptions = operatingCountries
    ?.sort((a, b) => b[numField] - a[numField])
    ?.map((item, index) => ({
      ...item,
      value: item?.id,
      label: item?.description,
    }))

  const countrySearchOptions =
    regionsData?.allCountries?.edges?.map((item, index) => ({
      ...item,
      value: item?.node?.code,
      label: item?.node?.name,
    })) ?? []

  const citySearchOptions =
    regionsData?.allCities?.edges?.map((item, index) => ({
      ...item,
      value: item?.node?.id,
      label: `${item?.node?.country?.name}, ${item?.node?.region?.name}, ${item?.node?.name}`,
    })) ?? []

  const allSearchResults = [...countrySearchOptions, ...citySearchOptions]

  useEffect(() => {
    if (isDataSelected) {
      return
    }

    if (activeCity?.length > 0) {
      callCity({ variables: { id: activeCity } })
    } else if (activeCountry?.length > 0) {
      callCountry({ variables: { code: activeCountry } })
    }

    if (cities) {
      const selectedCitiesOptions =
        cities?.map((item, index) => ({
          ...item,
          value: item?.id,
          label: `${item?.country?.name}, ${item?.region?.name}, ${item?.name}`,
        })) ?? []

      setSelectedCities(selectedCitiesOptions)
    }

    if (!countriesFullData?.length && countries) {
      let tempSelected = []
      const promiseArr = []
      countries?.forEach((item) => {
        promiseArr.push(callCountry({ variables: { code: item } }))
      })
      Promise.all(promiseArr).then((results) => {
        results?.forEach((result) => {
          const item = result?.data?.country
          const countryResult = {
            ...item,
            value: item?.code,
            label: item?.name,
          }
          tempSelected = [...tempSelected, countryResult]
        })
        setSelectedCountries(tempSelected)
      })
    } else if (countriesFullData?.length) {
      setSelectedCountries(countriesFullData)
    }
  }, [])

  useEffect(() => {
    let selected
    if (cityData) {
      const regionName = `${cityData.country?.name}, ${cityData.region?.name}, ${cityData.name}`
      setSelectedCountry(regionName)
      selected = {
        node: cityData,
        value: cityData?.id,
        label: regionName,
      }
    } else if (countryData) {
      setSelectedCountry(countryData?.name)
      selected = {
        node: countryData,
        value: countryData?.code,
        label: countryData.name,
      }
    } else {
      selected = null
    }

    setSelectedData(selected)

    if (selected && handleSelect) {
      handleSelect(selected)
    }
  }, [cityData, countryData])

  const groupedCountryOptions = [
    {
      label: formatMessage({
        id: "countrySelect::modal::chooseCountryOrcity",
        defaultMessage: "Choose a country or city",
      }),
      options: [],
    },
    {
      label: formatMessage({
        id: "countrySelect::modal::cities",
        defaultMessage: "Cities",
      }),
      options: citySearchOptions,
    },
    {
      label: formatMessage({
        id: "countrySelect::modal::countries",
        defaultMessage: "Countries",
      }),
      options: countrySearchOptions,
    },
  ]

  const style = {
    padding: isWide
      ? "3rem"
      : isTablet
      ? "2rem 2rem 2rem"
      : "0 1rem 1.5rem 1rem",
    overflow: "visible",
  }

  const currentCountry = useCallback(
    countryOptions?.find(
      (item) =>
        item?.description === selectedCountry?.description ||
        item?.description === selectedCountry
    )
  )

  const dropdownHeight = customStyle?.height
    ? customStyle?.height
    : isWide
    ? "3.375rem"
    : "3rem"

  const selectStyles = {
    control: {
      height: dropdownHeight,
      paddingTop: "0",
      paddingRight: "0",
      fontSize: isWide ? "1.5rem" : "0.75rem",
      textAlign: "left",
      minWidth: "unset",
      lineHeight: isWide ? "1rem" : "0.75rem",
    },
    valueContainer: {
      height: dropdownHeight,
      paddingTop: "0",
    },
    inputContainer: {
      height: dropdownHeight,
      paddingTop: "0",
    },
    input: {
      padding: "0",
      margin: "0",
    },
  }

  const inlineComponentHeight = "3rem"

  const searchableStyles = {
    control: {
      height: dropdownHeight,
      paddingTop: "0",
      paddingRight: "0",
      fontSize: isWide ? "1.5rem" : "1rem",
      textAlign: "left",
      minWidth: "unset",
      lineHeight: isWide ? "2.25rem" : "1.25rem",
      marginTop: !isTablet && "-1.8rem",
    },
    valueContainer: {
      height: dropdownHeight,
      paddingTop: "0",
      marginLeft: !isTablet && !inlineSearchableComponent && "1.5rem",
    },
    singleValue: {
      color: !isDataSelected && theme?.colors?.disabled,
    },
    inputContainer: {
      height: dropdownHeight,
      paddingTop: "0",
    },
    input: {
      padding: "0",
      margin: "0",
    },
    menuList: {
      maxHeight: !isTablet ? "calc(100vh - 10rem)" : "18.75rem",
    },
    dropdownIndicator: {
      display: "none",
    },
    placeholder: {},
    menu: {},
  }

  if (!isTablet && !inlineSearchableComponent) {
    searchableStyles.menu.boxShadow = "none"
    searchableStyles.control.borderStyle = "none"
  }

  const modalHeader =
    searchable && !isTablet
      ? ""
      : searchable && isTablet
      ? formatMessage({
          id: "countrySelect::modal::headerRegion",
          defaultMessage: "Choose region",
        })
      : formatMessage({
          id: "countrySelect::modal::header",
          defaultMessage: "Choose country",
        })

  const modalDescription =
    searchable && !isTablet
      ? ""
      : searchable && isTablet
      ? formatMessage({
          id: `countrySelect::modal::regionDescription`,
          defaultMessage:
            "Choose a city, a region or a country. We will show PAC Offers of stores that provide in the selected region.",
        })
      : formatMessage({
          id: `countrySelect::modal::${isOfferList ? "text2" : "text"}`,
          defaultMessage:
            "Choose the country. We show offers according to the country you have selected.",
        })

  const handleCountrySelect = (selected) => {
    const isCity = selected?.node?.country?.code || selected?.country

    if (selected?.value === -1) {
      return
    }

    if (isCity) {
      setSelectedCities([
        ...selectedCities?.filter((item) => item?.value !== selected?.value),
        selected,
      ])
    } else {
      setSelectedCountries([
        ...selectedCountries?.filter((item) => item?.value !== selected?.value),
        selected,
      ])
    }
  }

  useEffect(() => {
    if (typeof handleSelect === "function") {
      handleSelect(selectedCountries, selectedCities)
    }
  }, [selectedCities, selectedCountries])

  const removeRegion = (selected) => {
    const isCity = selected?.node?.country?.code || selected?.country

    if (isCity) {
      setSelectedCities(
        selectedCities?.filter((item) => item?.value !== selected?.value)
      )
    } else {
      setSelectedCountries(
        selectedCountries?.filter((item) => item?.value !== selected?.value)
      )
    }
  }

  const Option = ({ children, ...props }) => {
    const optionData = props?.data
    return (
      <components.Option {...props}>
        <StyledOption>
          <StyledOptionLabel>{optionData?.description}</StyledOptionLabel>
          <StyledOptionNumbers
            className={
              optionData?.description === selectedCountry ? "active" : ""
            }></StyledOptionNumbers>
        </StyledOption>
      </components.Option>
    )
  }

  const SearchOption = ({ children, ...props }) => {
    const optionData = props?.data
    return (
      <components.Option {...props}>
        <StyledOption>
          <StyledSearchOptionLabel>{optionData?.label}</StyledSearchOptionLabel>
          <StyledOptionNumbers
            className={
              optionData?.label === selectedCountry ? "active" : ""
            }></StyledOptionNumbers>
        </StyledOption>
      </components.Option>
    )
  }

  const GroupHeading = ({ children, ...props }) => {
    return (
      <components.GroupHeading {...props}>
        <StyledGroupHeading>{children}</StyledGroupHeading>
      </components.GroupHeading>
    )
  }

  const isExtraSettings =
    allSearchResults?.length === 0 ? { menuIsOpen: false } : {}

  const cityCountryDropDown = (
    <StyledRegionContainer>
      <Dropdown
        components={{ Option: SearchOption, GroupHeading }}
        newDesign
        data-testid="country"
        id="country"
        name="country"
        value={!dontShowSelected ? selectedData : null}
        options={groupedCountryOptions}
        defaultSelectStyles={searchableStyles}
        onInputChange={(selected) => {
          handleCountrySearch(selected)
        }}
        onChange={(selected) => {
          if (withTagsBelow) {
            handleCountrySelect(selected)
            return
          }
          if (selected === null) {
            selected = defaultRegionOption
          }
          handleSelectAction(selected)
          setSelectedCountry(selected?.label)
        }}
        isClearable={dontShowSelected ? false : isDataSelected}
        {...isExtraSettings}
      />
      {!isTablet && !inlineSearchableComponent && <StyledLine />}
      {withTagsBelow && (
        <>
          <Spacer top={0.75} />
          <StyledTagsContainer>
            {[...selectedCountries, ...selectedCities]?.map((item, index) => (
              <StyledTagItemContainer>
                <StyledTagItem>{item?.label}</StyledTagItem>
                <StyledCloseIcon onClick={() => removeRegion(item)}>
                  <FontAwesomeIcon icon={faXmark} />
                </StyledCloseIcon>
              </StyledTagItemContainer>
            ))}
          </StyledTagsContainer>
        </>
      )}
    </StyledRegionContainer>
  )

  const iconSize = isWide ? "2.5rem" : "1.25rem"

  return (
    <>
      {isDropdown ? (
        <>
          <Dropdown
            disabled={disabled}
            components={{
              Option,
            }}
            newDesign
            data-testid="country"
            id="country"
            name="country"
            value={currentCountry}
            options={countryOptions}
            defaultSelectStyles={selectStyles}
            onChange={(selected) => {
              setSelectedCountry(selected?.label)
              handleSelectAction(selected)
            }}
          />
        </>
      ) : inlineSearchableComponent ? (
        <StyledInlineWrapper className="input-wrapper" disabled={disabled}>
          {cityCountryDropDown}
        </StyledInlineWrapper>
      ) : (
        <StyledCountrySelectorFilter disabled={disabled}>
          <StyledSelectedCountry
            isOfferList={isOfferList}
            onClick={countryClick}>
            {selectedCountry}
          </StyledSelectedCountry>
          {modalStatus && (
            <CustomModal
              isModalOpen={modalStatus}
              style={style}
              header={modalHeader}
              headerStyle={{
                marginBottom: isWide ? "1rem" : "0",
                paddingTop: isTablet
                  ? "0"
                  : !isTablet && searchable
                  ? "0.5rem"
                  : "1rem",
              }}
              text={modalDescription}
              descriptionStyle={{ marginBottom: isWide ? "2.5rem" : "1.5rem" }}
              children={searchable ? cityCountryDropDown : countryList}
              isCloseIcon={true}
              cancelButtonAction={closeModal}
              isMobile={isMobile}
              closeIconStyle={{
                zIndex: "100",
                width: iconSize,
                height: iconSize,
              }}
            />
          )}
        </StyledCountrySelectorFilter>
      )}
    </>
  )
}

export default CountrySelectorFilter

CountrySelectorFilter.propTypes = {
  handleSelect: PropTypes.func,
  isOfferList: PropTypes.bool,
}
