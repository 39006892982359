import styled from "@emotion/styled"

export const StyledWrapper = styled.div`
  margin: 5.125rem auto 0;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    max-width: ${(p) => p.theme.breakpoints.tablet};
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    max-width: ${(p) => p.theme.breakpoints.desktop};
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    max-width: ${(p) => p.theme.breakpoints.wide};
  }
`

export const StyledStoryBox = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin: auto;

  img {
    width: 100%;
  }

  min-height: 21rem;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    padding-left: 1.5rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    padding-left: 2rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    padding-left: 6rem;
    min-height: 40rem;
  }
`

export const StyledStoryBoxHeader = styled.div`
  font-family: ${(p) => p.theme.fontFamilies.headline};
  font-style: ${(p) => p.theme.fontStyles.normal};
  font-weight: ${(p) => p.theme.fontWeights.semiBolder};
  color: ${(p) => p.theme.colors.whiteSmoke};
  text-align: center;
  font-size: 1.5rem;
  line-height: 2.125rem;
  max-width: 20.4375rem;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    color: ${(p) => p.theme.colors.carrot};
    text-align: left;
    font-size: 2rem;
    line-height: 2.75rem;
    margin: 1.5rem 0;
    max-width: 28.4375rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    margin: 3rem 0 1.5rem;
    max-width: 39.6875rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 3rem;
    line-height: normal;
    margin-top: 0;
    max-width: 70.6875rem;
  }
`

export const StyledStoryBoxTitle = styled.p`
  font-family: ${(p) => p.theme.fontFamilies.headline};
  font-style: ${(p) => p.theme.fontStyles.normal};
  font-weight: ${(p) => p.theme.fontWeights.semiBolder};
  color: ${(p) => p.theme.colors.pitchBlack};
  text-align: center;
  font-size: 1.125rem;
  line-height: 1.75rem;
  margin-bottom: 2rem;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    text-align: unset;
    max-width: 32.0625rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 2rem;
    line-height: 2.75rem;
    max-width: 61.125rem;
  }
`

export const StyledStoryBoxText = styled.div`
  overflow: hidden;
  z-index: 999;
  margin: auto;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    margin: 0;
    margin-top: 2rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    margin-top: 0;
  }
`

export const StyledStoryBoxOverlay = styled.div`
  z-index: 899;
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: ${(p) => p.theme.colors.pitchBlack};
  opacity: 40%;
`

export const StyledStoryBoxImage = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;

  img {
    height: 100%;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    left: auto;
    width: 50%;
    -webkit-mask-image: linear-gradient(to right, transparent 0%, black 100%);
    mask-image: linear-gradient(to right, transparent 0%, black 50%);
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    width: 60%;
    -webkit-mask-image: linear-gradient(to right, transparent 0%, black 10%);
    mask-image: linear-gradient(to right, transparent 0%, black 50%);
  }
`

export const StyledStoryBoxMobileText = styled.div`
  margin: 2.5rem 1rem 3rem 1rem;
`

export const StyledBenefitList = styled.div`
  background-color: ${(p) => p.theme.colors.whiteSmoke};
  display: flex;
  flex-direction: column;
  padding-bottom: 3rem;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    padding: 2.5rem 1.5rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    padding: 2rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    padding: 4rem 6rem;
  }
`

export const StyledBenefit = styled.div`
  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    align-items: flex-start;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
`

export const StyledBenefitCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;

  &:nth-child(2) {
    padding-left: unset;
    height: 100%;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    padding-top: 0;
    justify-content: flex-start;
    align-items: flex-start;

    &:nth-child(2) {
      border-right: 0.125rem solid ${(p) => p.theme.colors.darkGrey};
      border-left: 0.125rem solid ${(p) => p.theme.colors.darkGrey};
      border-top: none;
      border-bottom: none;
    }

    &:nth-child(1) {
      padding-right: 0.5rem;
    }

    &:nth-child(2) {
      padding-right: 0.5rem;
      padding-left: 0.5rem;
    }

    &:nth-child(3) {
      padding-left: 0.5rem;
    }
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    padding-top: 0;

    &:nth-child(1) {
      padding-right: 1rem;
    }

    &:nth-child(2) {
      padding-right: 1rem;
      padding-left: 1rem;
    }

    &:nth-child(3) {
      padding-left: 1rem;
    }
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    &:nth-child(1) {
      padding-right: 2rem;
    }

    &:nth-child(2) {
      padding-right: 1rem;
      padding-left: 1rem;
    }

    &:nth-child(3) {
      padding-left: 2rem;
    }
  }
`

export const StyledBenefitFontIcon = styled.div`
  & > svg {
    align-items: left;
    justify-content: left;
    color: ${(p) => p.theme.colors.carrot};
    height: 2rem;
    width: 2rem;
  }

  margin-top: 3.375rem;
  padding-left: 1rem;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    & > svg {
      color: ${(p) => p.theme.colors.carrot};
      height: 2rem;
      width: 2rem;
    }

    margin-top: 0;
    padding-left: unset;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    & > svg {
      height: 2.0625rem;
      width: 2.0625rem;
    }
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    & > svg {
      height: 4.125rem;
      width: 4.125rem;
    }
  }
`

export const StyledBenefitTitle = styled.h4`
  font-family: ${(p) => p.theme.fontFamilies.headline};
  font-style: ${(p) => p.theme.fontStyles.normal};
  font-weight: ${(p) => p.theme.fontWeights.semiBolder};
  color: ${(p) => p.theme.colors.pitchBlack};
  text-align: left;
  font-size: 1.5rem;
  line-height: 2.125rem;
  margin-top: 2rem;
  padding-left: 1rem;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    text-align: left;
    margin: 2.3125rem 0 1rem;
    padding-left: unset;
    max-width: unset;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    margin: 2.3125rem 0 0.625rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 2rem;
    line-height: 2.75rem;
    margin: 2.3125rem 0 2.0625rem;
  }
`
export const StyledBenefitDescription = styled.div`
  font-family: ${(p) => p.theme.fontFamilies.regular};
  font-style: ${(p) => p.theme.fontStyles.normal};
  font-weight: ${(p) => p.theme.fontWeights.liteMedium};
  color: ${(p) => p.theme.colors.pitchBlack};
  text-align: left;
  font-size: 1rem;
  line-height: 1.5rem;
  margin: 1rem 0 1.5rem;
  padding: 0 1rem;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    margin-bottom: 0;
    padding-left: unset;
    min-width: 13.75rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    min-width: 17.5rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 1.5rem;
    line-height: 2.25rem;
    min-width: 33.3125rem;
  }
`

export const StyledBenefitLink = styled.div`
  padding-left: 1rem;
  margin-bottom: 2.5rem;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    padding-left: unset;
    margin-bottom: unset;
    margin-top: 1.5rem;
    max-width: 11.875rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    max-width: unset;
  }

  button {
    p {
      span {
        font-family: ${(p) => p.theme.fontFamilies.headline};
        font-weight: ${(p) => p.theme.fontWeights.semiBolder};
        font-style: ${(p) => p.theme.fontStyles.normal};
        color: ${(p) => p.theme.colors.carrot};
        font-size: 1rem;
        line-height: 1.5rem;
        text-align: left;

        @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
        }

        @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
        }

        @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
          font-size: 1.5rem;
          line-height: 2.125rem;
        }
      }
      svg {
        color: ${(p) => p.theme.colors.carrot};
        display: unset;
      }
    }
  }
`

export const StyledStoreContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 1.5rem;
  padding: 3rem 1rem;

  h4 {
    font-family: ${(p) => p.theme.fontFamilies.headline};
    font-style: ${(p) => p.theme.fontStyles.normal};
    font-weight: ${(p) => p.theme.fontWeights.semiBolder};
    color: ${(p) => p.theme.colors.pitchBlack};
    font-size: 1.5rem;
    line-height: 2.125rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    grid-template-columns: 1fr 1fr;
    column-gap: 2rem;
    padding: 3rem 1.5rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    column-gap: 1.5rem;
    padding: 3.4375rem 2rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    column-gap: 3.875rem;
    padding: 5rem 6rem;
  }
`

export const StyledStoreImage = styled.div`
  img {
    height: 100%;
    width: 100%;
  }

  margin: 1.5rem 0;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    margin: unset;
  }
`

export const StyledStoreContent = styled.div`
  h4 {
    font-family: ${(p) => p.theme.fontFamilies.headline};
    font-style: ${(p) => p.theme.fontStyles.normal};
    font-weight: ${(p) => p.theme.fontWeights.semiBolder};
    color: ${(p) => p.theme.colors.pitchBlack};
    font-size: 1.5rem;
    line-height: 2.125rem;

    @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
      font-size: 1.75rem;
      line-height: 2.625rem;
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
      font-size: 3rem;
      line-height: normal;
    }
  }

  P {
    font-family: ${(p) => p.theme.fontFamilies.regular};
    font-style: ${(p) => p.theme.fontStyles.normal};
    font-weight: ${(p) => p.theme.fontWeights.liteMedium};
    color: ${(p) => p.theme.colors.pitchBlack};
    font-size: 1rem;
    line-height: 1.5rem;

    @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
      font-size: 1.5rem;
      line-height: 2.25rem;
    }
  }
`

export const StyledStoreMapIcon = styled.div`
  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    margin: 1rem 0;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    margin: 2.5rem 0;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
  }
`
export const StyledSectionDivider = styled.hr`
  margin: 1rem 1rem 0;
  width: unset;
`
