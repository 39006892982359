import React, { useEffect } from "react"
import { Router } from "@reach/router"
import { navigate } from "gatsby-plugin-intl"
import {
  PartnerProfileForm,
  Campaigns,
  Donations,
  // TODO: Notifications will be added after designs and BE API is ready
  // Notifications,
  /* Overview,
  Users, */
  Wallet,
  DownloadCenter,
  ReferralCodeListings,
  ReferralCodeForm,
} from "@tmu/components/dashboard/dashboardPages"
import { useAuth } from "@tmu/hooks"
import { useMediaQuery } from "beautiful-react-hooks"
import { DisabledWarning } from "@tmu/layouts/dashboard"
import { API_PERMISSIONS } from "@tmu/apollo/constants"

const Partners = () => {
  const { isLoading, user, apiPermissions } = useAuth()
  const isMedium =
    typeof window !== "undefined" && useMediaQuery("(max-width: 767px)")

  useEffect(() => {
    if (isLoading) {
      return
    }
    if (!isLoading && user?.id && !user.isPartner) {
      navigate("/dashboard/profile")
    }
  }, [isLoading, user])

  return isMedium ? (
    <DisabledWarning />
  ) : (
    <Router>
      {apiPermissions?.includes(
        API_PERMISSIONS.PARTNER_VIEW_ACCOUNT_INFORMATION
      ) && <PartnerProfileForm path="account/*" default />}

      {apiPermissions?.includes(API_PERMISSIONS.PARTNER_VIEW_WALLET) && (
        <Wallet path="wallet/*" dashboardType="partners" />
      )}

      {apiPermissions?.includes(
        API_PERMISSIONS.PARTNER_VIEW_CAMPAIGN_LISTING
      ) && <Campaigns path="campaigns/*" />}

      {/* {apiPermissions?.includes(API_PERMISSIONS.PARTNER_MANAGE_USERS) && (
        <Users path="users/*" dashboardType="partners" />
      )} */}

      {/*  <Notifications path="notifications/*" /> */}
      <Donations dashboardType="partners" path="donations/*" />
      <DownloadCenter dashboardType="partners" path="download-center/*" />
      {apiPermissions?.includes(API_PERMISSIONS.PARTNER_VIEW_REFERRAL_LINK) && (
        <ReferralCodeListings
          dashboardType="partners"
          path="referral-codes/*"
        />
      )}
      {apiPermissions?.includes(
        API_PERMISSIONS.PARTNER_CREATE_REFERRAL_LINK
      ) && (
        <ReferralCodeForm dashboardType="partners" path="referral-codes/new" />
      )}
      {apiPermissions?.includes(
        API_PERMISSIONS.PARTNER_UPDATE_REFERRAL_LINK
      ) && (
        <ReferralCodeForm
          dashboardType="partners"
          path="referral-codes/edit/:id"
        />
      )}
    </Router>
  )
}

export default Partners
