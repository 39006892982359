import React, { useRef, useEffect } from "react"
import { FormattedMessage, navigate, useIntl } from "gatsby-plugin-intl"
import { LazyImage } from "@tmu/components/common"
import { faChevronRight } from "@fortawesome/pro-regular-svg-icons/faChevronRight"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import {
  StyledSlice,
  StyledDescription,
  StyledTitle,
  StyledStoryBox,
  StyledText,
  StyledLink,
  StyledImage,
} from "./index.styles"

const { CLOUDFLARE_IMAGE_URL } = process.env

const SliceHowItWorks = () => {
  return (
    <StyledSlice data-testid="slice-how-it-works" id="how-it-works">
      <StyledStoryBox className="row-one">
        <StyledText>
          <StyledTitle>
            <FormattedMessage
              id="howItWorks::sliceHowTo::nonProfitTitle"
              defaultMessage="Are you charity representative?"
            />
          </StyledTitle>
          <StyledDescription>
            <FormattedMessage
              id="howItWorks::sliceHowTo::nonProfitDescription"
              defaultMessage="Learn more about what we can achieve together"
            />
          </StyledDescription>
          <StyledLink onClick={() => navigate("/join/ngo")}>
            <FormattedMessage
              id="howItWorks::sliceHowTo::nonProfitLink"
              defaultMessage="Join as a Non-Profit"
            />
            <FontAwesomeIcon icon={faChevronRight} />
          </StyledLink>
        </StyledText>
        <StyledImage>
          <LazyImage
            altName="join as non-profit"
            src={CLOUDFLARE_IMAGE_URL + "/static/assets/images/hands.png"}
            width="auto"
            height="216"
            fit="cover"
          />
        </StyledImage>
      </StyledStoryBox>
      <StyledStoryBox className="row-two">
        <StyledImage className="left-to-right">
          <LazyImage
            altName="join as merchant"
            src={
              CLOUDFLARE_IMAGE_URL + "/static/assets/images/store owner.jpeg"
            }
            width="auto"
            height="216"
            fit="cover"
          />
        </StyledImage>
        <StyledText className="right">
          <StyledTitle>
            <FormattedMessage
              id="howItWorks::sliceHowTo::merchantTitle"
              defaultMessage="Do you have online store?"
            />
          </StyledTitle>
          <StyledDescription>
            <FormattedMessage
              id="howItWorks::sliceHowTo::merchantDescription"
              defaultMessage="Increase your sales by doing good, join TrustMeUp"
            />
          </StyledDescription>
          <StyledLink onClick={() => navigate("/join/merchants")}>
            <FormattedMessage
              id="howItWorks::sliceHowTo::merchantLink"
              defaultMessage="Join as a Merchant"
            />
            <FontAwesomeIcon icon={faChevronRight} />
          </StyledLink>
        </StyledText>
      </StyledStoryBox>
    </StyledSlice>
  )
}

export default SliceHowItWorks
