import { useLazyQuery } from "@apollo/client"
import { useApolloApiClients } from "@tmu/apollo/client"
import {
  ALL_CITIES_QUERY,
  ALL_COUNTRIES_QUERY,
  // CITIES_QUERY,
  COUNTRIES_QUERY,
  COUNTRIES_AND_CITIES_QUERY,
  COUNTRY_QUERY,
  CITY_QUERY,
} from "@tmu/apollo/dashboard/queries/countries"
import { get, getExpiration, removeExpiredKeys, set } from "@tmu/utils/storage"
import { useIntl } from "gatsby-plugin-intl"
import { useEffect, useState } from "react"

const storageKeys = {
  // CITIES: "cities",
  COUNTRIES: "countries",
}

// const regionSearchCharLimit = 3

// const expireDuration = Date.now() + 24 * 60 * 60 * 1000

const useCountries = () => {
  const { storefrontClient } = useApolloApiClients()
  const { locale } = useIntl()
  const [countries, setCountries] = useState([])
  const [allLocaleCountries, setAllLocaleCountries] = useState({})
  // const [cities, setCities] = useState({})
  // const [selectedCities, setSelectedCities] = useState({})
  const [countryOptions, setCountryOptions] = useState([])
  //const [cityOptions, setCityOptions] = useState([])

  //const citiesExpiration = getExpiration(storageKeys.CITIES) || expireDuration
  const countriesExpiration =
    //getExpiration(storageKeys.CITIES) || expireDuration

    useEffect(() => {
      removeExpiredKeys()
      //setCities(get(storageKeys.CITIES) || {})
      setAllLocaleCountries(get(storageKeys.COUNTRIES) || {})
    }, [])

  const [callCountries, { data, called: countriesCalled, refetch }] =
    useLazyQuery(COUNTRIES_QUERY, {
      variables: {
        language: locale,
      },
      client: storefrontClient,
      fetchPolicy: "cache-and-network",
    })

  const [
    callAllCountries,
    { data: allCountriesData, called: allCountriesCalled },
  ] = useLazyQuery(ALL_COUNTRIES_QUERY, {
    variables: {
      language: locale,
    },
    client: storefrontClient,
    fetchPolicy: "cache-and-network",
  })

  const [callCity, { data: cityData, called: cityCalled }] = useLazyQuery(
    CITY_QUERY,
    {
      client: storefrontClient,
      fetchPolicy: "cache-and-network",
    }
  )

  const [callCountry, { data: countryData, called: countryCalled }] =
    useLazyQuery(COUNTRY_QUERY, {
      client: storefrontClient,
      fetchPolicy: "cache-and-network",
    })

  const [callAllCities, { data: allCitiesData, called: allCitiesCalled }] =
    useLazyQuery(ALL_CITIES_QUERY, {
      variables: {
        language: locale,
      },
      client: storefrontClient,
      fetchPolicy: "cache-and-network",
    })

  const [callRegions, { data: regionsData, called: regionsCalled }] =
    useLazyQuery(COUNTRIES_AND_CITIES_QUERY, {
      variables: {
        language: locale,
      },
      client: storefrontClient,
      fetchPolicy: "cache-and-network",
    })

  // const [callCities] = useLazyQuery(CITIES_QUERY, {
  //   onCompleted: (d) => {
  //     const cityData = d?.cities
  //     let tempCities = cities
  //     const countryCode = cityData?.at(0)?.countryCode
  //     tempCities[countryCode] = cityData
  //     set(storageKeys.CITIES, tempCities, citiesExpiration)
  //     setCities(tempCities)
  //     setSelectedCities(cityData)

  //     createCityOptions(cityData)
  //   },
  // })

  const getCountries = () => {
    let tempData = allLocaleCountries[locale]

    if (!tempData) {
      const countryData = get(storageKeys.COUNTRIES)
      if (countryData) {
        const localeCountry = countryData[locale]
        if (localeCountry) {
          tempData = localeCountry
        }
      }
    }

    if (tempData && tempData?.length > 0) {
      setCountries(tempData)
      createCountryOptions(tempData)
      return
    }

    if (!countriesCalled) {
      callCountries()
    } else {
      refetch()
    }
  }

  // const getCities = (countryCode) => {
  //   let cityByCountryData = cities[countryCode]

  //   if (!cityByCountryData) {
  //     const cityData = get(storageKeys.CITIES)
  //     if (cityData) {
  //       const localeCities = cityData[countryCode]
  //       if (localeCities) {
  //         cityByCountryData = localeCities
  //       }
  //     }
  //   }

  //   if (cityByCountryData && cityByCountryData?.length > 0) {
  //     setSelectedCities(cityByCountryData)
  //     createCityOptions(cityByCountryData)
  //     return
  //   }

  //   callCities({
  //     variables: { country: countryCode },
  //     client: storefrontClient,
  //     fetchPolicy: "cache-first",
  //   })
  // }

  useEffect(() => {
    if (data) {
      const tempData = data?.countries || []
      let tempCountriesData = allLocaleCountries
      tempCountriesData[locale] = tempData
      set(storageKeys.COUNTRIES, tempCountriesData, countriesExpiration)
      setAllLocaleCountries(tempCountriesData)
      setCountries(tempData)
      createCountryOptions(tempData)
    }
  }, [data])

  useEffect(() => {
    if (allLocaleCountries) {
      const tempCountryData = allLocaleCountries[locale]
      setCountries(tempCountryData)
      createCountryOptions(tempCountryData)
    }
  }, [allLocaleCountries])

  // useEffect(() => {
  //   if (cities) {
  //     const tempData = cities[locale]
  //     setSelectedCities(tempData)
  //   }
  // }, [cities])

  const createCountryOptions = (countryData) => {
    const optionsData = countryData?.map((item) => ({
      value: item.shortCode,
      label: item.label,
    }))

    setCountryOptions(optionsData)
  }

  // const createCityOptions = (cityData) => {
  //   const optionsData = cityData?.map((item) => ({
  //     value: item.shortCode,
  //     label: item.name,
  //   }))

  //   setCityOptions(optionsData)
  // }

  // const getCityRegions = (country = "", inputValue) => {
  //   if (inputValue?.length < regionSearchCharLimit) return null
  //   const currentCities = selectedCities

  //   return new Promise((resolve, reject) => {
  //     const result = currentCities
  //       ?.filter((i) => i.name.toLowerCase().includes(inputValue.toLowerCase()))
  //       .map((item) => ({
  //         value: item.shortCode,
  //         label: item.name,
  //       }))
  //     resolve(result)
  //   })
  // }

  return {
    allLocaleCountries,
    countries,
    // cities,
    // selectedCities,
    countryOptions,
    // cityOptions,
    // getCities,
    getCountries,
    // getCityRegions,
    callAllCities,
    callAllCountries,
    allCountriesData: allCountriesData?.allCountries?.edges,
    allCitiesData: allCitiesData?.allCities?.edges,
    callRegions,
    regionsData,
    callCountry,
    callCity,
    countryData: countryData?.country,
    cityData: cityData?.city,
    regionsCalled,
  }
}

export default useCountries
