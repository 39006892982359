import React from "react"
import { useIntl } from "gatsby-plugin-intl"
import { CustomModal } from "@tmu/components/common"

export const StopModal = ({
  isStopModalOpen,
  handleStopModalClose,
  handleEndCampaign,
}) => {
  const { formatMessage } = useIntl()
  return (
    <CustomModal
      isModalOpen={isStopModalOpen}
      handleModalClose={handleStopModalClose}
      header={formatMessage({
        id: "dashboard::common::sureToStop",
        defaultMessage: "Sure, you want to stop the campaign?",
      })}
      text={formatMessage({
        id: "dashboard::campaigns::stopCampaignWarning",
        defaultMessage:
          "Once you end a campaign, it cannot be reopened or get donation for this campaign.",
      })}
      confirmButtonText={formatMessage({
        id: "dashboard::common::stop",
        defaultMessage: "Stop",
      })}
      confirmButtonAction={handleEndCampaign}
      confirmButtonColor="red"
      cancelButtonAction={handleStopModalClose}
      isCloseIcon={true}
      isMobile={false}
      isFull={false}
    />
  )
}
