/* eslint-disable jsx-a11y/no-onchange */
import React, { useEffect, useState, useRef } from "react"
import { useIntl, FormattedMessage, Link } from "gatsby-plugin-intl"
import { useQuery } from "@apollo/client"
import { CAMPAIGN_LISTING_QUERY_WITH_LOCALE } from "@tmu/apollo/storefront/queries/campaign"
import { useApolloApiClients } from "@tmu/apollo/client"
import { useAllCampaignCategories, useAuth, useTypeConstants } from "@tmu/hooks"
import { getAllScreenTypes } from "@tmu/utils/mediaQueries"
import queryString from "query-string"
import CategoryList from "./CategoryList"
import SEO from "@tmu/components/seo"
import { OFFER_TYPE } from "@tmu/apollo/constants"

import {
  Spinner,
  NewCard,
  FilterBar,
  Checkbox,
  CommonToggle,
} from "@tmu/components/common"
import {
  StyledCampaignsWrapper,
  StyledContent,
  StyledHeading,
  StyledListingFilters,
  StyledCampaignList,
  CampaignListAction,
  StyledCampaignsBody,
  NoResults,
  StyledFilterBar,
  ShowAllButton,
  StyledHeadingTop,
  StyledOnlyPacsToggle,
} from "./index.styles"
import { getQueryParams, getValueForLocale } from "@tmu/utils/string"
import { CARDS_PER_PAGE, LIST_TYPES } from "@tmu/apollo/constants"

const sortingTypes = [
  {
    id: "name,-created",
    label: {
      id: "sorting::alphabeticallyLow",
      defaultMessage: "Alphabetically A-Z",
    },
  },
  {
    id: "-name,-created",
    label: {
      id: "sorting::alphabeticallyHigh",
      defaultMessage: "Alphabetically Z-A",
    },
  },
  {
    id: "fundedAmount,-created",
    label: {
      id: "sorting::donationsLow",
      defaultMessage: "Total raised: low > high",
    },
  },
  {
    id: "-fundedAmount,-created",
    label: {
      id: "sorting::donationsHigh",
      defaultMessage: "Total raised: high > low",
    },
  },
  {
    id: "donation_count,-created",
    label: {
      id: "sorting::donationCountLow",
      defaultMessage: "Donations: low > high",
    },
  },
  {
    id: "-donation_count,-created",
    label: {
      id: "sorting::donationCountHigh",
      defaultMessage: "Donations: high > low",
    },
  },
  {
    id: "-created",
    label: {
      id: "sorting::dateNew",
      defaultMessage: "Date: recent first",
    },
  },
  {
    id: "created",
    label: {
      id: "sorting::dateOld",
      defaultMessage: "Date: old first",
    },
  },
  {
    id: "progress,-created",
    label: {
      id: "sorting::progressLow",
      defaultMessage: "Progress: low first",
    },
  },
  {
    id: "-progress,-created",
    label: {
      id: "sorting::progressHigh",
      defaultMessage: "Progress: high first",
    },
  },
]

const CampaignListing = ({ location }) => {
  const { isLoading } = useAuth()
  const { locale, defaultLocale, formatMessage } = useIntl()

  const ref = useRef()
  const filterBarRef = useRef()
  const loadRef = useRef()
  const [searchText, setSearchText] = useState(null)
  const params = getQueryParams()
  const [selectedCategories, setSelectedCategories] = useState([])
  const [isCharitiesFilter, setCharitiesFilter] = useState(
    params?.charity !== "false"
  )
  const [isCampaignsFilter, setCampaignsFilter] = useState(
    params?.event !== "false"
  )
  const [isEventsFilter, setEventsFilter] = useState(params?.event !== "false")
  const [selectedCategoryIds, setSelectedCategoryIds] = useState([])
  const [isCategoriesFetched, setIsCategoriesFetched] = useState(false)
  const [listType, setListType] = useState(params?.view || LIST_TYPES.TILE)
  const [selectedCountry, setSelectedCountry] = useState(params?.country)
  const [isOnlyPACFilter, setOnlyPACFilter] = useState(params?.pacs === "true")
  const [isSupporterFilter, setIsSupporterFilter] = useState(
    params?.supporter !== "false"
  )

  const [isOnlyCorporateFilter, setIsOnlyCorporateFilter] = useState(
    params?.corporate === "true"
  )

  /* These codes will be activated when requested.
    const [isStoreSupporterFilter, setIsStoreSupporterFilter] = useState(
    params?.storeSupporter !== "false"
  ) */

  const [selectedSortBy, setSelectedSortBy] = useState("-fundedAmount,-created")
  const [loadMoreAfter, setLoadMoreAfter] = useState(false)

  const { isDesktop } = getAllScreenTypes()
  const [firstLoad, setFirstLoad] = useState(false)
  const { storefrontClient } = useApolloApiClients()
  const { callTypeConstants, called: callTypeConstantsCalled } =
    useTypeConstants({ callImmediately: false })

  useEffect(() => {
    setCharitiesFilter(params?.charity !== "false")
    setCampaignsFilter(params?.campaign !== "false")
    setEventsFilter(params?.event !== "false")
    setOnlyPACFilter(params?.pacs === "true")
    setIsSupporterFilter(params?.supporter !== "false")
    setIsOnlyCorporateFilter(params?.corporate === "true")
  }, [params])

  const { loading, data, fetchMore } = useQuery(
    CAMPAIGN_LISTING_QUERY_WITH_LOCALE(locale),
    {
      variables: {
        first: CARDS_PER_PAGE,
        orderBy: selectedSortBy,
        country: selectedCountry,
        categories: selectedCategoryIds,
        pacMultiplier: isOnlyPACFilter ? 2 : null,
        search: searchText,
        isPublic: true,
        visibilityStatus: ["1", "2", "3"],
        campaignOrigin: isOnlyCorporateFilter ? "brand" : null,
        fundraiserType: isOnlyCorporateFilter
          ? null
          : [
              isCharitiesFilter ? "charities" : null,
              isCampaignsFilter ? "campaigns" : null,
              isEventsFilter ? "events" : null,
              isSupporterFilter ? "supporters" : null,
            ].filter((item) => item),
        /* This code will be activated when requested.
      isStoreSupporter: isStoreSupporterFilter ? true : null, */
      },
      client: storefrontClient,
      onCompleted: () => setFirstLoad(true),
    }
  )

  const { allCampaignCategories } = useAllCampaignCategories()

  useEffect(() => {
    if (isLoading) {
      return
    }

    if (!firstLoad) {
      return
    }

    if (!callTypeConstantsCalled) {
      callTypeConstants()
    }
  }, [isLoading, firstLoad])

  useEffect(() => {
    if (params?.country) {
      setSelectedCountry(params?.country)
    }

    if (params?.orderBy && params?.orderBy?.length) {
      const orderData = Array.isArray(params?.orderBy)
        ? params?.orderBy?.join(",")
        : params?.orderBy
      setSelectedSortBy(orderData)
    }
  }, [params?.country])

  useEffect(() => {
    if (
      !isCategoriesFetched &&
      allCampaignCategories &&
      allCampaignCategories?.length
    ) {
      setIsCategoriesFetched(true)

      if (params?.campaignCategories && params?.campaignCategories?.length) {
        const categories = params?.campaignCategories?.split("|")
        setSelectedCategories(categories)
        const categoryIds = allCampaignCategories
          .filter((t) => categories.indexOf(t.slug) !== -1)
          .map((t) => t.id)

        setSelectedCategoryIds(categoryIds)
      }
    }
  }, [allCampaignCategories])

  const changeUrlState = (url) => {
    window?.history?.pushState({}, null, url)
  }

  const selectCategory = (id, slug) => {
    let categoryValue = [...selectedCategories, slug]

    if (selectedCategories.indexOf(slug) > -1) {
      categoryValue = selectedCategories.filter((c) => c !== slug)
      setSelectedCategories(categoryValue)
    } else setSelectedCategories([...selectedCategories, slug])

    if (categoryValue?.length) {
      delete params?.campaignCategories

      const parsedData = categoryValue?.join("|")

      navigateWithParams({
        ...params,
        campaignCategories: parsedData,
      })

      const categoryIds = allCampaignCategories
        .filter((t) => categoryValue.indexOf(t.slug) !== -1)
        .map((t) => t.id)

      setSelectedCategoryIds(categoryIds)
    } else {
      delete params?.campaignCategories

      navigateWithParams({
        ...params,
      })

      setSelectedCategoryIds([])
    }
  }

  const selectCountry = (countryShortCode) => {
    const countryCode = countryShortCode?.value || countryShortCode?.id

    navigateWithParams({
      ...params,
      country: countryCode,
    })
    setSelectedCountry(countryCode)
  }

  const setSorting = (orderBy) => {
    navigateWithParams({
      ...params,
      orderBy: orderBy?.value,
    })
    setSelectedSortBy(orderBy?.value)
  }

  const navigateWithParams = (newParams) => {
    const updatedParams = queryString.stringify(
      { ...newParams },
      { arrayFormat: "comma" }
    )
    changeUrlState("?" + updatedParams)
  }

  const handleLoadMore = () => {
    if (loading) return // Prevent multiple fetches

    fetchMore({
      variables: {
        ...params,
        orderBy: selectedSortBy,
        country: selectedCountry,
        categories: selectedCategoryIds,
        after: data?.allCampaigns?.pageInfo?.endCursor,
        isPublic: true,
        search: searchText,
        campaignOrigin: isOnlyCorporateFilter ? "brand" : null,
        fundraiserType: [
          isCharitiesFilter ? "charities" : null,
          isCampaignsFilter ? "campaigns" : null,
          isEventsFilter ? "events" : null,
        ].filter((item) => item),
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev

        const newEdges = fetchMoreResult?.allCampaigns?.edges ?? []
        const pageInfo = fetchMoreResult?.allCampaigns?.pageInfo

        const prevEdges = prev.allCampaigns?.edges || []

        // Ensure no duplicates are added
        const combinedEdges = [...prevEdges, ...newEdges].reduce(
          (acc, edge) => {
            if (!acc.find((item) => item.node.id === edge.node.id)) {
              acc.push(edge)
            }
            return acc
          },
          []
        )

        return newEdges?.length
          ? {
              // Put the new allCampaigns at the end of the list and update `pageInfo`
              // so we have the new `endCursor` and `hasNextPage` values
              allCampaigns: {
                __typename: prev.allCampaigns.__typename,
                edges: combinedEdges,
                pageInfo,
              },
            }
          : prev
      },
    })
  }

  const handleShowAll = () => {
    delete params?.campaignCategories
    delete params.offerCategories
    setSelectedCategories([])
    setSelectedCategoryIds([])
    navigateWithParams({ ...params })
  }

  const handeListType = (listType) => {
    setListType(listType)
    navigateWithParams({
      ...params,
      view: listType,
    })
  }

  const handleTypeChange = (newParam) => {
    navigateWithParams({
      ...params,
      ...newParam,
    })
  }

  const handleSearch = (e) => {
    setSearchText(e.target.value)
  }

  //Initialize the intersection observer API
  useEffect(() => {
    if (!loadRef?.current) {
      return
    }
    let options = {
      threshold: 1,
    }
    const observer = new IntersectionObserver(handleObserver, options)
    if (loadRef.current) {
      observer.observe(loadRef.current)
    }
  }, [loadRef?.current, data?.allCampaigns?.pageInfo])

  // Handle intersection with load more
  const handleObserver = (entities) => {
    const target = entities[0]
    if (target.isIntersecting) {
      setLoadMoreAfter(true)
    }
  }

  // Handle loading more
  useEffect(() => {
    if (loadMoreAfter && data?.allCampaigns?.pageInfo?.hasNextPage) {
      handleLoadMore()
      setLoadMoreAfter(false)
    }
  }, [loadMoreAfter, data?.allCampaigns?.pageInfo?.hasNextPage, loading])

  return (
    <>
      <SEO
        lang={locale}
        title={formatMessage({
          id: "campaignListing::campaignsTitle",
          defaultMessage: "Browse Charities and Donate",
        })}
        description={formatMessage({
          id: "seo::campaignListing::description",
          defaultMessage:
            "Discover the various charities on the world's only rewarding donation platform.",
        })}
      />
      <StyledCampaignsWrapper id="campaigns" ref={ref}>
        <StyledHeading>
          <div>
            <StyledHeadingTop>
              <FormattedMessage
                id="campaignListing::campaignsTitle"
                defaultMessage="Campaigns"
                tagName="h3"
              />
              <Link to={`/join/ngo/`} aria-label="non-profit">
                <FormattedMessage
                  id="campaignListing::joinAsNonProfit"
                  defaultMessage="JOIN AS NON PROFIT"
                  tagName="h5"
                />
              </Link>
            </StyledHeadingTop>
            <StyledFilterBar>
              <FilterBar
                handleSort={setSorting}
                handleSelectCountry={selectCountry}
                selectedCountry={selectedCountry}
                handleListType={handeListType}
                sortOptions={sortingTypes}
                defaultSortValue={selectedSortBy}
                handleSearch={handleSearch}
                categories={allCampaignCategories}
                selected={selectedCategories}
                onSelect={selectCategory}
                handleShowAll={handleShowAll}
                ref={filterBarRef}
                isOnlyPACFilter={isOnlyPACFilter}
                setOnlyPACFilter={setOnlyPACFilter}
                isSupporterFilter={isSupporterFilter}
                setIsSupporterFilter={setIsSupporterFilter}
                isOnlyCorporateFilter={isOnlyCorporateFilter}
                setIsOnlyCorporateFilter={setIsOnlyCorporateFilter}
                setEventsFilter={setEventsFilter}
                isEventsFilter={isEventsFilter}
                selectedCategories={selectedCategories}
                setSelectedCategories={setSelectedCategories}
                isCharitiesFilter={isCharitiesFilter}
                setCharitiesFilter={setCharitiesFilter}
                isCampaignsFilter={isCampaignsFilter}
                setCampaignsFilter={setCampaignsFilter}
                handleTypeChange={handleTypeChange}
                searchFieldName="allCampaigns"
                isDonationPage={true}
                filters={{
                  sort: true,
                  search: true,
                  listType: true,
                  country: true,
                }}
              />
            </StyledFilterBar>
          </div>
        </StyledHeading>
        <StyledCampaignsBody>
          <div>
            <StyledListingFilters>
              <FormattedMessage
                id="campaignListing::filters::fundraiserType"
                defaultMessage="Fundraiser Type"
                tagName="p"
              />
              <div>
                <Checkbox
                  className="filter-checkbox charities"
                  label={
                    <FormattedMessage
                      id="campaignListing::filters::charities"
                      defaultMessage="Charities"
                    />
                  }
                  isChecked={isCharitiesFilter}
                  onChange={() => {
                    handleTypeChange({ charity: !isCharitiesFilter })
                    setCharitiesFilter(!isCharitiesFilter)
                  }}
                />
                <Checkbox
                  className="filter-checkbox"
                  label={
                    <FormattedMessage
                      id="campaignListing::filters::campaigns"
                      defaultMessage="Campaigns"
                    />
                  }
                  isChecked={isCampaignsFilter}
                  onChange={() => {
                    handleTypeChange({
                      campaign: !isCampaignsFilter,
                      supporter: !isCampaignsFilter ? true : false,
                    })
                    setCampaignsFilter(!isCampaignsFilter)
                    setIsSupporterFilter(!isCampaignsFilter ? true : false)
                  }}
                />
                <Checkbox
                  className="filter-checkbox"
                  label={
                    <FormattedMessage
                      id="campaignListing::filters::events"
                      defaultMessage="Events"
                    />
                  }
                  isChecked={isEventsFilter}
                  onChange={() => {
                    handleTypeChange({ event: !isEventsFilter })
                    setEventsFilter(!isEventsFilter)
                  }}
                />
              </div>
              <StyledOnlyPacsToggle
                className="first"
                onClick={() => {
                  handleTypeChange({
                    corporate: !isOnlyCorporateFilter,
                    supporter: !isOnlyCorporateFilter || isSupporterFilter,
                    campaign: !isOnlyCorporateFilter || isCampaignsFilter,
                  })
                  setIsOnlyCorporateFilter(!isOnlyCorporateFilter)
                }}>
                <FormattedMessage
                  id="campaignListing::filters::showOnlyCorporate"
                  defaultMessage="Only Corporate Campaigns"
                />
                <CommonToggle controlled checked={isOnlyCorporateFilter} />
              </StyledOnlyPacsToggle>

              <StyledOnlyPacsToggle
                className="second"
                onClick={() => {
                  handleTypeChange({
                    supporter: !isSupporterFilter,
                    campaign: !isSupporterFilter || isCampaignsFilter,
                  })
                  setIsSupporterFilter(!isSupporterFilter)
                }}>
                <FormattedMessage
                  id="campaignListing::filters::showSupporter"
                  defaultMessage="Supporters campaigns"
                />
                <CommonToggle
                  disabled={isOnlyCorporateFilter}
                  controlled
                  checked={isSupporterFilter}
                />
              </StyledOnlyPacsToggle>
              {/* These codes will be activated when requested.
               <StyledOnlyPacsToggle
                className="first"
                onClick={() => {
                  handleTypeChange({ storeSupporter: !isStoreSupporterFilter })
                  setIsStoreSupporterFilter(!isStoreSupporterFilter)
                }}>
                <FormattedMessage
                  id="campaignListing::filters::showStoreSupporter"
                  defaultMessage="Show corporate supporter campaigns"
                />
                <CommonToggle controlled checked={isStoreSupporterFilter} />
              </StyledOnlyPacsToggle> */}
              <StyledOnlyPacsToggle
                onClick={() => {
                  handleTypeChange({
                    pacs: !isOnlyPACFilter,
                    campaign: !isOnlyPACFilter || isCampaignsFilter,
                  })
                  setOnlyPACFilter(!isOnlyPACFilter)
                }}>
                <FormattedMessage
                  id="campaignListing::filters::onlyPacs"
                  defaultMessage="Only double PACs campaigns"
                />
                <CommonToggle controlled checked={isOnlyPACFilter} />
              </StyledOnlyPacsToggle>
              <FormattedMessage
                id="listing::filters::category"
                defaultMessage="Category"
                tagName="p"
              />
              <div
                data-testid="explore-campaigns__category-list"
                className="category-filters">
                <ShowAllButton
                  active={!selectedCategories?.length}
                  color="black"
                  onClick={handleShowAll}
                  variant="text">
                  <FormattedMessage
                    id="campaignListing::filters::showall"
                    defaultMessage="Show all"
                  />
                </ShowAllButton>
                <CategoryList
                  categories={allCampaignCategories}
                  selected={selectedCategories}
                  onSelect={selectCategory}
                />
              </div>
            </StyledListingFilters>
            <StyledContent>
              {loading ? (
                <Spinner />
              ) : (
                <StyledCampaignList>
                  {data?.allCampaigns?.edges.length ? (
                    <>
                      {data?.allCampaigns?.edges?.map(({ node: campaign }) => {
                        return (
                          <NewCard
                            variant={
                              campaign?.isDefaultCampaign
                                ? "charity"
                                : "campaign"
                            }
                            key={campaign?.id}
                            id={campaign?.id}
                            imageSrc={campaign?.image?.url}
                            title={getValueForLocale(
                              campaign,
                              "name",
                              locale,
                              defaultLocale
                            )}
                            type={campaign?.campaignType}
                            totalDonors={campaign?.donationCount}
                            totalSupport={campaign?.fundedAmount}
                            size={!isDesktop ? LIST_TYPES.LIST : listType}
                            cardLink={
                              campaign?.isDefaultCampaign
                                ? `/charities/${campaign?.partner?.slug}`
                                : `/campaigns/${campaign?.slug}`
                            }
                            goalAmount={campaign?.goalAmount}
                            progress={campaign?.progress}
                            fundedAmount={campaign?.fundedAmount}
                            partnerLogo={getValueForLocale(
                              campaign?.partner,
                              "logo",
                              locale,
                              defaultLocale
                            )}
                            storeLogo={getValueForLocale(
                              campaign?.store,
                              "logo",
                              locale,
                              defaultLocale
                            )}
                            partnerName={
                              campaign?.partner?.displayName ||
                              campaign?.partner?.name
                            }
                            taxId={campaign?.partner?.taxId}
                            pacMultiplier={campaign?.pacMultiplier}
                            isStoreSupporter={
                              campaign?.store?.id ? true : false
                            }
                            isCorporate={
                              campaign?.store?.defaultOffer?.offerType ===
                              OFFER_TYPE.BRAND
                            }
                          />
                        )
                      })}
                    </>
                  ) : (
                    <NoResults>
                      <FormattedMessage
                        id="campaignListing::filters::noResult"
                        defaultMessage="We couldn’t find any results ("
                        tagName="h6"
                      />
                      <FormattedMessage
                        id="campaignListing::filters::tryFilters"
                        defaultMessage="Try to change filters"
                        tagName="p"
                      />
                    </NoResults>
                  )}
                </StyledCampaignList>
              )}
              <CampaignListAction ref={loadRef}>
                {data?.allCampaigns?.pageInfo?.hasNextPage && (
                  <Spinner condensed />
                )}
              </CampaignListAction>
            </StyledContent>
          </div>
        </StyledCampaignsBody>
      </StyledCampaignsWrapper>
    </>
  )
}

export default CampaignListing
