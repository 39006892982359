import styled from "@emotion/styled"
import { Link } from "gatsby-plugin-intl"
import { Button, Accordion } from "@tmu/components/common"

export const StyledHeaderWrapper = styled.header`
  .white-text {
    &,
    button,
    span,
    a {
      color: ${(p) => p.theme?.colors?.white} !important;
    }
  }
  .navbar-typeahead-not-logged {
    @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
      margin-left: 9.5rem !important;
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
      margin-left: inherit;
    }
  }

  .navbar-typeahead {
    width: 100vw;
    margin-left: -1rem;
    @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
      margin-left: 11.312rem;
      width: auto;
    }
    @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
      margin-left: 12.812rem;
      width: auto;
    }
    @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
      margin-left: 16.7rem;
    }
  }
  .overlay-dashboard {
    background-color: transparent;
    width: 0;
    height: 0;
  }

  @keyframes fade {
    from {
      opacity: 0;
      visibility: hidden;
      transform: translateY(0.625rem);
    }
    to {
      opacity: 1;
      visibility: visible;
      transform: translateY(0);
    }
  }

  @media only screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    max-width: ${(p) => p.theme.breakpoints.desktop};
  }
  @media only screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    max-width: ${(p) => p.theme.breakpoints.wide};
    .navbar-logo {
      margin-top: 0.4rem;
    }
  }
  .navbar-logo-container {
    width: 120%;
  }
  @media only screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    max-width: ${(p) => p.theme.breakpoints.wide};

    .navbar-logo-container {
      width: auto;
      margin-top: 0.3rem;
    }
  }

  @media only screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    .navbar-logo-container {
      margin-top: 0.5rem;
    }
  }
  .navbar {
    @media only screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
      height: 5rem;
    }
    z-index: 9998;
    height: 3rem;
    margin: auto;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100vw;
    transition: transform 0.35s, background-color 0.35s;
    background-color: ${(p) => p.theme.colors.white};
    color: ${(p) => p.theme.colors.primaryTextColor};
    -webkit-user-select: none; /* Safari 3.1+ */
    -moz-user-select: none; /* Firefox 2+ */
    -ms-user-select: none; /* IE 10+ */
    user-select: none; /* Standard syntax */
    box-shadow: 0 0 1.25rem -0.75rem rgba(0, 0, 0, 0.54);

    &.hidden {
      transform: translateY(-110%);
    }
    &.transparent {
      background-color: transparent;
      color: ${(p) => p.theme.colors.pitchBlack};
      box-shadow: none;

      button {
        color: ${(p) => p.theme.colors.pitchBlack};

        &:hover,
        &:active,
        &.active {
          color: ${(p) => p.theme.colors.pitchBlack};
        }
        &.has-dropdown:hover,
        &.has-dropdown:active,
        &.has-dropdown.active {
          color: black;
        }
      }
      .react-select__control {
        .languages-header {
          img {
            margin-right: 0.625rem;
          }
        }
      }

      .react-select__input {
        color: ${(p) => p.theme.colors.pitchBlack};
        padding-bottom: 0.0625rem;
      }
      .react-select__option {
        .languages-header {
          img {
            margin-right: 0.625rem;
          }
        }
      }

      .digital-banking {
        .react-select__placeholder,
        .react-select__single-value {
          color: ${(p) => p.theme.colors.white};
          padding-bottom: 0.0625rem;
        }
      }
    }
    .digital-banking {
      .react-select__placeholder,
      .react-select__single-value {
        color: ${(p) => p.theme.colors.black};
        position: absolute;
        text-overflow: initial;
        padding-bottom: 0.0625rem;
      }
    }
    ul {
      gap: 0.5rem;
      height: 100%;
      @media only screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
        gap: 1rem;
      }
      display: inline-flex;
      justify-content: space-between;
      align-items: center;
      li {
        display: flex;
        height: inherit;
        align-items: center;
        justify-content: center;
        font-family: ${(p) => p.theme.fontFamilies.regular};
        font-size: 1rem;
        line-height: 1rem;
        @media only screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
          font-size: 1.5rem !important;
          line-height: 2.25rem !important;
        }
        a {
          text-decoration: none;
          color: inherit;
        }
      }
    }

    .navbar__search {
      width: 5rem;
      justify-content: end;
      @media only screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
        justify-content: center;
        width: 2.5rem;
      }

      @media only screen and (min-width: ${(p) =>
          p.theme.breakpoints.desktop}) {
        justify-content: center;
        width: 5rem;
      }
    }

    .navbar__bars {
      float: right;
      margin-right: 0.5rem;
      justify-self: start;
      padding-left: 0;

      svg {
        max-width: 1.3125rem;
      }
      @media only screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
        justify-self: center;
      }

      @media only screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
        font-size: 1.5rem;
      }
      &:hover,
      &:focus {
        background-color: transparent;
        font-size: 1rem;
        @media only screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
          font-size: 1.5rem;
        }
      }
      &.transparent {
        background-color: transparent;
        color: ${(p) => p.theme.colors.pitchBlack};
        font-size: 1rem;
        @media only screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
          font-size: 1.5rem;
        }
      }
    }
    .dropdown-content {
      animation: fade 0.35s linear forwards;
    }
    .navbar__hiw,
    .navbar__store,
    .navbar__explore,
    .navbar__login {
      @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
        margin-right: 1.9rem;
      }

      @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
      }

      /* TODO: button style will be updated when a new guideline for menu buttons is prepared */
      button {
        color: ${(p) => p.theme.colors.pitchBlack};
        background: transparent;
        font-family: ${(p) => p.theme.fontFamilies.regular};
        font-size: 1rem;
        @media only screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
          font-size: 1.5rem !important;
          line-height: 2.25rem !important;
        }
        margin: 0;
        text-align: left;
        border-radius: 0;
        cursor: pointer;
        border: none;
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
        text-transform: none;
        font-weight: ${(p) => p.theme.fontWeights.regular};
        letter-spacing: normal;
      }
    }
    .navbar__bars,
    .navbar__pac button,
    .navbar__pac span,
    .navbar__search button {
      color: ${(p) => p.theme.colors.pitchBlack};
    }
    &.transparent {
      .navbar__hiw,
      .navbar__store,
      .navbar__explore,
      .navbar__login {
        /* TODO: button style will be updated when a new guideline for menu buttons is prepared */
        button {
          color: ${(p) => p.theme.colors.pitchBlack};
        }
      }
      .navbar__bars,
      .navbar__pac span,
      .navbar__search button {
        color: ${(p) => p.theme.colors.pitchBlack};
      }
    }

    .navbar__explore {
      position: relative;

      svg,
      i {
        margin-left: 0.625rem;
      }
      &.dropdown-content {
        display: none;
        position: absolute;
        top: 5rem;
        left: 0;
        background-color: ${(p) => p.theme.colors.white};
        box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.2);
        color: ${(p) => p.theme.colors.primaryTextColor};
        z-index: 1;

        a {
          text-decoration: none;
          line-height: 2.3125rem;
          color: ${(p) => p.theme.colors.primarySubmenuColor};
          font-family: ${(p) => p.theme.fontFamilies.regular};
          font-size: 1rem;
          @media only screen and (min-width: ${(p) =>
              p.theme.breakpoints.wide}) {
            font-size: 1.5rem !important;
            line-height: 2.25rem !important;
          }
          width: 100%;

          &:hover {
            color: ${(p) => p.theme.colors.blue};
          }
        }

        a,
        button {
          color: ${(p) => p.theme.colors.pitchBlack};
          font-family: ${(p) => p.theme.fontFamilies.regular};
          font-size: 1rem;
          @media only screen and (min-width: ${(p) =>
              p.theme.breakpoints.wide}) {
            font-size: 1.5rem !important;
            line-height: 2.25rem !important;
          }
          margin: 0;
          text-align: left;
          border-radius: 0;
          cursor: pointer;

          &:hover,
          &:active,
          &.active {
            color: ${(p) => p.theme.colors.blue};
            background: ${(p) => p.theme.colors.pitchBlack} !important;
          }
          &.has-dropdown:hover,
          &.has-dropdown:active,
          &.has-dropdown.active {
            color: black;
          }
        }
      }

      /*  
      // TODO: Will show with new explore campaign dropdown
     &:hover,
      &:focus {
        background-color: ${(p) => p.theme.colors.white};

        button {
          color: ${(p) => p.theme.colors.pitchBlack};
        }

        .navbar__explore.dropdown-content {
          background-color: transparent;
          box-shadow: none;

          display: block;
          width: 100%;
          left: 0;
          position: fixed;

          a,
          button {
            padding: 0.625rem 0.5rem;
          }
        }
      } */
    }

    .navbar__hiw {
      position: relative;
      border-right: 0.0625rem solid rgba(255, 255, 255, 0.15);
      padding-right: 0.9rem;
      margin-right: 0.775rem;
    }

    .navbar__signup {
      min-width: 6.8125rem;
      text-align: center;
    }

    .navbar__pac {
      a {
        display: flex;
        align-items: center;
      }
      .pac-icon {
        margin-right: 0.5rem;
      }
      span {
        color: inherit;
        font-size: 1rem;
        @media only screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
          font-size: 1.5rem !important;
          line-height: 2.25rem !important;
        }
        line-height: 1rem;
      }

      &.pulsate-fwd {
        -webkit-animation: pulsate-fwd 0.25s ease-in-out 3 both;
        animation: pulsate-fwd 0.25s ease-in-out 3 both;
      }

      @-webkit-keyframes pulsate-fwd {
        0% {
          -webkit-transform: scale(1);
          transform: scale(1);
        }
        50% {
          -webkit-transform: scale(1.1);
          transform: scale(1.1);
        }
        100% {
          -webkit-transform: scale(1);
          transform: scale(1);
        }
      }
      @keyframes pulsate-fwd {
        0% {
          -webkit-transform: scale(1);
          transform: scale(1);
        }
        50% {
          -webkit-transform: scale(1.1);
          transform: scale(1.1);
        }
        100% {
          -webkit-transform: scale(1);
          transform: scale(1);
        }
      }
    }

    .navbar__cart {
      position: relative;
      height: 1rem;
      width: 1.4375rem;
      color: inherit;
      font-size: 1.25rem;
      line-height: 1rem;
      margin: 0 1rem;

      .cart-badge {
        position: absolute;
        font-size: 0.75rem;
        top: -0.5rem;
        right: -0.5rem;
        border-radius: 50%;
        color: ${(p) => p.theme.colors.white};
        background-color: ${(p) => p.theme.colors.green};
        width: 0.8rem;
        height: 0.8rem;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0.6rem;
        overflow: hidden;
      }
    }
    .navbar__user {
      cursor: pointer;
      position: relative;
      padding: 1.3125rem;

      p {
        color: inherit;
        font-size: 1rem;
        @media only screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
          font-size: 1.5rem !important;
          line-height: 2.25rem !important;
        }
        line-height: 1rem;
        margin: 0 0.625rem;
      }

      &.dropdown-content {
        position: absolute;
        top: 5rem;
        right: 0;
        width: 12.375rem;
        padding-top: 1.25rem;
        text-align: right;
        background-color: ${(p) => p.theme.colors.white};
        box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.2);
        z-index: 1;
        display: none;
        flex-direction: column;
        align-content: flex-start;

        &::before {
          content: "";
          position: absolute;
          display: block;
          width: 9.625rem;
          height: 3.125rem;
          background-color: transparent;
          top: -1.5rem;
          left: 0;
        }
        &::after {
          content: "";
          position: absolute;
          display: block;
          width: 1.25rem;
          height: 3.125rem;
          background-color: transparent;
          top: -4.5625rem;
          left: 6.4375rem;
        }
        button {
          color: ${(p) => p.theme.colors.pitchBlack};
          font-size: 1rem;
          @media only screen and (min-width: ${(p) =>
              p.theme.breakpoints.wide}) {
            font-size: 1.5rem !important;
            line-height: 2.25rem !important;
          }
          margin: 0;

          &:hover {
            color: ${(p) => p.theme.colors.pitchBlack};
            background: ${(p) => p.theme.colors.pitchBlack};
          }
        }

        p {
          font-family: ${(p) => p.theme.fontFamilies.headline};
          font-size: 1.125rem;
          line-height: 1.4375rem;
          margin-bottom: 0.9375rem;
        }

        a {
          text-decoration: none;
          display: block;
          line-height: 2.3125rem;
          color: ${(p) => p.theme.colors.primarySubmenuColor};
          font-family: ${(p) => p.theme.fontFamilies.regular};
          font-size: 1rem;
          @media only screen and (min-width: ${(p) =>
              p.theme.breakpoints.wide}) {
            font-size: 1.5rem !important;
            line-height: 2.25rem !important;
          }
          padding-right: 1.125rem;
          text-align: start;
          padding-left: 0.5rem;
          white-space: nowrap;

          &:hover {
            background: ${(p) => p.theme.colors.pitchBlack};
            color: ${(p) => p.theme.colors.pitchBlack};
          }
        }
      }
      &:hover {
        box-shadow: 0.625rem 0.625rem 1.875rem 0 rgba(0, 0, 0, 0.15);
        background-color: ${(p) => p.theme.colors.white};
        color: ${(p) => p.theme.colors.primaryTextColor};
        .dropdown-content {
          display: block;
          padding-bottom: 0.75rem;
        }
      }
    }
    .react-select__control,
    .react-select__option {
      cursor: pointer;
    }
  }

  .languages-header {
    display: flex;
    .media-box {
      padding-bottom: 1.25rem;
      padding-right: 0.625rem;
    }
    img {
      margin-right: 0.625rem;
      @media only screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
        margin-top: 0.5rem;
      }
    }
  }
`

export const StyledNavbarContent = styled.div`
  margin: auto;
  height: 100%;
  ul {
    width: 100%;
  }
  padding: 0 0.9rem 0 0.9rem;
  @media only screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    ul {
      width: auto;
    }
    max-width: ${(p) => p.theme.breakpoints.tablet};
    padding: 0 1.3rem 0 1.3rem;
  }

  @media only screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    max-width: ${(p) => p.theme.breakpoints.desktop};
    padding: 0 1.9rem 0 1.9rem;
  }

  @media only screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    max-width: ${(p) => p.theme.breakpoints.wide};
    margin-top: 0;
    padding: 0 5.9rem 0 5.9rem;
  }

  button {
    padding: 0;
    svg {
      margin-left: 0;
      margin-right: 0;
    }
  }
`
export const StyledMenuOverlay = styled.div`
  z-index: 9997;
  position: fixed;
  width: 100vw;
  height: 200vh;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  justify-content: start;

  background-color: rgba(0, 0, 0, 0.5);

  &.hidden {
    transform: translateX(-100%);
  }
`

export const StyledMobileMenu = styled.div`
  ::-webkit-scrollbar {
    width: 0.25rem;
    height: 0;
  }
  ::-webkit-scrollbar-track {
    background: ${(p) => p.theme.colors.transparent};
  }
  ::-webkit-scrollbar-thumb {
    background: ${(p) => p.theme.colors.lightGrey};
    width: 0.25rem;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: ${(p) => p.theme.colors.lightGrey};
  }
  overscroll-behavior: none;
  height: 100vh;
  justify-content: start;
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  color: ${(p) => p.theme.colors.primaryTextColor};
  background-color: ${(p) => p.theme.colors.whiteSmoke};

  display: flex;
  padding-right: 2.5rem;

  overflow-y: auto;
  width: 13.438rem;

  .slim {
    padding: 0;
  }
  @media only screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    width: calc(
      13.4375rem + (100vw - ${(p) => p.theme.breakpoints.tablet}) / 2
    );
    padding-left: calc(
      (100vw - ${(p) => p.theme.breakpoints.tablet}) / 2 + 0.5rem
    );
  }
  @media only screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    width: calc(
      13.4375rem + (100vw - ${(p) => p.theme.breakpoints.desktop}) / 2
    );
    padding-left: calc(
      (100vw - ${(p) => p.theme.breakpoints.desktop}) / 2 + 1rem
    );
  }
  @media only screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    width: calc(28.8125rem + (100vw - ${(p) => p.theme.breakpoints.wide}) / 2);
    padding-left: calc((100vw - ${(p) => p.theme.breakpoints.wide}) / 2 + 5rem);
  }
  transition: transform 4.5s ease;
  transform: translateX(0);
  ul {
    flex-direction: column;
    li {
      flex: 1 0 4rem;
    }
  }

  .left-arrow {
    display: block;
    margin: 3rem 1rem 1rem;
  }
  .mobile-explore-text,
  .mobile-hiw-text {
    font-size: 1.5rem;
    font-weight: ${(p) => p.theme.fontWeights.bold};
    line-height: 1.3125rem;
    margin: 1rem;
    color: black;
  }

  .navbar__start {
    width: auto;
    text-align: left;
    border-right: none;
    button {
      margin: 0;
    }
  }
  .navbar__explore {
    width: auto;
    margin-right: 1.9rem;
  }
  .navbar__hiw,
  .navbar__store,
  .navbar__explore {
    padding: 0;
    border: none;
    border-bottom: 0.0625rem solid rgba(0, 0, 0, 0.15);

    /* TODO: button style will be updated when a new guideline for menu buttons is prepared */
    button {
      color: ${(p) => p.theme.colors.pitchBlack};
      font-family: ${(p) => p.theme.fontFamilies.regular};
      font-size: 1rem;
      @media only screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
        font-size: 1.5rem !important;
        line-height: 2.25rem !important;
      }
      margin: 0;
      text-align: left;
      border-radius: 0;
      cursor: pointer;
      background: transparent;
      border: none;
      display: flex;
      align-items: center;
      text-align: center;
      justify-content: center;
      text-transform: none;
      font-weight: ${(p) => p.theme.fontWeights.regular};
      letter-spacing: normal;
    }
  }
  .navbar__signup {
    width: 50%;
    text-align: left;
    display: inline-block;
  }
  .navbar__login {
    display: inline-block;
  }
  .navbar__explore.dropdown-content,
  .navbar__hiw.dropdown-content {
    display: block;
    position: static;
    min-width: unset;
    padding: 0;
    box-shadow: none;
  }

  hr {
    width: 80%;
    margin-bottom: 1rem;
  }
`

export const StyledNavbarPacAmount = styled(Link)`
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 0.5rem 0.5rem 1.5rem;

  span {
    margin-left: 0.625rem;
    font-size: 1rem;
    @media only screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
      font-size: 1.5rem !important;
      line-height: 2.25rem !important;
    }
    font-weight: ${(p) => p.theme.fontWeights.bold};
  }
`

export const StyledExploreMenu = styled.div`
  border-top: 0.0625rem solid ${(p) => p.theme.colors.gainsboro};
`

export const StyledMobileNavTitle = styled.div`
  padding: 1rem 0.5rem;
`

export const StyledMobileAuthSection = styled.div`
  display: grid;
  grid-template-columns: ${(p) =>
    p.authenticated ? "1fr 0.8fr 1.4fr" : "1fr"};
  align-items: center;
  justify-items: center;
  padding: 0;
  grid-gap: 0.05rem;

  > a:nth-of-type(1),
  .navbar__cart {
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .navbar__cart {
    width: inherit;
    height: inherit;
    margin: 0;
    line-height: inherit;
  }
  .navbar__user {
    font-size: 0.85rem;
    padding: inherit !important;
    margin: 0 auto !important;

    .display-name {
      margin-left: 0.5rem;
    }
  }

  @media only screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    gap: 1rem;
    margin-top: 0.5rem;
  }
  @media only screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    gap: 1.5rem;
    margin-top: 1rem;
  }

  @media only screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    gap: 2rem;
    margin-top: 2rem;
  }
`

export const StyledCloseButton = styled.button`
  color: ${(p) => p.theme.colors.black} !important;
  border: none;
  background: transparent;
  padding: 1rem;
  cursor: pointer;

  svg,
  i {
    font-size: 1.25rem;
  }
`

export const StyledMobileNavLink = styled(Link)`
  display: block;
  text-decoration: none;
  padding: 0.85rem 0.6rem;
  width: 100%;

  color: ${(p) => p.theme.colors[p?.color ?? "black"]};
  font-size: 0.75rem;
  line-height: 1rem;
  font-weight: ${(p) => p.theme.fontWeights.semiBolder};
  @media only screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  svg,
  i {
    margin-right: 0.3125rem;
    margin-left: 0;
    width: 1.3rem;
  }
`
export const StyledGetStartedButton = styled(Button)`
  background-color: ${(p) => p.theme.colors.pacificBlue};
  min-width: 5rem;
  margin-left: 1rem;
  height: 2rem;
  a {
    color: ${(p) => p.theme.colors.white} !important;
  }
  @media only screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    a {
      font-size: 1rem;
      line-height: 1.375rem;
    }
    width: 8.25rem;
    height: 2.5rem;
  }
`

export const StyledLeftGetStartedButton = styled(Button)`
  background-color: ${(p) => p.theme.colors.pacificBlue};
  margin-left: 0;
  min-width: 6rem;
  min-height: 2rem;
  border-radius: 0.25rem;
  a {
    color: ${(p) => p.theme.colors.white} !important;
  }
  @media only screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 1rem;
    line-height: 1.375rem;
    width: 12.063rem;
    height: 2.75rem;
  }
`
export const StyledGetStartedButtonMobile = styled(Button)`
  margin: auto;
  background-color: ${(p) => p.theme.colors.pacificBlue};
  min-width: 6rem;
  min-height: 2rem;
  font-weight: ${(p) => p.theme.fontWeights.semiBolder} !important;
  font-family: ${(p) => p.theme.fontFamilies.headline};
  width: 20.5rem;
  height: 2.5rem;
  a {
    color: ${(p) => p.theme.colors.white} !important;
  }
  @media only screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 1rem;
    line-height: 1.375rem;
    width: 12.063rem;
    height: 2.75rem;
  }
`

export const StyledGetStartedButtonContainerMobile = styled.div``
export const StyledLeftSigninButton = styled(Link)`
  margin-left: 0 !important;
  font-size: 1rem;
  line-height: 1.375rem;
  a {
    color: ${(p) => p.theme.colors.white} !important;
  }

  @media only screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 1.5rem;
    line-height: 2.375rem;
  }
`
export const StyledMobileNavButton = styled.div`
  font-size: 0.75rem;
  line-height: 1.125rem;
  font-weight: ${(p) => p.theme.fontWeights.liteMedium};
  align-self: center;
  cursor: pointer;

  @media only screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 1rem;
    line-height: 1.375rem;
  }

  &.active {
    font-weight: ${(p) => p.theme.fontWeights.semiBold};
  }
`

export const StyledNavbarStart = styled.li`
  text-align: center;
  a {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2.9375rem;
    width: 7.5625rem;
    border-radius: 0.1875rem;
    background: ${(p) => p.theme.colors.blue};
  }
`

export const StyledLogoSearch = styled.ul`
  margin: 0;
  padding: 0;
  list-style-type: none;
  float: left;
  display: block;

  li:nth-of-type(1) {
    flex: 2 1 auto;
  }
`

export const StyledAvatar = styled.span`
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  background-color: ${(p) => p.theme.colors.disabled};
  border-radius: 50%;
  overflow: hidden;
  margin-right: 0.5rem;

  @media only screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    width: 2.5rem;
    height: 2.5rem;
  }

  img {
    width: 100%;
    height: 100%;
    color: ${(p) => p.theme.colors.white};
  }
  .fa-user-alt {
    font-size: 1.2rem;
  }
`

export const DesktopMenuContainer = styled.div`
  height: 100%;
  display: flex;
  justify-content: space-between;
  .header-items {
    gap: 1rem !important;
  }

  &.desktop {
    justify-content: end;
  }
`

export const StyledNavbarLogo = styled.div`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  a {
    img {
      margin-right: 0.625rem;
    }
  }
`

export const StyledFullWidthSubMenu = styled.div`
  box-sizing: content-box;
  border: 0.0625rem solid white;
  max-width: ${(p) => p.theme.breakpoints.wide};
  background-color: ${(p) => p.theme.colors.white};
  box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.2);
  display: grid;
  grid-template-columns: 2fr 0.3fr;
  grid-template-rows: 1fr;
  grid-gap: 1rem;
  padding: 4rem 2.5rem;
  margin: 0 auto;

  > div {
    > h2 {
      margin-bottom: 1rem;
    }
    > p {
      margin-bottom: 2rem;
      font-family: ${(p) => p.theme.fontFamilies.regular};
      font-weight: ${(p) => p.theme.fontWeights.regular};
      color: ${(p) => p.theme.colors.dimGray};
    }
    > nav {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-rows: 1fr 1fr;
      align-items: stretch;
      justify-items: stretch;
      margin-bottom: 1rem;
      grid-gap: 0.0725rem;
      background: ${(p) => p.theme.colors.shadyLady};

      &:after {
        content: " ";
        background: ${(p) => p.theme.colors.pitchBlack};
      }

      a {
        background: ${(p) => p.theme.colors.pitchBlack};
        display: flex;
        align-items: center;
        justify-content: flex-start;
        text-align: left;
        padding: 0.5rem 2rem;

        svg,
        i {
          margin-left: 0.5rem;
          margin-right: 0.5rem;
        }
      }

      button {
        background: ${(p) => p.theme.colors.pitchBlack};
      }
    }
  }

  .call-to-action {
  }
`

export const StyledNavBarUser = styled(Link)`
  display: flex;
  align-items: center;
  .display-name {
    color: ${(p) => p.theme.colors.gondola};
    font-size: 1rem;
    @media only screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
      font-size: 1.5rem !important;
      line-height: 2.25rem !important;
    }
  }

  &:hover {
    box-shadow: none !important;
  }
`

export const StyledMobileNavDasboardLinks = styled.div`
  display: flex;
  flex-direction: column;
`

export const StyledCTAButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  #explore-campaign-see-all {
    color: ${(p) => p.theme.colors.white};
    padding: 0 0.9375rem;
    border-radius: 0.1875rem;

    &:hover {
      background-color: ${(p) => p.theme.colors.red} !important;
    }
  }
`
export const StyledAccordionSubElement = styled.div`
  border-bottom: 0.0625rem solid ${(p) => p.theme.colors.tableItemBorder};
  width: 100%;
  position: absolute;
  padding-left: 1.875rem;
  height: 2.5rem;
  display: flex;
  align-items: center;
`

export const StyledNavBarButton = styled(Button)`
  color: ${(p) => p.theme.colors.primaryBrandTextColor} !important;
`

export const StyledAccordion = styled(Accordion)`
  ${(p) => p.theme.colors.tableItemBorder};
  font-size: 1.125rem;
`

export const StyledDigitalBankingDropdown = styled.div`
  display: flex;
  flex-direction: column;

  padding-top: ${(p) => (p.hasChild ? "1.25rem" : 0)};

  .digital-banking-sub-title {
    font-size: 0.6875rem;
    opacity: 0.5;
    padding-top: 0.3125rem;
  }
  .personal {
    color: ${(p) => p.theme.colors.pacificBlue};
  }
  .business {
    color: ${(p) => p.theme.colors.red};
  }

  .digital-banking-title {
    line-height: normal;
  }
`

export const StyledMobileMenuItem = styled.div`
  font-size: 1.25rem;
  line-height: 1.5rem;
  margin-top: 1rem;
  cursor: pointer;
`
export const StyledMobileMenuItemList = styled.div`
  overflow-y: auto;
  // max-height: calc(1vh * 65);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  text-align: left;
`

export const StyledLine = styled.hr`
  margin: 1rem 0;
  width: auto;
  color: ${(p) => p.theme.colors.darkGrey};

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    margin: 1.5rem 0;
  }
`

export const StyledMobileMenuWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: -3rem;
`

export const StyledLanguageMenuItem = styled.div`
  display: flex;
  justify-content: space-between;

  .language div {
    margin-top: unset !important;
    gap: 0.75rem !important;
  }

  .language .glow img {
    border: none;
  }
`

export const StyledMobileNavBarLogo = styled.div`
  align-self: start;
  width: 8.125rem;
  margin-left: 1rem;
`

export const StyledMobileLanguageBar = styled.div`
  position: absolute;
  bottom: 1.8rem;
  display: flex;
  flex-direction: column;
  left: 0;
  width: 99vw;

  .center {
    margin: auto;
  }

  .language-bar {
    div {
      gap: 2.56rem;
    }
    margin-bottom: 1.5rem;
  }
`
export const StyledLeftMenuItem = styled.div`
  display: flex;
  margin-top: 1rem;

  &:last-child {
    padding-bottom: 2rem;
  }
`

export const StyledLeftMenuItemText = styled.div``
export const StyledLeftMenuUserInfo = styled.div`
  font-size: 0.75rem;
  line-height: 1rem;
  font-weight: ${(p) => p.theme.fontWeights.semiBolder};
  align-self: center;

  @media only screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 1.5rem;
    line-height: 2rem;
  }
`
export const StyledMerchantType = styled.div`
  font-size: 0.5rem;
  margin-top: 0.25rem;
  line-height: 0.75rem;
  letter-spacing: 0.015rem;
  font-weight: ${(p) => p.theme.fontWeights.semiBolder};
  color: ${(p) => p.theme.colors.concreteGrey};
  text-transform: uppercase;
  @media only screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 1rem;
    line-height: 1.5rem;
    margin-top: 0.5rem;
  }
`
export const StyledMerchantsTypeWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const StyledLeftMenuItemList = styled.div`
  margin-top: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: start;
  padding-left: 1rem;

  @media only screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    margin-top: 6rem;
  }
`

export const StyledLeftMenuTextWithIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 9.375rem;
  cursor: ${(p) => (p.isMultipleMerchant ? "pointer" : "auto")};

  & > svg {
    margin-left: 0.5rem;
    height: 1rem;
    width: 1rem;
  }

  @media only screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    width: 8.75rem;
    & > svg {
      margin-left: 0.75rem;
    }
  }

  @media only screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    width: 20rem;
    & > svg {
      height: 2rem;
      width: 2rem;
    }
  }
`

export const StyledTooltip = styled.div`
  position: relative;
  display: inline-block;
  z-index: 999;
  width: 100%;
  box-shadow: none;
  .tooltiptext {
    width: 5.375rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${(p) => p.theme.colors.white};
    color: ${(p) => p.theme.colors.pitchBlack};
    text-align: center;
    border-radius: 0.375rem;
    line-height: 1rem;
    font-size: 0.625rem;
    height: 2rem;
    position: absolute;
    bottom: 175%;
    left: 50%;
    margin-left: -0.3125rem;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s;
    box-shadow: 0 0 0.75rem ${(p) => p.theme.colors.shadowConcreteGrey};
  }

  .tooltipselect {
    width: 22.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${(p) => p.theme.colors.white};
    color: ${(p) => p.theme.colors.pitchBlack};
    text-align: center;
    border-radius: 0.375rem;
    line-height: 1.25rem;
    font-size: 0.75rem;
    position: absolute;
    bottom: 175%;
    margin-left: -2.875rem;
    left: 50%;
    opacity: 1;
    transition: opacity 0.3s;
    box-shadow: 0 0 0.75rem ${(p) => p.theme.colors.shadowConcreteGrey};
  }

  @media only screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    .tooltipselect,
    .tooltiptext {
      bottom: auto;
      top: 125%;
    }
  }

  @media only screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    .tooltipselect {
      width: 30rem;
      line-height: 1.25rem;
      margin-left: -5.875rem;
    }

    .tooltiptext {
      font-size: 0.75rem;
      width: 6.75rem;
      height: 2.75rem;
      line-height: 1.25rem;
      margin-left: 2.3125rem;
    }
  }

  &:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
  }
`

export const StyledMerchantsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;

  @media only screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    width: 40rem;
  }

  @media only screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    width: 55rem;
  }
`

export const StyledMerchantOptions = styled.div`
  @media only screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    height: auto;
    max-height: 15.75rem;
    overflow: auto;
  }

  @media only screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    max-height: 22.5rem;
  }
`

export const StyledSelectedMerchant = styled.div`
  display: flex;
  align-items: center;
  height: auto;
  border-bottom: 0.00390625rem solid ${(p) => p.theme.colors.lightGrey};
  // margin-bottom: 0.3125rem;

  @media only screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    height: 8rem;
    padding: 0 2rem;
  }

  @media only screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    height: 11rem;
    padding: 0 2.5rem;
  }
`

export const StyledSelectedMerchantTitle = styled.div`
  color: ${(p) => p.theme.colors.pitchBlack};
  font-family: ${(p) => p.theme.fontFamilies.headline};
  font-weight: ${(p) => p.theme.fontWeights.semiBolder};
  flex: 1;
  font-size: 0.75rem;
  line-height: 1.25rem;
  text-align: left;

  @media only screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    font-size: 1.5rem;
    line-height: 2.125rem;
  }

  @media only screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 2rem;
    line-height: 2.75rem;
  }
`

export const StyledMerchant = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover {
    background-color: ${(p) => p.theme.colors.whiteSmoke};
  }

  @media only screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    height: 5.25rem;
    padding: 0 2rem;
    border-bottom: 0.00390625rem solid ${(p) => p.theme.colors.lightGrey};
    &:last-child {
      margin-bottom: 2rem;
    }
  }

  @media only screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    height: 7.5rem;
    padding: 0 2.5rem;
    &:last-child {
      margin-bottom: 2.5rem;
    }
  }
`

export const StyledMerchantIcon = styled.div`
  display: flex;
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 0.3125rem;
  background-color: ${(p) => p.theme.colors.pitchBlack};
  color: ${(p) => p.theme.colors.white};
  align-items: center;
  justify-content: center;
  border-radius: 50%;

  & > img {
    border-radius: 50%;
  }

  @media only screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    width: 2.5rem;
    height: 2.5rem;
    margin-right: 0.75rem;
  }

  @media only screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    width: 4rem;
    height: 4rem;
    margin-right: 1rem;
  }
`
export const StyledSelectedMerchantIcon = styled.div`
  display: flex;
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 0.3125rem;
  background-color: ${(p) => p.theme.colors.pitchBlack};
  color: ${(p) => p.theme.colors.white};
  align-items: center;
  justify-content: center;
  border-radius: 50%;

  & > img {
    border-radius: 50%;
  }

  @media only screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    width: 4rem;
    height: 4rem;
    margin-right: 1rem;
  }

  @media only screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    width: 6rem;
    height: 6rem;
    margin-right: 1rem;
  }
`

export const StyledMerchantTitle = styled.div`
  color: ${(p) => p.theme.colors.pitchBlack};
  flex: 1;
  line-height: 1.25rem;
  text-align: left;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  font-size: 0.75rem;

  @media only screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  @media only screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 1.5rem;
    line-height: 2.25rem;
  }
`

export const StyledMerchantStatus = styled.div`
  font-size: 0.525rem;
  font-family: ${(p) => p.theme.fontFamilies.headline};
  font-weight: ${(p) => p.theme.fontWeights.semiBolder};
  letter-spacing: 0.01875rem;
  line-height: 1rem;
  text-transform: uppercase;
  color: ${(p) => p.theme.colors.concreteGrey};
  text-align: left;

  @media only screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    font-size: 0.625rem;
    line-height: 1rem; /* 160% */
    letter-spacing: 0.0187rem;
  }

  @media only screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 1rem;
    line-height: 1.5rem;
    letter-spacing: 0.02rem;
  }
`
export const StyledGlobalSpinner = styled.div`
  position: absolute;
  top: 30%;
  left: 50%;
`

export const StyledCloseIcon = styled.div`
  position: absolute;
  cursor: pointer;
  display: flex;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    right: 2rem;
    top: 2rem;
    svg {
      height: 1.5rem;
      width: 1.5rem;
    }
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    right: 2.5rem;
    top: 2.5rem;
    svg {
      height: 2.5rem;
      width: 2.5rem;
    }
  }
`
