import styled from "@emotion/styled"
import { Tab, TabPanel } from "react-tabs"

export const StyledTab = styled(Tab)`
  display: inline-block;
  color: ${(p) => p.theme.colors.primaryBrandTextColor};
  font-family: ${(p) => p.theme.fontFamilies.headline};
  font-weight: ${(p) => p.theme.fontWeights.bold};
  font-size: 1.625rem;
  opacity: 0.4;
  outline: none;
  cursor: pointer;
  padding-right: 1rem;
  transition: all 0.3s ease 0s;
  background: none;
  border: none;
  margin-bottom: 1.875rem;

  &.react-tabs__tab--selected {
    opacity: 1;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    padding-right: 2.25rem;
  }
`

export const StyledTabPanel = styled(TabPanel)`
  white-space: pre-line;
`
