import React, {
  Fragment,
  useEffect,
  useState,
  useCallback,
  useContext,
} from "react"
import PropTypes from "prop-types"
import { useQuery, useLazyQuery } from "@apollo/client"
import { FormattedMessage, useIntl, navigate } from "gatsby-plugin-intl"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faHeart } from "@fortawesome/pro-solid-svg-icons/faHeart"
import { faHeart as falHeart } from "@fortawesome/pro-light-svg-icons/faHeart"
import { faPlus } from "@fortawesome/pro-solid-svg-icons/faPlus"
import { faUser } from "@fortawesome/pro-solid-svg-icons/faUser"
import { es, it, en } from "date-fns/locale"
import { faGem } from "@fortawesome/pro-solid-svg-icons/faGem"
import { Tooltip } from "react-tooltip"
import {
  Spinner,
  LazyImage,
  Money,
  ShareComponent,
  Stats,
  DetailHeader,
  Button,
  DonorListModal,
} from "@tmu/components/common"

import { useAuth, useFooterType, useUserPreferences } from "@tmu/hooks"
import SEO from "@tmu/components/seo"
import ContentNotFound from "@tmu/components/ContentNotFound"
import { useApolloApiClients } from "@tmu/apollo/client"
import {
  StyledSectionTitle,
  StyledWrapper,
  StyledContent,
  StyledPartnerDescription,
  StyledFavoriteNGOButton,
  StyledFiscalCode,
  StyledLogo,
  StyledLine,
  StyledCampaignsWrapper,
  StyledSideCampaignsTitle,
  StyledCreateButton,
  StyledSocialLink,
  StyledCampaignName,
  StyledCampaignListWrapper,
  StyledHeaderAndShare,
} from "./index.styles"

import {
  StyledDonorListWrapper,
  StyledDonorsWrapper,
  StyledDonorName,
  StyledDonorNameWrapper,
  StyledSideDonorsTitle,
  StyledGem,
  StyledSideDonors,
} from "@tmu/components/common/DonorListModal/index.styles"
import { PARTNER_DETAIL_QUERY } from "@tmu/apollo/storefront/queries/partner"
import {
  USER_DONATION_PREFERENCE_QUERY,
  ALL_SUBSCRIPTION_PRODUCTS,
} from "@tmu/apollo/storefront/queries/user"
import { capitalize, getValueForLocale } from "@tmu/utils/string"
import MediaSlider from "@tmu/components/common/MediaSlider"
import { getAllScreenTypes } from "@tmu/utils/mediaQueries"
import { API_PERMISSIONS, FOOTER_TYPE } from "@tmu/apollo/constants"
import {
  DetailPageContainer,
  DetailPageMobileContainer,
  MainPart,
  SidePart,
  Spacer,
} from "@tmu/global/page-addons/detail-page.styles"
import { HomePageContext } from "@tmu/context/homePageContext"
import RecurringDonation from "@tmu/components/partners/RecurringDonation"
import theme from "@tmu/global/theme"
import { getCustomDifferance } from "@tmu/src/utils/date"

const PartnerDetail = ({ slug, location }) => {
  useFooterType({ footerType: FOOTER_TYPE.WIDE })
  const { locale, defaultLocale, formatMessage } = useIntl()

  const pathname = location?.pathname
  const { savePreferences } = useUserPreferences()
  const [isFavoriteNGO, setIsFavoriteNGO] = useState()
  const [amounts, setAmounts] = useState()
  const {
    isAuthenticated,
    apiPermissions,
    callPartnerApiPermissions,
    user,
    callUserProfile,
  } = useAuth()
  const [modalStatus, setModalStatus] = useState(false)

  const { storefrontClient } = useApolloApiClients()
  const { intercomStatus, setIntercomStatus } = useContext(HomePageContext)

  const closeModal = () => {
    setModalStatus(false)
  }

  const openModal = () => {
    setModalStatus(true)
  }

  const { loading, data } = useQuery(PARTNER_DETAIL_QUERY({ locale }), {
    variables: { slug, isPublic: true },
    onCompleted: () => {
      setTimeout(function () {
        if (typeof window !== "undefined") {
          window.prerenderReady = true
        }
      }, 500)
    },
  })
  const topDonor = data?.topDonor?.edges?.[0]?.node || {}
  const latestDonors = data?.latestDonors?.edges?.map((n) => n.node) || []
  const { isTablet } = getAllScreenTypes()

  let donorList = topDonor?.id ? [topDonor].concat(latestDonors) : []
  const uniqueDonors = new Set()
  donorList = donorList.filter((donor) => {
    if (!uniqueDonors.has(donor.id)) {
      uniqueDonors.add(donor.id)
      return true
    }
    return false
  })

  const [callSubscriptionQuery, { data: allSubscriptionProductsData }] =
    useLazyQuery(ALL_SUBSCRIPTION_PRODUCTS, {
      variables: {
        partner: data?.partner?.id,
      },
    })

  useEffect(() => {
    if (data) callSubscriptionQuery()
  }, [data])

  useEffect(() => {
    if (!allSubscriptionProductsData?.allSubscriptionProducts) return
    const localeSuffix = capitalize(locale)

    const allAmountData = [
      {
        amount: parseFloat(
          allSubscriptionProductsData?.allSubscriptionProducts?.edges[0]?.node
            ?.subscriptionAmountOne || 0
        ),
        description:
          allSubscriptionProductsData?.allSubscriptionProducts?.edges[0]
            ?.node?.[`descriptionOne`],
      },
      {
        amount: parseFloat(
          allSubscriptionProductsData?.allSubscriptionProducts?.edges[0]?.node
            ?.subscriptionAmountTwo || 0
        ),
        description:
          allSubscriptionProductsData?.allSubscriptionProducts?.edges[0]
            ?.node?.[`descriptionTwo`],
      },
      {
        amount: parseFloat(
          allSubscriptionProductsData?.allSubscriptionProducts?.edges[0]?.node
            ?.subscriptionAmountThree || 0
        ),
        description:
          allSubscriptionProductsData?.allSubscriptionProducts?.edges[0]
            ?.node?.[`descriptionThree`],
      },
    ]
    setAmounts(allAmountData.sort((a, b) => a.amount - b.amount))
  }, [
    allSubscriptionProductsData,
    allSubscriptionProductsData?.allSubscriptionProducts,
  ])

  const [getDonationPreference, { data: dataPref }] = useLazyQuery(
    USER_DONATION_PREFERENCE_QUERY,
    {
      variables: { slug },
      client: storefrontClient,
      fetchPolicy: "network-only",
    }
  )

  useEffect(() => {
    if (user?.id) {
      getDonationPreference()
      callPartnerApiPermissions()
    }
    setIntercomStatus({ hide: true })
  }, [])

  useEffect(() => {
    setIsFavoriteNGO(
      data?.partner?.defaultCampaign?.id ===
        dataPref?.me?.userPreference?.campaign?.id
    )
  }, [
    data?.partner?.id,
    dataPref?.me?.userPreference,
    dataPref?.me?.userPreference?.campaign?.id,
  ])

  const deleteFavoriteNGO = () => {
    savePreferences({
      variables: {
        input: {
          campaign: null,
        },
      },
    }).then((res) => {
      if (!res?.data?.userPreference?.userPreference?.campaign)
        setIsFavoriteNGO(false)
      callUserProfile()
    })
  }

  const partner = data?.partner
  const campaign = partner?.defaultCampaign

  let partnerImages =
    partner?.images?.edges?.map(({ node }) => ({
      ...node,
    })) ?? []

  const partnerMatch = user?.partners?.edges?.some(
    (item) => item?.node?.id === partner?.id
  )

  const canCreateCampaign =
    isAuthenticated &&
    partnerMatch &&
    apiPermissions?.includes(API_PERMISSIONS.PARTNER_CREATE_CAMPAIGN)

  const storeSocialLinks = {
    official: {
      label: formatMessage({
        id: "progress::detail::officialWebSite",
        defaultMessage: "Official Website",
      }),
      url: partner?.website,
    },
    linkedin: { label: "linkedin", url: partner?.linkedin },
    twitter: { label: "twitter", url: partner?.twitter },
    facebook: { label: "facebook", url: partner?.facebook },
    instagram: { label: "instagram", url: partner?.instagram },
    youtube: { label: "youtube", url: partner?.youtube },
  }

  const descriptionToShare =
    campaign?.shortDescription +
    formatMessage(
      {
        id: "progress::detail::totalSupport",
        defaultMessage: "<funded>{amount}</funded> Raised",
      },
      {
        funded: (...chunks) => (
          <span key="funded" className="funded">
            {chunks.map((chunk, i) => (
              <Fragment key={i}>{chunk}</Fragment>
            ))}
          </span>
        ),
        amount: (
          <Money value={campaign?.fundedAmount} maximumFractionDigits={0} />
        ),
      }
    )

  const favoriteTipText = formatMessage({
    id: "partner::detail::favoriteTooltip",
    defaultMessage:
      "We will remember your favourite charity choice and you could choose to donate it while shopping",
  })

  const MobileHeader = useCallback(() => {
    return (
      <>
        <DetailHeader text={partner?.name} logo={partnerLogo} />
        {partner?.taxId && (
          <StyledFiscalCode>
            <p className="caption">
              <FormattedMessage
                id="typehead::partners::fiscalCode"
                defaultMessage="FISCAL CODE"
              />
              <span className="caption">{partner?.taxId}</span>
            </p>
          </StyledFiscalCode>
        )}
      </>
    )
  }, [partner?.name])

  const DesktopHeader = useCallback(() => {
    return (
      <>
        <StyledLogo>
          <LazyImage
            src={partnerLogo}
            altName={`partner-detail-logo${partner?.name}`}
            height={80}
          />
          {partner?.isEligibleForDonationPreference && isAuthenticated && (
            <StyledFavoriteNGOButton
              data-tooltip-content={favoriteTipText}
              data-tooltip-id="favorite">
              <FontAwesomeIcon
                color={isFavoriteNGO ? theme?.colors?.red : theme?.colors?.blue}
                icon={isFavoriteNGO ? faHeart : falHeart}
                onClick={handleToggleFavorite}
              />
              <Tooltip
                arrowColor="transparent"
                effect="solid"
                place="left"
                type="info"
                textColor="black"
                backgroundColor="white"
                id="favorite"
                className="favorite"
                delayShow={500}
              />
            </StyledFavoriteNGOButton>
          )}
        </StyledLogo>
        <DetailHeader text={partner?.name} />
        {partner?.taxId && (
          <StyledFiscalCode className="caption">
            <FormattedMessage
              id="typehead::partners::fiscalCode"
              defaultMessage="FISCAL CODE"
            />
            <span className="caption">{partner?.taxId}</span>
          </StyledFiscalCode>
        )}
      </>
    )
  }, [partner?.name, isFavoriteNGO])

  const handleCampaignClick = (campaignItem) => {
    navigate("/campaigns/" + campaignItem?.slug)
  }

  const handleShare = () => {
    try {
      if (navigator && navigator.share)
        navigator.share({
          title: partner?.name,
          url: location?.href || "https://trustmeup.com",
          text: partner?.name || "TrustmeUp",
        })
    } catch (err) {}
  }

  const partnerCampaignList =
    partner?.partnerCampaigns?.edges?.filter(
      (item) => item?.node?.isListed === true && !item?.node?.isDefaultCampaign
    ) || []

  const supporterCampaigns =
    partner?.supporterCampaigns?.edges?.filter(
      (item) => item?.node?.isListed === true
    ) || []

  const sortedSupporterCampaigns = [...supporterCampaigns]?.sort(
    (a, b) => parseFloat(b.node.fundedAmount) - parseFloat(a.node.fundedAmount)
  )

  const top3SupporterCampaigns = sortedSupporterCampaigns?.slice(0, 3) || []

  const remainingSupporterCampaigns =
    supporterCampaigns?.filter(
      (campaign) => !top3SupporterCampaigns.includes(campaign)
    ) || []

  const supporterCampaignList = top3SupporterCampaigns.concat(
    remainingSupporterCampaigns
  )

  const PartnerDonors = useCallback(
    ({ isMobile }) => {
      return (
        <>
          <StyledSideDonorsTitle>
            <p className="caption">
              <FormattedMessage
                id="partner::detail::donors"
                defaultMessage="Donors"
              />
            </p>
            <Button variant="text" onClick={openModal}>
              <FormattedMessage id="ui::seeAll" defaultMessage="See all" />
            </Button>
          </StyledSideDonorsTitle>
          <StyledDonorListWrapper isMobile={isMobile}>
            {donorList.length ? (
              donorList?.slice(0, 5).map((donor, index) => (
                <StyledDonorsWrapper key={donor.id}>
                  {donor?.isAnonymous ? (
                    <div className="user">
                      <FontAwesomeIcon icon={faUser} />
                    </div>
                  ) : donor?.user?.profileImage ? (
                    <LazyImage
                      src={donor?.user?.profileImage}
                      altName={`donor-user-${donor?.user?.fullName}`}
                      width={97}
                      height={97}
                      fit="cover"
                    />
                  ) : (
                    <div className="user">{donor?.user?.fullName?.[0]}</div>
                  )}
                  <StyledDonorNameWrapper>
                    <StyledDonorName>
                      {donor?.isAnonymous ? (
                        <FormattedMessage
                          id="donors::modal::anonymous"
                          defaultMessage="Anonymous"
                          tagName="p"
                        />
                      ) : (
                        donor?.user?.fullName
                      )}

                      <StyledSideDonors className="caption">
                        {getCustomDifferance(
                          new Date(),
                          new Date(donor?.latestDonationDate),
                          formatMessage
                        )}
                      </StyledSideDonors>
                      {index === 0 && (
                        <StyledGem>
                          <FontAwesomeIcon className="gem" icon={faGem} />
                          <StyledSideDonors className="caption gem">
                            <FormattedMessage
                              id="donors::modal::top"
                              defaultMessage="Top"
                            />
                          </StyledSideDonors>
                        </StyledGem>
                      )}
                    </StyledDonorName>
                    <div className="total">
                      <Money value={donor?.total || 0} />
                    </div>
                  </StyledDonorNameWrapper>
                </StyledDonorsWrapper>
              ))
            ) : (
              <FormattedMessage
                id="partner::detail::noDonors"
                defaultMessage="There are no donors currently"
                tagName="p"
              />
            )}
          </StyledDonorListWrapper>
        </>
      )
    },
    [partner, partnerCampaignList]
  )

  const PartnerCampaigns = () => {
    return (
      <>
        <StyledSideCampaignsTitle className="caption">
          <FormattedMessage
            id="partner::detail::campaignsInSupportOf"
            defaultMessage="Charity Campaigns"
          />
        </StyledSideCampaignsTitle>
        <StyledCampaignListWrapper>
          {partnerCampaignList.length ? (
            partnerCampaignList?.map((campaignItem, index) => {
              return (
                <StyledCampaignsWrapper
                  key={index}
                  onClick={() => handleCampaignClick(campaignItem?.node)}>
                  <LazyImage
                    src={
                      campaignItem?.node?.images?.edges?.[0]?.node?.image ||
                      partnerLogo
                    }
                    altName={`campaign-item-logo-${campaignItem?.node?.name}`}
                    width={97}
                    height={56}
                    fit="cover"
                  />
                  <StyledCampaignName
                    title={getValueForLocale(
                      campaignItem?.node,
                      "name",
                      locale,
                      defaultLocale
                    )}>
                    {getValueForLocale(
                      campaignItem?.node,
                      "name",
                      locale,
                      defaultLocale
                    )}
                  </StyledCampaignName>
                </StyledCampaignsWrapper>
              )
            })
          ) : (
            <FormattedMessage
              id="partner::detail::noCharityCampaigns"
              defaultMessage="There are no campaigns of this charity"
              tagName="p"
            />
          )}
        </StyledCampaignListWrapper>
      </>
    )
  }

  const SupporterCampaigns = useCallback(() => {
    return (
      <>
        <StyledSideCampaignsTitle className="caption">
          <FormattedMessage
            id="partner::detail::supporterCampaigns"
            defaultMessage="Supporter Campaigns"
          />
        </StyledSideCampaignsTitle>
        <StyledCampaignListWrapper>
          {supporterCampaignList.length ? (
            supporterCampaignList?.map((campaignItem, index) => (
              <StyledCampaignsWrapper
                onClick={() => handleCampaignClick(campaignItem?.node)}
                key={index}>
                <LazyImage
                  src={
                    (typeof campaignItem?.node?.image === "string"
                      ? campaignItem?.node?.image
                      : campaignItem?.node?.image?.url) ||
                    campaignItem?.node?.images?.edges?.[0]?.node.image
                  }
                  altName={`campaign-item-logo-${campaignItem?.node?.name}`}
                  width={97}
                  height={56}
                  fit="cover"
                />
                <StyledCampaignName
                  title={getValueForLocale(
                    campaignItem?.node,
                    "name",
                    locale,
                    defaultLocale
                  )}>
                  {getValueForLocale(
                    campaignItem?.node,
                    "name",
                    locale,
                    defaultLocale
                  )}
                </StyledCampaignName>
              </StyledCampaignsWrapper>
            ))
          ) : (
            <FormattedMessage
              id="partner::detail::noSupporterCampaigns"
              defaultMessage="There are no supporter campaigns yet"
              tagName="p"
            />
          )}
        </StyledCampaignListWrapper>
        {(partner.isEligibleForSupporterEvent ||
          partner.isEligibleForSupporterCollection ||
          canCreateCampaign) && (
          <div className={!isTablet ? "sticky-bottom-bar" : ""}>
            <StyledCreateButton
              color="transparent"
              onClick={() =>
                navigate(
                  `/dashboard/campaign${
                    canCreateCampaign
                      ? ""
                      : `?charity=${
                          partner?.defaultCampaign?.slug
                        }&supporter=true${
                          !partner.isEligibleForSupporterEvent
                            ? "&event=false"
                            : ""
                        }${
                          !partner.isEligibleForSupporterCollection
                            ? "&collection=false"
                            : ""
                        }`
                  }`
                )
              }>
              <FontAwesomeIcon icon={faPlus} />
              {canCreateCampaign ? (
                <FormattedMessage
                  id="campaign::detail::createCampaign"
                  defaultMessage="Create a campaign"
                />
              ) : (
                <FormattedMessage
                  id="partner::detail::createSupporterCampaign"
                  defaultMessage="Create a supporter campaign"
                />
              )}
            </StyledCreateButton>
          </div>
        )}
      </>
    )
  }, [partner, supporterCampaignList])

  const SocialLinks = useCallback(() => {
    const socialLinksVisible = Object.values(storeSocialLinks).some(
      (item) => item?.url?.length > 0
    )
    return (
      <>
        {socialLinksVisible && (
          <>
            <StyledSideCampaignsTitle className="caption">
              <FormattedMessage
                id="partner::detail::officalLinks"
                defaultMessage="Official Links"
              />
            </StyledSideCampaignsTitle>

            <StyledSocialLink>
              {storeSocialLinks &&
                Object.values(storeSocialLinks)
                  ?.filter((value) => value?.url?.length > 0)
                  ?.map((value, index) => (
                    <p key={index}>
                      <a href={value?.url} target="_blank">
                        {value?.label}
                      </a>
                    </p>
                  ))}
            </StyledSocialLink>
          </>
        )}
      </>
    )
  }, [storeSocialLinks])

  const PartnerTabs = useCallback(() => {
    return (
      <>
        <StyledWrapper>
          <StyledContent>
            {shortDescription && shortDescription?.length > 0 && (
              <>
                <StyledSectionTitle className="caption">
                  <FormattedMessage
                    id="partner::detail::productsTitle"
                    defaultMessage="About"
                  />
                </StyledSectionTitle>
                <StyledPartnerDescription
                  dangerouslySetInnerHTML={{
                    __html: shortDescription,
                  }}
                />
                <StyledLine />
              </>
            )}

            {description?.length > 0 && (
              <>
                <StyledSectionTitle className="caption">
                  <FormattedMessage
                    id="partner::detail::whatDoWeDo"
                    defaultMessage="What do we do?"
                  />
                </StyledSectionTitle>
                <StyledPartnerDescription
                  dangerouslySetInnerHTML={{
                    __html: description,
                  }}
                />
                <StyledLine />
              </>
            )}

            {whySupport && whySupport.length > 0 && (
              <>
                <StyledSectionTitle className="caption">
                  <FormattedMessage
                    id="partner::detail::whySupport"
                    defaultMessage="Why Support"
                  />
                </StyledSectionTitle>
                <StyledPartnerDescription
                  dangerouslySetInnerHTML={{
                    __html: whySupport,
                  }}
                />
              </>
            )}
          </StyledContent>
        </StyledWrapper>
      </>
    )
  })

  if (loading) return <Spinner />

  if (!partner) {
    return <ContentNotFound slug={slug} />
  }

  const shortDescription = getValueForLocale(
    partner,
    "shortDescription",
    locale,
    defaultLocale
  )
  const description = getValueForLocale(
    partner,
    "description",
    locale,
    defaultLocale
  )
  const whySupport = getValueForLocale(
    partner,
    "whySupport",
    locale,
    defaultLocale
  )

  const statsComponent = (
    <>
      <Stats
        fundedAmount={partner?.fundedAmount}
        donationCount={partner?.donationCount}
        isTablet={isTablet}
        campaignName={campaign?.name}
        campaignId={campaign?.id}
      />
      {amounts?.length && (
        <RecurringDonation
          amountOne={amounts?.[0]?.amount}
          amountTwo={amounts?.[1]?.amount}
          amountThree={amounts?.[2]?.amount}
          // descriptionOne={amounts?.[0]?.description}
          // descriptionTwo={amounts?.[1]?.description}
          // descriptionThree={amounts?.[2]?.description}
          campaign={campaign}
        />
      )}
    </>
  )

  const partnerDisplayName =
    partner?.displayName?.length > 0 ? partner.displayName : partner.name
  const partnerLogo = getValueForLocale(partner, "logo", locale, defaultLocale)

  const handleToggleFavorite = () => {
    if (!isFavoriteNGO) {
      savePreferences({
        variables: {
          input: {
            campaign: partner?.defaultCampaign?.id,
          },
        },
      }).then((res) => {
        if (res?.data?.userPreference?.userPreference?.campaign?.id)
          setIsFavoriteNGO(true)
        callUserProfile()
      })
    } else {
      deleteFavoriteNGO()
    }
  }

  return (
    <>
      <SEO
        lang={locale}
        title={partnerDisplayName}
        image={partnerImages?.[0]?.image}
        pathname={pathname}
      />
      {!isTablet ? (
        <>
          <DetailPageMobileContainer>
            <MediaSlider images={partnerImages} altName={`partner-detail-`} />
            <MobileHeader />
            {statsComponent}
            <PartnerDonors isMobile />
            <Spacer top={2} />
            <PartnerTabs />
            <SocialLinks />
            <StyledLine />
            <PartnerCampaigns />
            <SupporterCampaigns />
          </DetailPageMobileContainer>
        </>
      ) : (
        <DetailPageContainer>
          <MainPart>
            <MediaSlider images={partnerImages} altName={`partner-detail-`} />
            <PartnerTabs />
          </MainPart>
          <SidePart>
            <StyledHeaderAndShare>
              <div>
                <DesktopHeader />
              </div>
              <div>
                <ShareComponent
                  slug={slug}
                  name={partnerDisplayName}
                  position="center"
                  iconColor={theme?.colors?.blue}
                />
              </div>
            </StyledHeaderAndShare>
            {statsComponent}
            <StyledLine />
            <PartnerDonors />
            <PartnerCampaigns />
            <SupporterCampaigns />
            <StyledLine />
            <SocialLinks />
          </SidePart>
        </DetailPageContainer>
      )}
      {modalStatus ? (
        <DonorListModal
          modalStatus={modalStatus}
          closeModal={closeModal}
          campaignPartnerSlug={slug}
          onAction={closeModal}
          supportButtonText={formatMessage({
            id: "campaign::detail::buttons::supportCampaign",
            defaultMessage: "Donate and Get PACs",
          })}
        />
      ) : null}
    </>
  )
}

PartnerDetail.propTypes = {
  slug: PropTypes.string.isRequired,
}

PartnerDetail.defaultProps = {
  slug: "",
}

export default PartnerDetail
