import React, { useRef, useCallback, useEffect } from "react"
import { LazyImage } from "react-lazy-images"
import { isSafari } from "react-device-detect"
import { StyledMediaBox } from "./index.styles"

const { CLOUDFLARE_IMAGE_DOMAIN = "trustmeup.com" } = process.env
const placeholder = `data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMjc3cHgiIGhlaWdodD0iMTg1cHgiIHZpZXdCb3g9IjAgMCAyNzcgMTg1IiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgogICAgPCEtLSBHZW5lcmF0b3I6IFNrZXRjaCA2MS4yICg4OTY1MykgLSBodHRwczovL3NrZXRjaC5jb20gLS0+CiAgICA8dGl0bGU+bG9nby1zaGFwZS13aGl0ZTwvdGl0bGU+CiAgICA8ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz4KICAgIDxnIGZpbGw9Im5vbmUiIGZpbGxSdWxlPSJldmVub2RkIj4KICAgICAgICA8cGF0aCBmaWxsPSIjRUVFIiBkPSJNMCAwaDI3N3YxODVIMHoiIC8+CiAgICAgICAgPHBhdGgKICAgICAgICAgIGQ9Ik0xNjUgMTE3YTYgNiAwIDAwNi02Vjc1YTYgNiAwIDAwLTYtNmgtNTJhNiA2IDAgMDAtNiA2djM2YTYgNiAwIDAwNiA2aDUyem0wLTRoLTUyYy0xLjEwMyAwLTItLjg5Ny0yLTJWNzVjMC0xLjEwMy44OTctMiAyLTJoNTJjMS4xMDMgMCAyIC44OTcgMiAydjM2YzAgMS4xMDMtLjg5NyAyLTIgMnptLTQ0LTIzYTcgNyAwIDEwMC0xNCA3IDcgMCAwMDAgMTR6bTAtNGMtMS42NTQgMC0zLTEuMzQ2LTMtM3MxLjM0Ni0zIDMtMyAzIDEuMzQ2IDMgMy0xLjM0NiAzLTMgM3ptNDAuNSAyM2ExLjUgMS41IDAgMDAxLjUtMS41Vjk2YTMgMyAwIDAwLS44NzktMi4xMjFsLTExLTExYTMgMyAwIDAwLTQuMjQyIDBsLTExLjg4IDExLjg3OC0zLjg3OC0zLjg3OGEzIDMgMCAwMC00LjI0MiAwbC0xMSAxMWMtLjQ4NS40ODUtLjg3OSAxLjQzNS0uODc5IDIuMTIxdjMuNWExLjUgMS41IDAgMDAxLjUgMS41aDQ1em0tMi41LTRoLTQwdi0uNTg2bDEwLTEwIDYgNiAxNC0xNCAxMCAxMFYxMDV6IgogICAgICAgICAgZmlsbD0iIzMyNTE1OCIKICAgICAgICAgIGZpbGxSdWxlPSJub256ZXJvIgogICAgICAgICAgb3BhY2l0eT0iMC4xIgogICAgICAgIC8+CiAgICAgIDwvZz4KPC9zdmc+`

/**
 * Using loadEagerly, with SSR the image is displayed ASAP,
 * in the first render.
 *
 * With loadEagerly activated, the "error" prop of LazyImage
 * component is not rendering. So, in order to workaround the fallback image,
 * We need to do two things:
 *
 * * Catch the event onError and change the src with the fallback image
 * * Check in the "didMount" (useEffect) that if is completed and doesn't
 *   have height, this means that there is an error, and how is completed
 *   before the hydration, the onError event was not fired by React. So in
 *   this case also the src shoud be changed to the fallback image.
 */
function useLoadEagerlyFallbackImage(fallbackSrc) {
  const ref = useRef(null)

  /**
   * Error happened / catched after hydration
   */
  const onError = useCallback(
    (e) => {
      e.target.src = fallbackSrc
    },
    [fallbackSrc]
  )

  /**
   * Error happened before hydration, but catched after hydration
   */
  useEffect(() => {
    if (ref && ref.current) {
      const { complete, naturalHeight } = ref.current
      const errorLoadingImgBeforeHydration = complete && naturalHeight === 0

      if (errorLoadingImgBeforeHydration) {
        ref.current.src = fallbackSrc
      }
    }
  }, [fallbackSrc])

  return { ref, onError }
}

const LazyImageComponent = ({
  src,
  altName,
  width,
  height,
  fit = "pad",
  maxWidth,
  maxHeight,
  setImageStatus,
  trim,
  ...rest
}) => {
  const loadEagerlyProps = useLoadEagerlyFallbackImage(placeholder)
  const addCdn = useCallback((src, width, height, fit, trim, fitType) => {
    let options = `width=${width},height=${height},fit=${fitType}`
    if (trim) {
      options = `${options},trim=${trim}`
    }

    return src.includes(CLOUDFLARE_IMAGE_DOMAIN)
      ? src.replace(
          CLOUDFLARE_IMAGE_DOMAIN,
          `${CLOUDFLARE_IMAGE_DOMAIN}/cdn-cgi/image/${options},${
            !isSafari && `format=webp`
          },quality=90`
        )
      : src
  })
  const fitType = fit || "cover"
  const imgSrc =
    src && src.length > 0
      ? addCdn(
          src,
          width,
          height,
          fit,
          trim,
          fitType === "fill" ? "cover" : fitType
        )
      : placeholder

  return (
    <StyledMediaBox
      {...rest}
      className="media-box"
      width={width || maxWidth}
      height={height}>
      {imgSrc && altName && (
        <LazyImage
          loadEagerly
          src={imgSrc}
          alt={altName}
          actual={({ imageProps }) => (
            <img
              {...imageProps}
              {...loadEagerlyProps}
              onLoad={setImageStatus}
            />
          )}
          width={width}
          height={height}
          style={{
            objectFit: fitType,
            maxWidth: maxWidth,
            maxHeight: maxHeight,
          }}
        />
      )}
    </StyledMediaBox>
  )
}

export default LazyImageComponent
