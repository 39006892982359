import { gql } from "@apollo/client"
import { pacTransactionParts } from "../fragments/transaction"

export const DEFAULT_PARTNER_QUERY = gql`
  query DefaultPartnerQuery {
    partner {
      id
      name
      email
      displayName
      description
      logo
      country
      city
      postCode
      addressLine1
      addressLine2
      isEligibleForDonationPreference
      paymentDestinations {
        accountName
        bankName
        iban
        country
        sortCode
        accountNumber
        bic
      }
      images {
        edges {
          node {
            id
            order
            image
          }
        }
      }
    }
  }
`

export const PARTNER_PAC_TRANSACTIONS_QUERY = gql`
  query PartnerTransactionListing($first: Int, $after: String) {
    allPacTransactions(first: $first, after: $after, orderBy: "-created") {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          ...pacTransactionParts
          campaign {
            id
            name
            nameEn
            nameIt
            nameEs
            slug
          }
        }
      }
    }
    transactionDirections: __type(
      name: "MoneyPacTransactionTransactionDirectionChoices"
    ) {
      enumValues {
        description
        name
      }
    }
  }

  ${pacTransactionParts}
`

export const PARTNER_DONATION_LISTING_QUERY = gql`
  query PartnerDonationsListing(
    $first: Int
    $after: String
    $orderBy: String
    $status: DonationsDonationStatusChoices
    $campaign: ID
    $startDate: Date
    $endDate: Date
    $created_Lte: DateTime
    $created_Gte: DateTime
  ) {
    allDonations(
      first: $first
      after: $after
      orderBy: $orderBy
      status: $status
      campaign: $campaign
      startDate: $startDate
      endDate: $endDate
      created_Lte: $created_Lte
      created_Gte: $created_Gte
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
      edges {
        node {
          donorName
          id
          campaign {
            id
            name
            nameEn
            nameIt
            nameEs
            deadline
            slug
            status
            user {
              fullName
            }
          }
          user {
            id
            displayName
            fullName
            email
          }
          intendedUser {
            id
            firstName
            lastName
          }
          created
          modified
          amount
          platformFee
          status
          receipt
          refundRequests {
            id
            status
          }
          stripePaymentIntentId
          earnedPac
        }
      }
      __typename
    }
  }
`

export const PARTNER_DONATION_SEARCH_QUERY = gql`
  query DonationSearch($pk: String, $id: ID) {
    donation(pk: $pk, id: $id) {
      id
      campaign {
        id
        name
        nameEn
        nameIt
        nameEs
        slug
        deadline
        partner {
          id
          name
        }
      }
      created
      modified
      amount
      platformFee
      status
      receipt
      refundRequests {
        id
        status
      }
      earnedPac

      __typename
    }
  }
`

export const PARTNER_USER_LISTING_QUERY = gql`
  query PartnerUserListing(
    $first: Int
    $after: String
    $isActive: Boolean
    $partner: ID
  ) {
    allPartnerUsers(
      first: $first
      after: $after
      isActive: $isActive
      partner: $partner
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          id
          fullName
          email
          created
          isActive
          user {
            id
            email
          }
          apiRole {
            id
            codename
            name
            accessType
          }
        }
      }
    }
  }
`

export const SEARCH_PARTNER_USER_QUERY = gql`
  query SearchUser($email: String!) {
    user(email: $email) {
      id
      email
    }
  }
`

export const PARTNER_REFERRAL_LIST = gql`
  query PartnerReferralList($first: Int, $after: String) {
    allReferrals(first: $first, after: $after) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          id
          code
          email
          validUntil
          escalation
          hours
        }
      }
    }
  }
`

export const PARTNER_HERO_LISTING_QUERY = gql`
  query PartnerHeroListing($first: Int, $after: String) {
    allHeroItems(first: $first, after: $after) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          isActive
          title
          titleEn
          titleIt
          titleEs
          detail
          detailEn
          detailIt
          detailEs
          image
          page
          buttonText
          buttonTextEn
          buttonTextIt
          buttonTextEs
          buttonLink
          buttonLinkEn
          buttonLinkIt
          buttonLinkEs
          ordering
          id
        }
      }
    }
  }
`

export const DASHBOARD_PARTNER_HERO_DETAIL_QUERY = gql`
  query heroItem($id: ID!) {
    heroItem(id: $id) {
      isActive
      title
      titleEn
      titleIt
      titleEs
      detail
      detailEn
      detailIt
      detailEs
      image
      page
      buttonText
      buttonTextEn
      buttonTextIt
      buttonTextEs
      buttonLink
      buttonLinkEn
      buttonLinkIt
      buttonLinkEs
      buttonEnabled
      ordering
      id
    }
  }
`

export const PARTNER_ALL_ASSETS_LISTING_QUERY = gql`
  query PartnerAllAssetsListing(
    $first: Int
    $language: String
    $search: String
    $orderBy: String
  ) {
    allAssets(
      first: $first
      language: $language
      search: $search
      orderBy: $orderBy
    ) {
      edges {
        node {
          id
          name
          asset
          assetUrl
          description
          directory
          mimetype
          size
          language
          store {
            id
            name
          }
          charity {
            id
            name
          }
        }
      }
    }
  }
`

export const MAIN_PARTNERS_QUERY = gql`
  query MainPartnersQuery {
    mainAssociates(first: 100) {
      edges {
        node {
          id
          level
          orderingNumber
          logoUrl
          store {
            id
            name
          }
          charity {
            id
            name
          }
        }
      }
    }
  }
`

export const OTHER_PARTNERS_QUERY = gql`
  query OtherPartnersQuery {
    otherAssociates(first: 100) {
      edges {
        node {
          id
          level
          orderingNumber
          logoUrl
          store {
            id
            name
          }
          charity {
            id
            name
          }
        }
      }
    }
  }
`
