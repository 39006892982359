import { useLazyQuery, useMutation, useQuery } from "@apollo/client"
import { faPlus } from "@fortawesome/pro-light-svg-icons/faPlus"
import { faQuestionCircle } from "@fortawesome/pro-light-svg-icons/faQuestionCircle"
import { faUserPlus } from "@fortawesome/pro-light-svg-icons/faUserPlus"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useApolloApiClients } from "@tmu/apollo/client"
import {
  MAX_PER_PAGE,
  PER_PAGE,
  getRelationshipTable,
} from "@tmu/apollo/constants"
import {
  CREATE_MERCHANT_USER_MUTATION,
  CREATE_MERCHANT_USER_REFERRAL_MUTATION,
  DELETE_MERCHANT_USER_MUTATION,
  UPDATE_MERCHANT_USER_MUTATION,
} from "@tmu/apollo/dashboard/mutations/merchant"
import {
  CREATE_PARTNER_USER_MUTATION,
  CREATE_PARTNER_USER_REFERRAL_MUTATION,
  DELETE_PARTNER_USER_MUTATION,
  UPDATE_PARTNER_USER_MUTATION,
} from "@tmu/apollo/dashboard/mutations/partner"
import {
  MERCHANT_REFERRAL_LIST,
  MERCHANT_USER_LISTING_QUERY,
} from "@tmu/apollo/dashboard/queries/merchant"
import {
  PARTNER_REFERRAL_LIST,
  PARTNER_USER_LISTING_QUERY,
} from "@tmu/apollo/dashboard/queries/partner"
import { ALL_API_ROLES_QUERY } from "@tmu/apollo/dashboard/queries/user"
import { Button, CustomModal, Spinner } from "@tmu/components/common"
import { EmptyTable } from "@tmu/components/dashboard/dashboardCommon"
import { modalStyles } from "@tmu/global/GlobalStyle"
import {
  StyledModalActions,
  StyledPage,
  StyledPageActions,
  StyledPageContent,
  StyledPageTitle,
} from "@tmu/global/page-addons/dashboard.styles"
import theme from "@tmu/global/theme"
import {
  useAuth,
  useDefaultMerchant,
  useDefaultPartner,
  useToast,
} from "@tmu/hooks"
import { getAllScreenTypes } from "@tmu/utils/mediaQueries"
import { FormattedMessage, useIntl } from "gatsby-plugin-intl"
import { createContext, useEffect, useState } from "react"
import InviteUserForm from "./InviteUserForm"
import UserTable from "./UserTable"
import {
  StyledAddUserContainer,
  StyledPageTitleUserContainer,
  StyledPageUserContainer,
  StyledRightRelationshipTableHeader,
  StyledRightRelationshipTableRow,
  StyledRoleIcon,
  StyledRoleName,
  StyledRoleText,
  StyledRolesContainer,
  StyledRolesExplanationText,
} from "./index.styles"

export const UserManagementContext = createContext({})
export const UserManagementProvider = UserManagementContext.Provider
export const UserManagementConsumer = UserManagementContext.Consumer

export default ({ dashboardType }) => {
  const { isLoading, isAuthenticated, user: currentUser } = useAuth()
  const { defaultPartner } = useDefaultPartner({
    skip: isLoading || !currentUser?.isPartner,
  })
  const {
    defaultMerchant,
    isMerchantCorporate,
    isMerchantInternal,
    isMerchantOffline,
    isMerchantOnline,
  } = useDefaultMerchant({
    skip: isLoading || !currentUser?.isMerchant,
  })
  const [selected, setSelected] = useState(null)
  const [isExplanationVisible, setExplanationVisible] = useState(false)
  const [openedModal, setOpenedModal] = useState(null)
  const { formatMessage } = useIntl()
  const { success, error } = useToast()
  const { getDashboardClient } = useApolloApiClients()
  const client = getDashboardClient(dashboardType)
  const { data: userRolesData, loading: loadingRoles } = useQuery(
    ALL_API_ROLES_QUERY,
    {
      client,
      skip: isLoading || !isAuthenticated,
      fetchPolicy: "cache-and-network",
      variables: { first: MAX_PER_PAGE },
    }
  )
  const tempUserRoles = loadingRoles
    ? []
    : userRolesData?.allApiRoles?.edges.map(({ node }) => node) ?? []

  const userRoles =
    dashboardType === "merchants"
      ? isMerchantCorporate || isMerchantOffline
        ? tempUserRoles.filter(
            (role) =>
              !["merchant-developer", "merchant-voucher-manager"].includes(
                role.codename
              )
          )
        : isMerchantInternal
        ? tempUserRoles.filter((role) => role.codename !== "merchant-developer")
        : isMerchantOnline
        ? tempUserRoles.filter(
            (role) => role.codename !== "merchant-voucher-manager"
          )
        : tempUserRoles ?? []
      : tempUserRoles ?? []

  const { isWide, isDesktop, isTablet } = getAllScreenTypes()

  const LISTING_QUERY =
    dashboardType === "partners"
      ? PARTNER_USER_LISTING_QUERY
      : MERCHANT_USER_LISTING_QUERY

  const CREATE_MUTATION =
    dashboardType === "partners"
      ? CREATE_PARTNER_USER_MUTATION
      : CREATE_MERCHANT_USER_MUTATION

  const CREATE_REFERRAL_MUTATION =
    dashboardType === "partners"
      ? CREATE_PARTNER_USER_REFERRAL_MUTATION
      : CREATE_MERCHANT_USER_REFERRAL_MUTATION

  const UPDATE_MUTATION =
    dashboardType === "partners"
      ? UPDATE_PARTNER_USER_MUTATION
      : UPDATE_MERCHANT_USER_MUTATION

  const DELETE_MUTATION =
    dashboardType === "partners"
      ? DELETE_PARTNER_USER_MUTATION
      : DELETE_MERCHANT_USER_MUTATION

  const [firstLoad, setFirstLoad] = useState(false)
  const variables = {
    first: PER_PAGE,
    store: currentUser?.activeStore?.id || defaultMerchant?.id,
  }
  const { data, loading, fetchMore } = useQuery(LISTING_QUERY, {
    variables,
    client,
    skip:
      isLoading ||
      !isAuthenticated ||
      (dashboardType !== "partners" && !currentUser?.isMerchant),
    fetchPolicy: "cache-and-network",
    onCompleted: () => setFirstLoad(true),
    onError: () => setFirstLoad(true),
  })

  const REFERRAL_LIST_QUERY =
    dashboardType === "partners"
      ? PARTNER_REFERRAL_LIST
      : MERCHANT_REFERRAL_LIST
  const [
    callReferralListQuery,
    {
      data: referralData,
      loading: loadingReferrals,
      fetchMore: fetchRefMore,
      called,
      refetch,
    },
  ] = useLazyQuery(REFERRAL_LIST_QUERY, {
    variables,
    client,
  })

  useEffect(() => {
    if (!called) {
      callReferralListQuery()
    }
  }, [])

  const referrals =
    referralData?.allReferrals?.edges?.map(({ node }) => ({ ...node })) ?? []

  const [createUser] = useMutation(CREATE_MUTATION, {
    client,
    refetchQueries: [
      {
        query: LISTING_QUERY,
        variables,
      },
    ],
  })
  const [createReferral, { error: referralError }] = useMutation(
    CREATE_REFERRAL_MUTATION,
    {
      client,
      refetchQueries: [
        {
          query: REFERRAL_LIST_QUERY,
          variables,
        },
      ],
    }
  )

  useEffect(() => {
    if (referralError) {
      error(referralError.message)
    }
  }, [referralError])

  const [updateUser, { loading: updatingUser }] = useMutation(UPDATE_MUTATION, {
    client,
    refetchQueries: [
      {
        query: LISTING_QUERY,
        variables,
      },
    ],
  })

  const [deleteUser] = useMutation(DELETE_MUTATION, {
    client,
    refetchQueries: [
      {
        query: LISTING_QUERY,
        variables,
      },
    ],
  })

  const authorizeNewUserText = formatMessage({
    id: "dashboard::users::inviteModalTitle",
    defaultMessage: "Authorize new user",
  })

  const connection =
    dashboardType === "partners" ? "allPartnerUsers" : "allMerchantUsers"

  const users =
    data?.[connection]?.edges
      .map(({ node }) => node)
      .map((node) => ({
        ...node,
        isSelf: node.user.email === currentUser?.email,
      }))
      .sort((a, b) => {
        if (a.isSelf) {
          return -1
        } else if (b.isSelf) {
          return 1
        } else {
          return 0
        }
      }) ?? []

  const pageInfo = data?.[connection]?.pageInfo

  const handleInviteUserModal = () => {
    setSelected(null)
    setOpenedModal("invite")
  }

  const handleConfirmDelete = async () => {
    if (!selected) {
      return
    }
    await handleDeleteUser({ ...selected, isActive: false })
    setSelected(null)
  }
  const handleCancelDelete = () => {
    setSelected(null)
  }

  const handleDeleteUser = ({ id }) => {
    deleteUser({
      variables: {
        input: {
          id,
        },
      },
    }).then(({ data }) => {
      setSelected(null)
      if (data?.deleteUser?.errors?.length) {
        return data.deleteUser.errors.map((err) => error(err.messages?.[0]))
      }
      success(
        formatMessage({
          id: "dashboard::user::userDeleted",
          defaultMessage: "The user is deleted successfully",
        })
      )
    })
  }

  const handleCancelInviteModal = () => {
    setSelected(null)
    setOpenedModal(null)
  }

  const handleLoadMore = () => {
    fetchMore({
      variables: {
        after: data[connection].pageInfo.endCursor,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        const newEdges = fetchMoreResult[connection]?.edges
        const pageInfo = fetchMoreResult[connection].pageInfo

        return newEdges.length
          ? {
              // Put the new [connection] at the end of the list and update `pageInfo`
              // so we have the new `endCursor` and `hasNextPage` values
              [connection]: {
                __typename: prev[connection].__typename,
                edges: [...prev[connection]?.edges, ...newEdges],
                pageInfo,
              },
            }
          : prev
      },
    })
  }

  const handleUpdateUser = async ({ id, apiRole, isActive }) => {
    const { data } = await updateUser({
      variables: { input: { id, apiRole, isActive } },
    })
    const updateConnection =
      dashboardType === "partners" ? "updatePartnerUser" : "updateMerchantUser"

    if (data?.[updateConnection]?.errors?.length === 0) {
      success(
        formatMessage({
          id: "dashboard::user::userUpdated",
          defaultMessage: "The user is updated successfully",
        })
      )
    } else {
      data[updateConnection].errors.forEach((err) => {
        error(err.messages[0])
      })
    }
  }

  const handleAddUser = (values, form) => {
    form.setSubmitting(true)
    let input = { ...values }

    // cleanup input
    delete input.email
    if (dashboardType === "partners") {
      input.partner = defaultPartner?.id
    } else {
      input.store = defaultMerchant?.id
    }

    createUser({
      variables: { input },
    })
      .then(({ data }) => {
        const createConnection =
          dashboardType === "partners"
            ? "createPartnerUser"
            : "createMerchantUser"
        if (data?.[createConnection]?.errors?.length) {
          data[createConnection].errors.forEach((err) =>
            error(err.messages?.[0])
          )
          return
        }
        setOpenedModal(null)

        success(
          formatMessage({
            id: "dashboard::user::userAdded",
            defaultMessage: "The user is added successfully",
          })
        )
      })
      .finally(() => form.setSubmitting(false))
  }

  const handleReferEmail = (values, form) => {
    form.setSubmitting(true)

    let input = { ...values }

    createReferral({
      variables: { input },
    })
      .then(({ data }) => {
        const createConnection =
          dashboardType === "partners"
            ? "createPartnerUserReferral"
            : "createMerchantUserReferral"
        if (data?.[createConnection]?.errors?.length) {
          let errorMessages = "Error : "

          data[createConnection].errors.forEach((err) => {
            try {
              errorMessages += error.messages.join("\n")
            } catch (e) {
              console.error(e)
            }
          })
          error(errorMessages)
          return
        } else if (data?.[createConnection] === null) {
          error("An error occured.")
          return
        }

        setOpenedModal(null)

        success(
          formatMessage(
            {
              id: "dashboard::user::emailInvited",
              defaultMessage: "The email is invited using code {code}",
            },
            {
              code: data[createConnection].referral.code,
            }
          )
        )
      })
      .finally(() => form.setSubmitting(false))
  }

  const tableHeader = userRoles.reduce((acc, item) => {
    if (item.codename !== "partner-voucher-manager") {
      // TODO: will be added when design is ready
      acc.push({
        name: item.name,
        codename: item.codename,
      })
    }
    return acc
  }, [])

  const rightRelationshipTableHeader = (
    <>
      <FormattedMessage
        id="apiRoles::roles::title"
        defaultMessage="Roles"
        tagName="h4"
      />
      <StyledRightRelationshipTableHeader
        columns={tableHeader.length}
        data-testid="right-relationship-table-header">
        <div></div>
        {tableHeader?.map((role) => {
          const { codename, name } = role
          return (
            <StyledRoleName key={name}>
              <FormattedMessage
                id={`apiRoles::role::${codename}`}
                defaultMessage={name}
              />
            </StyledRoleName>
          )
        })}
      </StyledRightRelationshipTableHeader>
    </>
  )

  const merchantKey = isMerchantCorporate
    ? "isMerchantCorporate"
    : isMerchantInternal
    ? "isMerchantInternal"
    : isMerchantOffline
    ? "isMerchantOffline"
    : isMerchantOnline
    ? "isMerchantOnline"
    : null

  const rightRelationshipTable = getRelationshipTable(
    dashboardType,
    merchantKey,
    tableHeader,
    formatMessage
  )
  return (
    <UserManagementProvider
      value={{
        client,
        dashboardType,
        selected,
        roles: userRoles,
        referrals,
        isLoading: updatingUser,
        onUpdateUser: handleUpdateUser,
        onDeleteUser: (payload) => {
          setSelected({ ...payload, apiRole: payload.apiRole.id })
        },
      }}>
      <StyledPage>
        <StyledPageTitleUserContainer>
          <StyledPageTitle
            style={{
              marginBottom: "unset",
            }}>
            <FormattedMessage
              id="dashboard::nav::users"
              defaultMessage="Users"
              tagName="h1"
            />
          </StyledPageTitle>
          {isTablet && (
            <StyledPageUserContainer>
              <StyledRolesContainer
                onMouseEnter={() => setExplanationVisible(true)}
                onMouseLeave={() => setExplanationVisible(false)}>
                <StyledRoleText>
                  <FormattedMessage
                    id="apiRoles::roles::title"
                    defaultMessage="Roles"
                  />
                </StyledRoleText>
                <StyledRoleIcon>
                  <FontAwesomeIcon icon={faQuestionCircle} />
                </StyledRoleIcon>
                {isExplanationVisible && rightRelationshipTable?.length > 0 && (
                  <StyledRolesExplanationText>
                    <div>
                      {rightRelationshipTableHeader}
                      <StyledRightRelationshipTableRow
                        columns={tableHeader.length}>
                        {rightRelationshipTable?.map((item, index) => (
                          <div
                            key={item?.key}
                            className={`cell ${
                              index % (tableHeader.length + 1) === 0 &&
                              "first-cell"
                            }`}>
                            <span>{item?.label}</span>
                          </div>
                        ))}
                      </StyledRightRelationshipTableRow>
                    </div>
                  </StyledRolesExplanationText>
                )}
              </StyledRolesContainer>
              <Button
                variant="text"
                label="Authorize New User"
                data-testid="btn-authorize-new-user"
                onClick={handleInviteUserModal}>
                <FontAwesomeIcon icon={faUserPlus} />
                {(isWide || isDesktop) && authorizeNewUserText}
              </Button>
            </StyledPageUserContainer>
          )}
        </StyledPageTitleUserContainer>
        <StyledPageContent data-testid="user-management-page-content">
          {firstLoad && !loading && users && users.length === 0 && (
            <EmptyTable
              emptyMessage={
                <FormattedMessage
                  id="dashboard::users::usersTable::empty"
                  defaultMessage="There are no users yet."
                />
              }
            />
          )}
          {!firstLoad && loading ? (
            <Spinner condensed />
          ) : (
            <>
              {users.length > 0 && (
                <UserTable users={users} dashboardType={dashboardType} />
              )}
              {pageInfo?.hasNextPage && (
                <StyledPageActions align="center" data-testid="view-more">
                  <Button
                    data-testid="button-view-more"
                    className="button-view-more"
                    label="View More"
                    color="carrot"
                    variant="text"
                    onClick={() => handleLoadMore()}>
                    <FontAwesomeIcon icon={faPlus} />
                    <FormattedMessage
                      id="ui::viewMore"
                      defaultMessage="View More"
                    />
                  </Button>
                </StyledPageActions>
              )}
              <br />
              {!isTablet && (
                <StyledAddUserContainer>
                  <Button
                    data-testid="btn-save"
                    className="blue-button button-margin"
                    color={theme.colors.pacificBlue}
                    type="button"
                    onClick={handleInviteUserModal}>
                    {authorizeNewUserText}
                  </Button>
                </StyledAddUserContainer>
              )}
            </>
          )}
        </StyledPageContent>
      </StyledPage>

      <CustomModal
        isOpen={openedModal === "invite"}
        style={{
          ...modalStyles,
          content: {
            ...modalStyles.content,
            overflow: "visible",
            minHeight: "auto",
          },
        }}
        headerTextStyle={
          isTablet && {
            fontFamily: theme.fontFamilies.headline,
            fontWeight: theme.fontWeights.semiBolder,
            fontStyle: theme.fontStyles.normal,
            color: theme?.colors.pitchBlack,
            fontSize: "1.5rem",
            lineHeight: "normal",
          }
        }
        header={isTablet && authorizeNewUserText}
        descriptionStyle={
          !isTablet && {
            fontFamily: theme.fontFamilies.headline,
            fontWeight: theme.fontWeights.semiBolder,
            fontStyle: theme.fontStyles.normal,
            color: theme?.colors.pitchBlack,
            fontSize: "1.5rem",
            lineHeight: "normal",
            margin: "1rem 0",
          }
        }
        text={!isTablet && authorizeNewUserText}
        isCloseIcon={true}
        isCloseXButton={isTablet ? true : false}
        isCloseBackButton={!isTablet ? true : false}
        cancelButtonAction={() => handleCancelInviteModal()}
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        isMobile={!isTablet}
        isFull={!isTablet}>
        <InviteUserForm
          users={users}
          onInviteUser={handleAddUser}
          onReferEmail={handleReferEmail}
          onCancel={handleCancelInviteModal}
        />
      </CustomModal>
      <CustomModal
        isOpen={selected !== null}
        style={{
          ...modalStyles,
          content: {
            ...modalStyles.content,
            flexDirection: "column",
          },
        }}
        headerTextStyle={
          isTablet && {
            fontFamily: theme.fontFamilies.headline,
            fontWeight: theme.fontWeights.semiBolder,
            fontStyle: theme.fontStyles.normal,
            color: theme?.colors.pitchBlack,
            fontSize: "1.5rem",
            lineHeight: "normal",
          }
        }
        header={
          isTablet && (
            <FormattedMessage
              id="dashboard::users::deleteUser"
              defaultMessage="Delete user"
            />
          )
        }
        descriptionStyle={
          !isTablet && {
            fontFamily: theme.fontFamilies.headline,
            fontWeight: theme.fontWeights.semiBolder,
            fontStyle: theme.fontStyles.normal,
            color: theme?.colors.pitchBlack,
            fontSize: "1.5rem",
            lineHeight: "normal",
          }
        }
        text={
          isTablet && (
            <FormattedMessage
              id="dashboard::user::confirmDelete"
              defaultMessage="Are you sure to delete this user?"
              tagName="p"
            />
          )
        }
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        isCloseIcon={true}
        isCloseXButton={isTablet ? true : false}
        isCloseBackButton={!isTablet ? true : false}
        cancelButtonAction={() => handleCancelDelete()}>
        <StyledModalActions>
          <Button
            color="blue"
            type="button"
            label="Delete User"
            onClick={handleConfirmDelete}
            data-testid="btn-confirm"
            className={isTablet ? "not-mobile-btn-user" : "mobile-btn-user"}>
            <FormattedMessage
              id="dashboard::users::deleteUser"
              defaultMessage="Delete User"
            />
          </Button>
        </StyledModalActions>
      </CustomModal>
    </UserManagementProvider>
  )
}
