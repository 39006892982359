import styled from "@emotion/styled"
import { css } from "@emotion/react"

export const StyledCardWrapper = styled.div`
  box-shadow: rgba(60, 64, 67, 0.3) 0 0.0625rem 0.125rem 0,
    rgba(60, 64, 67, 0.15) 0 0.125rem 0.375rem 0.125rem;
  border-radius: 0.25rem;
  padding: 1.75rem 0.75rem 0.75rem;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    padding: 1.75rem 1.5rem 1.5rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    padding: 3.375rem 2rem 2rem;
  }

  svg {
    color: ${(p) => p.theme.colors.softGreen};
  }

  &:hover {
    box-shadow: rgba(0, 0, 0, 0.15) 0 0.9375rem 1.5625rem,
      rgba(0, 0, 0, 0.05) 0 0.3125rem 0.625rem;
  }
  ${(p) =>
    p.disabled &&
    css`
      background: ${p.theme.colors.whiteSmoke};
    `}

  button {
    font-family: ${(p) => p.theme.fontFamilies.headline};
    font-style: ${(p) => p.theme.fontStyles.normal};
    font-weight: ${(p) => p.theme.fontWeights.semiBolder};
    color: ${(p) => p.theme.colors.white};
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 0.04em;
    font-size: 0.75rem;
    line-height: 1rem;
    width: 100%;
    margin: 0;

    @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
      font-size: 1rem;
      line-height: 1.375rem;
    }
  }
`

export const StyledCardType = styled.div`
  font-family: ${(p) => p.theme.fontFamilies.headline};
  font-style: ${(p) => p.theme.fontStyles.normal};
  font-weight: ${(p) => p.theme.fontWeights.semiBolder};
  color: ${(p) => p.theme.colors.pitchBlack};
  font-size: 1.125rem;
  line-height: 1.5rem;
  margin-top: 1.3125rem;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    margin-top: 0.75rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 2rem;
    line-height: 2.75rem;
    margin-top: 1.875rem;
  }
`

export const StyledCardContent = styled.div`
  font-family: ${(p) => p.theme.fontFamilies.regular};
  font-style: ${(p) => p.theme.fontStyles.normal};
  font-weight: ${(p) => p.theme.fontWeights.liteMedium};
  color: ${(p) => p.theme.colors.pitchBlack};
  font-size: 0.625rem;
  line-height: 1rem;
  text-align: left;
  height: 9.0625rem;
  margin: 0.25rem 0 1.1875rem;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    font-size: 0.75rem;
    line-height: 1.25rem;
    height: 9.0625rem;
    margin: 0.5rem 0 2.5rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    height: 6.25rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 1.5rem;
    line-height: 2.25rem;
    height: 11.25rem;
    margin: 2rem 0 4rem;
  }
`
