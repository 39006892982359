import React, { useRef, useState } from "react"
import PropTypes from "prop-types"
import { FormattedMessage, Link, navigate, useIntl } from "gatsby-plugin-intl"
import { useQuery, useLazyQuery, useMutation } from "@apollo/client"
import { useApolloApiClients } from "@tmu/apollo/client"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCopy } from "@fortawesome/pro-light-svg-icons/faCopy"
import { faQuestionCircle } from "@fortawesome/pro-light-svg-icons/faQuestionCircle"
import { faPlus } from "@fortawesome/pro-light-svg-icons/faPlus"
import { Tooltip } from "react-tooltip"
import {
  Button,
  ClickToCopy,
  Date,
  Spinner,
  Table,
  TooltipSelectMenu,
} from "@tmu/components/common"
import { EmptyTable } from "@tmu/components/dashboard/dashboardCommon"
import { ReferralCodeModal } from "../ReferralCodeModal"
import { useAuth, useDefaultMerchant, useToast } from "@tmu/hooks"
import { getAllScreenTypes } from "@tmu/utils/mediaQueries"

import {
  ALL_USER_TRACKING_REFERRALS_WITH_CAMPAIGN_CONDITIONS_QUERY,
  ALL_USER_TRACKING_REFERRALS_WITH_OFFER_CONDITIONS_QUERY,
  USER_TRACKING_REFERRAL_QR_CODE_QUERY,
} from "@tmu/apollo/dashboard/queries/referrals"

import {
  DELETE_REFERRAL_MUTATION,
  DUPLICATE_REFERRAL_WITH_CAMPAIGN_CONDITIONS_MUTATION,
  DUPLICATE_REFERRAL_WITH_OFFER_CONDITIONS_MUTATION,
  UPDATE_USER_TRACKING_REFERRAL_WITH_CAMPAIGN_CONDITIONS_MUTATION,
  UPDATE_USER_TRACKING_REFERRAL_WITH_OFFER_CONDITIONS_MUTATION,
} from "@tmu/apollo/dashboard/mutations/referrals"

import { API_PERMISSIONS, PER_PAGE } from "@tmu/apollo/constants"

import {
  StyledPage,
  StyledPageContent,
  StyledPageTitle,
} from "@tmu/global/page-addons/dashboard.styles"

import {
  StyledPageTitleContainer,
  StyledPageContainer,
  StyledHowToUseContainer,
  StyledHowToUseText,
  StyledHowToUseIcon,
  StyledPageInfo,
  StyledTable,
  StyledNewReferralCode,
} from "./index.styles"

const ReferralCodeListings = ({ dashboardType }) => {
  const { formatMessage } = useIntl()
  const { getDashboardClient } = useApolloApiClients()
  const { isLoading, user: currentUser, apiPermissions } = useAuth()
  const { success: successToast, error: errorToast } = useToast()
  const { defaultMerchant, isMerchantCorporate, isMerchantOffline } =
    useDefaultMerchant({
      skip: isLoading || !currentUser?.isMerchant,
    })
  const { isDesktop, isTablet, isWide } = getAllScreenTypes()
  const client = getDashboardClient(dashboardType)
  const [menuStatus, setMenuStatus] = useState({})
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [modalType, setModalType] = useState("")
  const [selectedRow, setSelectedRow] = useState(null)
  const [svgSource, setSvgSource] = useState()
  const [sortInfo, setSortInfo] = useState([{ id: "created", desc: true }])
  const selectMenuRef = useRef()
  const menuWidth = isWide ? 220 : 160
  const tooltipHeight = 190
  const isMerchant = dashboardType === "merchants"
  const referralCodeLinkPrefix =
    dashboardType === "partners" ? "charities" : dashboardType
  const isCampaign = isMerchant
    ? isMerchantCorporate || isMerchantOffline
    : true

  const merchantUsers = currentUser?.merchantUsers?.edges

  const activeMerchantTemp =
    merchantUsers?.length === 1
      ? merchantUsers?.[0]
      : merchantUsers?.find(
          (merchant) =>
            merchant?.node?.store?.id === currentUser?.activeStore?.id ||
            merchant?.node?.store?.id === defaultMerchant?.id
        )

  const permission = (type) => {
    if (dashboardType === "donors") {
      return true
    } else if (dashboardType === "merchants") {
      return (
        apiPermissions?.includes(type) ||
        activeMerchantTemp?.node?.finalPermissions?.includes(type)
      )
    } else if (dashboardType === "partners") {
      return apiPermissions?.includes(type)
    }

    return false
  }

  const howToUseTooltipText = formatMessage({
    id: `dashboard::referralCodes::${
      isMerchant
        ? "merchantsTooltip"
        : dashboardType === "partners"
        ? "partnersTooltip"
        : "donorsTooltip"
    }`,
    defaultMessage: isMerchant
      ? "Create a referral link to one of your services and appoint it to a person to track purchases made through the referral link. Manage the usage amount and set link expiration date if needed."
      : dashboardType === "partners"
      ? "Create a referral link to one of your campaigns and appoint it to a person to track donations made through the referral link. Manage the usage amount and set link expiration date if needed."
      : "Create a referral link to one of your supporter campaigns and appoint it to a person to track donations made through the referral link. Manage the usage amount and set link expiration date if needed.",
  })

  const pageInfoText = isCampaign ? (
    dashboardType === "donors" ? (
      <FormattedMessage
        id="offerListing::campaigns::supporterCampaigns"
        defaultMessage="Supporter Campaigns"
        tagName="p"
      />
    ) : (
      <FormattedMessage
        id="nav::mycampaigns"
        defaultMessage="Campaigns"
        tagName="p"
      />
    )
  ) : (
    <FormattedMessage
      id="dashboard::nav::services"
      defaultMessage="Services"
      tagName="p"
    />
  )

  const LISTING_QUERY = isCampaign
    ? ALL_USER_TRACKING_REFERRALS_WITH_CAMPAIGN_CONDITIONS_QUERY
    : ALL_USER_TRACKING_REFERRALS_WITH_OFFER_CONDITIONS_QUERY

  const DUPLICATE_MUTATION = isCampaign
    ? DUPLICATE_REFERRAL_WITH_CAMPAIGN_CONDITIONS_MUTATION
    : DUPLICATE_REFERRAL_WITH_OFFER_CONDITIONS_MUTATION

  const UPDATE_MUTATION = isCampaign
    ? UPDATE_USER_TRACKING_REFERRAL_WITH_CAMPAIGN_CONDITIONS_MUTATION
    : UPDATE_USER_TRACKING_REFERRAL_WITH_OFFER_CONDITIONS_MUTATION

  const variables = {
    first: PER_PAGE,
  }

  const handleSort = (sortBy) => {
    setSortInfo(sortBy)
  }

  const getSortValue = (sortBy) => {
    const sortData = sortBy?.at(0)
    const direction = sortData?.desc === true ? "-" : ""
    let sortField = sortData?.id

    return sortField ? direction + sortField : "-created"
  }

  const { loading, data, fetchMore } = useQuery(LISTING_QUERY, {
    client,
    variables: { ...variables, orderBy: getSortValue(sortInfo) },
    fetchPolicy: "network-only",
  })

  const referrals =
    data?.allUserTrackingReferrals?.edges?.map(({ node }) => ({ ...node })) ??
    []
  const pageInfo = data?.allUserTrackingReferrals?.pageInfo

  const [callDeleteReferralMutation] = useMutation(DELETE_REFERRAL_MUTATION, {
    client,
    fetchPolicy: "network-only",
  })

  const [callDuplicateReferralMutation] = useMutation(DUPLICATE_MUTATION, {
    client,
    fetchPolicy: "network-only",
  })

  const [callUpdateReferralMutation] = useMutation(UPDATE_MUTATION, {
    client,
    fetchPolicy: "network-only",
  })

  const [callReferralQRCode, { data: qrCodeData }] = useLazyQuery(
    USER_TRACKING_REFERRAL_QR_CODE_QUERY,
    {
      client,
      fetchPolicy: "network-only",
    }
  )

  const handleLoadMore = () => {
    if (loading) return // Prevent multiple fetches

    fetchMore({
      variables: {
        after: pageInfo?.endCursor,
        first: PER_PAGE,
        orderBy: "-created",
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev

        const newEdges = fetchMoreResult?.allUserTrackingReferrals?.edges ?? []
        console.log("newEdges", newEdges)
        const pageInfo = fetchMoreResult?.allUserTrackingReferrals?.pageInfo

        const prevEdges = prev.allUserTrackingReferrals?.edges || []

        // Ensure no duplicates are added
        const combinedEdges = [...prevEdges, ...newEdges].reduce(
          (acc, edge) => {
            if (!acc.find((item) => item.node.id === edge.node.id)) {
              acc.push(edge)
            }
            return acc
          },
          []
        )

        return newEdges?.length
          ? {
              // Put the new allUserTrackingReferrals at the end of the list and update `pageInfo`
              // so we have the new `endCursor` and `hasNextPage` values
              allUserTrackingReferrals: {
                __typename: prev.allUserTrackingReferrals.__typename,
                edges: combinedEdges,
                pageInfo,
              },
            }
          : prev
      },
    })
  }

  const columns = [
    {
      Header: () => (
        <div>
          {formatMessage({
            id: "dashboard::referralCodes::referralName",
            defaultMessage: "Referral Name",
          })}
        </div>
      ),
      Cell: ({ row }) => {
        return (
          <div>
            <p className="name">{row?.original?.name}</p>
            <p className="mobile-related-id">{row?.original?.relatedId}</p>
          </div>
        )
      },
      accessor: "code",
      disableSort: false,
      headerStyle: {
        minWidth: isWide ? "22rem" : isTablet ? "12rem" : "11rem",
        maxWidth: isWide ? "2rem" : isTablet ? "12rem" : "11rem",
        width: isWide ? "22rem" : isTablet ? "12rem" : "11rem",
      },
    },
    {
      Header: () => (
        <div>
          <FormattedMessage
            id="dashboard::referralCodes::usageCount"
            defaultMessage="Usage Count"
          />
        </div>
      ),
      Cell: ({ row }) => (
        <div className="align-left">
          {row?.original?.isUnlimited ? (
            <>
              {row?.original?.usageCount} / <span className="symbol">∞</span>
            </>
          ) : (
            `${row?.original?.usageCount} / ${row?.original?.maxUsage}`
          )}
        </div>
      ),
      accessor: "usage",
      disableSort: true,
      headerStyle: {
        minWidth: isWide ? "12rem" : "6rem",
        maxWidth: isWide ? "12rem" : "6rem",
        width: isWide ? "12rem" : "6rem",
      },
    },
    {
      Header: () => (
        <div>
          <FormattedMessage
            id="dashboard::referralCodes::status"
            defaultMessage="Status"
          />
        </div>
      ),
      Cell: ({ row }) => (
        <div className={isTablet ? "align-left" : "align-right"}>
          {row?.original?.isActive ? (
            <FormattedMessage
              id="dashboard::referralCodes::active"
              defaultMessage="Active"
            />
          ) : (
            <FormattedMessage
              id="dashboard::referralCodes::passive"
              defaultMessage="Passive"
            />
          )}
        </div>
      ),
      accessor: "isActive",
      disableSort: false,
      headerStyle: {
        minWidth: isTablet ? "4rem" : isDesktop ? "6rem" : "3rem",
        maxWidth: isTablet ? "4rem" : isDesktop ? "6rem" : "3rem",
        width: isTablet ? "4rem" : isDesktop ? "6rem" : "3rem",
      },
    },
    {
      Header: () => (
        <div>
          <FormattedMessage
            id="dashboard::referralCodes::copyLink"
            defaultMessage="Copy Link"
          />
        </div>
      ),
      Cell: ({ row }) => {
        const isActive = row?.original?.isActive
        const referralUrl = row?.original?.referralUrl

        const handleCopyClick = (e) => {
          if (!isActive) {
            e.preventDefault()
            errorToast(
              formatMessage({
                id: "dashboard::referralCodes::isNotActive",
                defaultMessage: "The referral code is not active!",
              })
            )
          }
        }

        return isActive ? (
          <div className="align-right copy-link">
            <ClickToCopy text={referralUrl}>
              <FontAwesomeIcon icon={faCopy} />
            </ClickToCopy>
          </div>
        ) : (
          <div className="align-right copy-link" style={{ cursor: "pointer" }}>
            <FontAwesomeIcon icon={faCopy} onClick={handleCopyClick} />
          </div>
        )
      },
      accessor: "referralUrl",
      disableSort: true,
      headerStyle: {
        minWidth: isWide ? "8rem" : "5rem",
        maxWidth: isWide ? "8rem" : "5rem",
        width: isWide ? "8rem" : "5rem",
        textAlign: "right",
      },
    },
    {
      Header: () => (
        <div>
          <FormattedMessage
            id="dashboard::referralCodes::campaignOrOffer"
            defaultMessage="Campaign/Offer"
          />
        </div>
      ),
      Cell: ({ row }) => {
        const isActive = row?.original?.isActive
        const referralUrl = row?.original?.referralUrl
        const url = new URL(referralUrl)
        const referral = url.searchParams.get("referral")
        const toUrl = `${url.pathname.replace(
          /\/(en|it|es)/g,
          ""
        )}?referral=${referral}`

        const handleClick = (e) => {
          if (!isActive) {
            e.preventDefault()
            errorToast(
              formatMessage({
                id: "dashboard::referralCodes::isNotActive",
                defaultMessage: "The referral code is not active!",
              })
            )
          }
        }

        return (
          <div className="align-left condition">
            <Link to={toUrl} onClick={handleClick}>
              {isCampaign
                ? row?.original?.conditions?.edges?.map(
                    ({ node }) => node?.campaign?.name
                  )?.[0]
                : row?.original?.conditions?.edges?.map(
                    ({ node }) => node?.offer?.name
                  )?.[0]}
            </Link>
          </div>
        )
      },
      accessor: "condition",
      disableSort: true,
    },
    {
      Header: () => (
        <div>
          <FormattedMessage
            id="dashboard::referralCodes::validUntil"
            defaultMessage="Valid Until"
          />
        </div>
      ),
      Cell: ({ row }) => (
        <div className={isWide ? "align-left" : "align-right"}>
          <Date value={row?.original?.validUntil} showYear={true} />
        </div>
      ),
      accessor: "validUntil",
      disableSort: false,
      headerStyle: {
        minWidth: isWide ? "11rem" : "8rem",
        maxWidth: isWide ? "11rem" : "8rem",
        width: isWide ? "11rem" : "8rem",
      },
    },
    {
      Cell: ({ row }) => {
        const index = row?.index
        const referralCodeStatus = row?.original?.isActive
        return (
          <div className="dot-menu">
            <TooltipSelectMenu
              ref={selectMenuRef}
              menuIsOpen={menuStatus[index]}
              row={row}
              menuWidth={menuWidth}
              tooltipHeight={tooltipHeight}
              options={getMenuOptions(referralCodeStatus)}
              onMouseLeave={() => {
                const temp = {}
                temp[index] = 0
                setMenuStatus({ ...menuStatus, ...temp })
              }}
              onMouseOver={() => {
                if (menuStatus[index] === 1) {
                  return
                }
                const temp = {}
                temp[index] = 1
                Object.keys(menuStatus)?.forEach((ind) => {
                  if (ind != index) {
                    temp[ind] = 0
                  }
                })
                setMenuStatus({ ...menuStatus, ...temp })
              }}
            />
          </div>
        )
      },
      id: "edit",
      disableSort: true,
      headerStyle: {
        minWidth: "1rem",
        maxWidth: "1rem",
        width: "1rem",
      },
    },
  ]

  const hiddenColumns =
    isDesktop || isWide
      ? []
      : isTablet
      ? ["usage", "validUntil"]
      : ["usage", "condition", "validUntil"]

  const options = []

  if (
    permission(
      dashboardType === "merchants"
        ? API_PERMISSIONS.MERCHANT_DELETE_REFERRAL_LINK
        : API_PERMISSIONS.PARTNER_DELETE_REFERRAL_LINK
    )
  ) {
    options.push({
      value: "delete",
      label: formatMessage({
        id: "dashboard::referralCodes::delete",
        defaultMessage: "Delete",
      }),
      action: (row) => {
        setSelectedRow(row)
        setMenuStatus({})
        setModalType("delete")
        setIsModalOpen(true)
      },
    })
  }

  options.push({
    value: "stop",
    label: formatMessage({
      id: "dashboard::referralCodes::stop",
      defaultMessage: "Stop",
    }),
    action: (row) => {
      setSelectedRow(row)
      setMenuStatus({})
      setModalType("stop")
      setIsModalOpen(true)
    },
  })

  // if (
  //   permission(
  //     dashboardType === "merchants"
  //       ? API_PERMISSIONS.MERCHANT_UPDATE_REFERRAL_LINK
  //       : API_PERMISSIONS.PARTNER_UPDATE_REFERRAL_LINK
  //   )
  // ) {
  //   options.push({
  //     value: "edit",
  //     label: formatMessage({
  //       id: "dashboard::referralCodes::edit",
  //       defaultMessage: "Edit",
  //     }),
  //     action: (row) => {
  //       navigate(
  //         `/dashboard/${referralCodeLinkPrefix}/referral-codes/edit/${row?.original?.id}`
  //       )
  //     },
  //   })
  // }

  options.push({
    value: "duplicate",
    label: formatMessage({
      id: "dashboard::referralCodes::duplicate",
      defaultMessage: "Duplicate",
    }),
    action: (row) => {
      handleDuplicateReferralCode(row?.original?.id)
      setMenuStatus({})
    },
  })

  options.push({
    value: "downloadQRCode",
    label: formatMessage({
      id: "dashboard::referralCodes::downloadQRCode",
      defaultMessage: "Download a QR code",
    }),
    action: (row) => {
      handleDownloadReferralQRCode(row?.original?.id)
      setMenuStatus({})
    },
  })

  const getMenuOptions = (referralCodeStatus) => {
    if (referralCodeStatus === false) {
      return options.filter((option) => option.value !== "stop")
    }

    return options
  }

  const handleNewReferralCode = () => {
    navigate(`/dashboard/${referralCodeLinkPrefix}/referral-codes/new`)
  }

  const handleModalClose = () => {
    setIsModalOpen(false)
    setModalType("")
  }

  const unknownError = formatMessage({
    id: "dashboard::campaignForm::errorMessage",
    defaultMessage: "An error occurred",
  })

  const errorHandler = (errors) => {
    if (!errors) return

    if (Array.isArray(errors)) {
      errors.forEach((err) => {
        errorToast(
          (err?.field
            ? err.field + " : " + err.messages?.[0]
            : err.messages?.[0]) ||
            err.message ||
            unknownError
        )
      })
    } else errorToast(errors?.message ?? unknownError)
  }

  const getConditionId = isCampaign
    ? selectedRow?.original?.conditions?.edges?.map(
        ({ node }) => node?.campaign?.id
      )[0]
    : selectedRow?.original?.conditions?.edges?.map(
        ({ node }) => node?.offer?.id[0]
      )[0]

  const handleModalAction = () => {
    setIsModalOpen(false)
    if (modalType === "delete") {
      callDeleteReferralMutation({
        variables: { input: { id: selectedRow?.original?.id } },
        refetchQueries: [
          {
            query: LISTING_QUERY,
            variables: { ...variables, orderBy: getSortValue(sortInfo) },
          },
        ],
      })
        .then(({ data }) => {
          if (
            data?.deleteReferral?.errors &&
            data?.deleteReferral?.errors?.length
          ) {
            errorHandler(data?.deleteReferral?.errors)
          } else if (data?.deleteReferral?.success) {
            successToast(
              formatMessage({
                id: "dashboard::referralCodes::referralCodeDeleted",
                defaultMessage: "The referral code is deleted successfully",
              })
            )
          }
        })
        .catch((err) => {
          errorHandler(err)
        })
        .finally(() => {
          setModalType("")
        })
    } else if (modalType === "stop") {
      callUpdateReferralMutation({
        variables: {
          input: {
            id: selectedRow?.original?.id,
            name: selectedRow?.original?.name,
            maxUsage: selectedRow?.original?.isUnlimited
              ? 1
              : selectedRow?.original?.maxUsage,
            isUnlimited: selectedRow?.original?.isUnlimited,
            validUntil: selectedRow?.original?.validUntil,
            isActive: !selectedRow?.original?.isActive,
            relatedId: selectedRow?.original?.relatedId,
            ...(isCampaign
              ? { campaign: getConditionId }
              : { offer: getConditionId }),
          },
        },
        refetchQueries: [
          {
            query: LISTING_QUERY,
            variables: { ...variables, orderBy: getSortValue(sortInfo) },
          },
        ],
      })
        .then(({ data }) => {
          if (
            data?.updateUserTrackingReferral?.errors &&
            data?.updateUserTrackingReferral?.errors?.length
          ) {
            errorHandler(data?.updateUserTrackingReferral?.errors)
          } else if (data?.updateUserTrackingReferral?.referral?.id) {
            successToast(
              formatMessage({
                id: "dashboard::referralCodes::referralCodeUpdated",
                defaultMessage: "The referral code is updated successfully",
              })
            )
          }
        })
        .catch((err) => {
          errorHandler(err)
        })
        .finally(() => {
          setModalType("")
        })
    }
  }

  const handleDuplicateReferralCode = (id) => {
    callDuplicateReferralMutation({
      variables: { input: { id } },
      refetchQueries: [
        {
          query: LISTING_QUERY,
          variables: { ...variables, orderBy: getSortValue(sortInfo) },
        },
      ],
    })
      .then(({ data }) => {
        if (
          data?.duplicateReferral?.errors &&
          data?.duplicateReferral?.errors?.length
        ) {
          errorHandler(data?.duplicateReferral?.errors)
        } else if (data?.duplicateReferral?.referral?.id) {
          successToast(
            formatMessage({
              id: "dashboard::referralCodes::referralCodeDuplicated",
              defaultMessage: "The referral code is duplicated successfully",
            })
          )
        }
      })
      .catch((err) => {
        errorHandler(err)
      })
      .finally(() => {
        setModalType("")
      })
  }

  const handleDownloadReferralQRCode = (id) => {
    callReferralQRCode({
      variables: { id },
      onCompleted: (data) => {
        setMenuStatus({})
        setSvgSource(data?.userTrackingReferral?.referralQr)
        setModalType("qrCode")
        setIsModalOpen(true)
      },
    })
  }

  return (
    <>
      <StyledPage>
        <StyledPageTitleContainer>
          <StyledPageTitle
            className="referral-code"
            style={{ marginBottom: "unset" }}>
            <FormattedMessage
              id="dashboard::nav::referralCodes"
              defaultMessage="Referral Codes"
              tagName="h1"
            />
          </StyledPageTitle>
          {isTablet && (
            <StyledPageContainer>
              <StyledHowToUseContainer
                data-tooltip-id="howToUse"
                data-tooltip-content={howToUseTooltipText}
                data-tooltip-delay-show={500}>
                <StyledHowToUseText>
                  <FormattedMessage
                    id="dashboard::referralCodes::howToUse"
                    defaultMessage="How to use referrals"
                  />
                </StyledHowToUseText>
                <StyledHowToUseIcon>
                  <FontAwesomeIcon icon={faQuestionCircle} />
                </StyledHowToUseIcon>
                <Tooltip
                  arrowColor="transparent"
                  effect="solid"
                  place="bottom"
                  type="info"
                  textColor="black"
                  backgroundColor="white"
                  id="howToUse"
                  className="referral-code"
                  delayShow={500}
                />
              </StyledHowToUseContainer>
              {permission(
                dashboardType === "merchants"
                  ? API_PERMISSIONS.MERCHANT_CREATE_REFERRAL_LINK
                  : API_PERMISSIONS.PARTNER_CREATE_REFERRAL_LINK
              ) && (
                <Button
                  data-testid="btn-referral-code"
                  variant="text"
                  label="New referral code"
                  onClick={handleNewReferralCode}>
                  <FormattedMessage
                    id="dashboard::referralCodes::newReferralCode"
                    defaultMessage="New referral code"
                  />
                </Button>
              )}
            </StyledPageContainer>
          )}
        </StyledPageTitleContainer>
        <StyledPageInfo>{pageInfoText}</StyledPageInfo>
        <StyledPageContent data-testid="referral-codes-page-content">
          {!loading && referrals.length === 0 && (
            <EmptyTable
              emptyMessage={
                <FormattedMessage
                  id="dashboard::referralCodes::empty"
                  defaultMessage="There are no referral codes yet"
                />
              }
            />
          )}
          {loading ? (
            <Spinner condensed />
          ) : (
            <>
              <StyledTable>
                {referrals.length > 0 && (
                  <Table
                    columns={columns}
                    data={referrals}
                    hiddenColumns={hiddenColumns}
                    initialSort={sortInfo}
                    handleSort={handleSort}
                    options={{ sort: true }}
                  />
                )}
              </StyledTable>
              {!isTablet && (
                <StyledNewReferralCode>
                  <Button
                    data-testid="btn-referral-code"
                    className="blue-button button-margin"
                    type="button"
                    onClick={handleNewReferralCode}>
                    <FormattedMessage
                      id="dashboard::referralCodes::newReferralCode"
                      defaultMessage="New referral code"
                    />
                  </Button>
                </StyledNewReferralCode>
              )}
            </>
          )}
          {pageInfo?.hasNextPage && (
            <div data-testid="view-more">
              <Button
                data-testid="button-view-more"
                variant="text"
                label="View More"
                onClick={handleLoadMore}
                style={{
                  margin: "1rem auto",
                }}>
                <FontAwesomeIcon icon={faPlus} />
                <FormattedMessage
                  id="ui::viewMore"
                  defaultMessage="View More"
                />
              </Button>
            </div>
          )}
        </StyledPageContent>
      </StyledPage>
      {isModalOpen && (
        <ReferralCodeModal
          {...{
            isModalOpen,
            svgSource,
            modalType,
            handleModalAction,
            handleModalClose,
          }}
        />
      )}
    </>
  )
}

ReferralCodeListings.propTypes = {
  dashboardType: PropTypes.string.isRequired,
}

export default ReferralCodeListings
