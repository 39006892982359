import React, { useRef } from "react"
import PropTypes from "prop-types"
import { FormattedMessage, useIntl, navigate } from "gatsby-plugin-intl"
import { Button, Swiper, NewCard } from "@tmu/components/common"
import {
  StyledPartnerSlice as StyledSlice,
  StyledSliceHeader,
} from "../SliceCampaigns/index.styles"
import { getValueForLocale } from "@tmu/utils/string"

const Charities = ({ charities, title, header }) => {
  const { locale, defaultLocale } = useIntl()
  const ref = useRef()

  const handleViewAll = () => {
    navigate("/campaigns?charity=true")
  }

  return (
    <StyledSlice data-testid="explore-charities" id="charities" ref={ref}>
      {header || (
        <StyledSliceHeader>
          <h3>
            {title || (
              <FormattedMessage
                id="homepage::charities::pickCharity"
                defaultMessage="Pick a charity to support"
              />
            )}
          </h3>

          <Button
            variant="link"
            text={
              <FormattedMessage id="ui::viewAll" defaultMessage="View all" />
            }
            size="big"
            onClick={handleViewAll}
          />
        </StyledSliceHeader>
      )}
      <Swiper>
        {charities.map(({ node: charity }) => {
          return (
            <NewCard
              variant="charity"
              hasLogo
              key={charity?.id}
              id={charity?.id}
              partnerLogo={getValueForLocale(
                charity?.partner,
                "logo",
                locale,
                defaultLocale
              )}
              title={getValueForLocale(charity, "name", locale, defaultLocale)}
              totalDonors={charity?.donationCount}
              fundedAmount={charity?.fundedAmount}
              totalSupport={charity?.fundedAmount}
              size="default"
              cardLink={`/charities/${charity?.partner?.slug}`}
              taxId={charity?.partner?.taxId}
              pacMultiplier={charity?.pacMultiplier}
            />
          )
        })}
      </Swiper>
    </StyledSlice>
  )
}

Charities.propTypes = {
  charities: PropTypes.arrayOf(PropTypes.any).isRequired,
}

Charities.defaultProps = {
  charities: [],
}

export default Charities
