import React, { useEffect, useMemo, useState } from "react"
import { FormattedMessage, navigate, useIntl } from "gatsby-plugin-intl"
import { useLazyQuery, useMutation } from "@apollo/client"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlus } from "@fortawesome/pro-light-svg-icons/faPlus"
import { faXmark } from "@fortawesome/free-solid-svg-icons/faXmark"
import { useApolloApiClients } from "@tmu/apollo/client"
import { useAuth, useDefaultPartner, useToast } from "@tmu/hooks"
import { API_PERMISSIONS } from "@tmu/apollo/constants"

import {
  Button,
  Dropdown,
  Spinner,
  Tabs,
  TabList,
  Tab,
  TextInput,
  CustomModal,
} from "@tmu/components/common"
import {
  CampaignTable,
  EmptyTable,
} from "@tmu/components/dashboard/dashboardCommon"
import { getValueForLocale } from "@tmu/utils/string"
import {
  PER_PAGE,
  CAMPAIGN_STOP_REASON,
  VISIBILITY_STATUS,
} from "@tmu/apollo/constants"
import {
  PARTNER_CAMPAIGN_LISTING_QUERY,
  PARTNER_CAMPAIGN_LISTING_BY_FUNDRAISER_TYPE_QUERY,
} from "@tmu/apollo/dashboard/queries/campaign"
import {
  CREATE_SUPPORTER_CAMPAIGN_REVIEW_MUTATION,
  CAMPAIGN_SUBMIT_FOR_PARTIAL_UPDATE,
} from "@tmu/apollo/dashboard/mutations/campaign"
import { getAllScreenTypes } from "@tmu/utils/mediaQueries"
import {
  StyledPage,
  StyledPageContent,
  StyledPageTitle,
  StyledPageActions,
  StyledModalContainer,
  StyledModalHeader,
} from "@tmu/global/page-addons/dashboard.styles"

const CampaignListing = () => {
  const { locale, defaultLocale, formatMessage } = useIntl()
  const { isAuthenticated, isLoading, user, apiPermissions } = useAuth()
  const { defaultPartner } = useDefaultPartner({
    skip: isLoading || !user?.isPartner,
  })
  const { error } = useToast()
  const { partnerClient } = useApolloApiClients()
  const [isModalOpen, setModalOpen] = useState(false)
  const [tabIndex, setTabIndex] = useState(0)
  const [stopCampaignStep, setStopCampaignStep] = useState(0)
  const [campaign, setCampaign] = useState("")
  const [stopReason, setStopReason] = useState("")
  const [details, setDetails] = useState("")
  const { isWide } = getAllScreenTypes()

  const tabs = useMemo(() => {
    return [
      {
        label: formatMessage({
          id: "ui::charityCampaigns",
          defaultMessage: "Charity campaigns",
        }),
        key: "charityCampaigns",
        dataTestId: "charityCampaigns",
      },
      {
        label: formatMessage({
          id: "ui::supporterCampaigns",
          defaultMessage: "Supporter's campaigns",
        }),
        key: "supporterCampaigns",
        dataTestId: "supporterCampaigns",
      },
      {
        label: formatMessage({
          id: "ui::finished",
          defaultMessage: "Finished",
        }),
        key: "finished",
        dataTestId: "finished",
      },
      {
        label: formatMessage({
          id: "ui::allActive",
          defaultMessage: "All active",
        }),
        key: "allActive",
        dataTestId: "allActive",
      },
    ]
  }, [])

  const getVariables = () => {
    return tabIndex === 0
      ? {
          fundraiserType: ["campaigns", "charities", "events"],
          visibilityStatus: ["1", "2", "3"],
        }
      : tabIndex === 1
      ? {
          fundraiserType: ["supporters", "events"],
          visibilityStatus: ["1", "2", "3"],
        }
      : tabIndex === 2
      ? {
          visibilityStatus: ["0"],
        }
      : tabIndex === 3
      ? {
          visibilityStatus: ["1", "2", "3"],
        }
      : {}
  }

  const [callCampaignsByFundraiserType, { loading, data, fetchMore }] =
    useLazyQuery(PARTNER_CAMPAIGN_LISTING_BY_FUNDRAISER_TYPE_QUERY, {
      client: partnerClient,
      skip: loading || !isAuthenticated || !user?.isPartner,
      fetchPolicy: "cache-and-network",
    })

  const [createSupporterCampaignReview] = useMutation(
    CREATE_SUPPORTER_CAMPAIGN_REVIEW_MUTATION,
    {
      client: partnerClient,
      refetchQueries: [
        {
          query: PARTNER_CAMPAIGN_LISTING_QUERY,
          variables: getVariables(),
        },
      ],
    }
  )

  const [partialUpdateCampaign] = useMutation(
    CAMPAIGN_SUBMIT_FOR_PARTIAL_UPDATE,
    {
      client: partnerClient,
      refetchQueries: [
        {
          query: PARTNER_CAMPAIGN_LISTING_QUERY,
          variables: {
            first: PER_PAGE,
          },
        },
      ],
    }
  )

  useEffect(() => {
    callCampaignsByFundraiserType({
      variables: {
        first: PER_PAGE,
        ...getVariables(),
      },
    })
  }, [tabIndex])

  const defaultCampaign = defaultPartner?.length
    ? Array.from(defaultPartner)
    : []

  const campaigns =
    (!loading &&
      data?.allCampaigns?.edges
        ?.filter(({ node }) => node?.id !== defaultPartner?.id)
        .map(({ node }) => ({
          ...node,
          name: getValueForLocale(node, "name", locale, defaultLocale),
        }))) ??
    []

  const handlePrivacyClick = (id, visibilityStatus) => {
    partialUpdateCampaign({
      variables: {
        input: {
          id,
          visibilityStatus:
            visibilityStatus === VISIBILITY_STATUS.LISTED
              ? VISIBILITY_STATUS.UNLISTED
              : VISIBILITY_STATUS.LISTED,
        },
      },
      client: partnerClient,
    })
  }
  const handleCreateNewCampaign = () => {
    navigate("/dashboard/campaign?partner=true")
  }

  const handleStopCampaign = ({ id, visibilityStatus }) => {
    if (tabIndex === 0) {
      partialUpdateCampaign({
        variables: {
          input: {
            id,
            visibilityStatus:
              visibilityStatus === VISIBILITY_STATUS.UNLISTED
                ? VISIBILITY_STATUS.ARCHIVED
                : VISIBILITY_STATUS.UNLISTED,
          },
        },
        client: partnerClient,
      }).then(() => {
        callCampaignsByFundraiserType({
          variables: {
            first: PER_PAGE,
            ...getVariables(),
          },
        })
      })
    }

    if (tabIndex === 1) {
      setModalOpen(true)
      setCampaign(id)
    }
  }

  const handleModalStopCampaign = () => {
    stopCampaignStep === 0 && setStopCampaignStep(1)
    stopCampaignStep === 1 &&
      createSupporterCampaignReview({
        variables: {
          input: {
            campaign,
            type: stopReason?.value,
            details,
          },
        },
        client: partnerClient,
      })
        .then(() => {
          callCampaignsByFundraiserType({
            variables: {
              first: PER_PAGE,
              ...getVariables(),
            },
          })
        })
        .catch(errorHandler)
        .finally(() => setModalOpen(false))
  }

  const errorHandler = (err) => {
    error(
      err?.message ??
        formatMessage({
          id: "dashboard::campaignForm::errorMessage",
          defaultMessage: "An error occurred",
        })
    )
  }

  const handleModalClose = () => {
    setModalOpen(false)
    setStopCampaignStep(0)
  }

  const handleLoadMore = () => {
    fetchMore({
      variables: {
        after: data?.allCampaigns?.pageInfo?.endCursor,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        const newEdges = fetchMoreResult?.allCampaigns?.edges
        const pageInfo = fetchMoreResult?.allCampaigns?.pageInfo

        return newEdges?.length
          ? {
              // Put the new allCampaigns at the end of the list and update `pageInfo`
              // so we have the new `endCursor` and `hasNextPage` values
              allCampaigns: {
                __typename: prev.allCampaigns.__typename,
                edges: [...prev.allCampaigns?.edges, ...newEdges],
                pageInfo,
              },
            }
          : prev
      },
    })
  }

  const setCampaignStopReasonName = (key) => {
    const words = key.toLowerCase().split("_")

    for (let i = 1; i < words.length; i++) {
      words[i] = words[i][0].toUpperCase() + words[i].substr(1)
    }

    return words.join("")
  }

  const stopReasonOptions = Object.keys(CAMPAIGN_STOP_REASON).map((key) => {
    return {
      ...key,
      value: key,
      label: (
        <FormattedMessage
          id={`stopCampaign::reason::${useMemo(
            () => setCampaignStopReasonName(key),
            [key]
          )}`}
          defaultMessage={key.replace("_", " ")}
        />
      ),
    }
  })

  return (
    <>
      <StyledPage>
        <StyledPageTitle data-testid="text-campaigns">
          <FormattedMessage
            id="dashboard::campaigns::pageTitle"
            defaultMessage="Campaigns"
            tagName="h1"
          />
          {apiPermissions?.includes(API_PERMISSIONS.PARTNER_CREATE_CAMPAIGN) ? (
            <Button
              className="create-button"
              data-testid="btn-create-campaign"
              variant="text"
              onClick={handleCreateNewCampaign}
              type="button">
              <FontAwesomeIcon icon={faPlus} />
              <FormattedMessage
                id="supporter::campaigns::button"
                defaultMessage="Create a campaign"
              />
            </Button>
          ) : null}
        </StyledPageTitle>
        <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
          <TabList className="campaigns-tab">
            {tabs.map(({ key, label, dataTestId }) => (
              <Tab key={key} data-testid={"test-id-" + dataTestId}>
                {label}
              </Tab>
            ))}
          </TabList>
        </Tabs>{" "}
        {loading ? (
          <Spinner condensed />
        ) : (
          <StyledPageContent
            data-testid="table-campaigns"
            disabled={
              !apiPermissions?.includes(API_PERMISSIONS.PARTNER_CREATE_CAMPAIGN)
            }>
            {defaultCampaign?.length || campaigns?.length ? (
              <CampaignTable
                defaultCampaign={defaultCampaign}
                campaigns={campaigns}
                onStopCampaign={handleStopCampaign}
                handlePrivacyClick={handlePrivacyClick}
              />
            ) : (
              <EmptyTable
                emptyMessage={
                  tabIndex === 1 ? (
                    <FormattedMessage
                      id="dashboard::campaigns::emptySupporter"
                      defaultMessage="No supporter campaigns."
                    />
                  ) : tabIndex === 2 ? (
                    <FormattedMessage
                      id="dashboard::campaigns::emptyFinished"
                      defaultMessage="No finished campaigns."
                    />
                  ) : (
                    <FormattedMessage
                      id="dashboard::campaigns::empty"
                      defaultMessage="It seems you haven't created any campaigns, why not create one now?"
                    />
                  )
                }
              />
            )}
            {data?.allCampaigns?.pageInfo?.hasNextPage && (
              <StyledPageActions align={"center"}>
                <Button
                  data-testid="btn-view-more"
                  label="View More"
                  variant="text"
                  onClick={handleLoadMore}>
                  <FontAwesomeIcon icon={faPlus} />
                  <FormattedMessage
                    id="ui::viewMore"
                    defaultMessage="View More"
                  />
                </Button>
              </StyledPageActions>
            )}
          </StyledPageContent>
        )}
      </StyledPage>
      <CustomModal
        isOpen={isModalOpen}
        style={{
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            textAlign: "center",
            lineHeight: 3,
            display: "flex",
            alignItems: "center",
            justifyContent: "spaceBetween",
            maxWidth: "69%",
          },
        }}
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        onRequestClose={handleModalClose}>
        <form>
          <StyledModalContainer className="stop">
            <StyledModalHeader className="stop-header">
              <FormattedMessage
                id="stopCampaign::reason::sure"
                defaultMessage="Sure, you want to stop this campaign?"
                tagName="h4"
              />
              <Button
                data-testid="btn-modal-close"
                variant="text"
                onClick={handleModalClose}
                type="button">
                <FontAwesomeIcon icon={faXmark} size="3x" />
              </Button>
            </StyledModalHeader>
            {stopCampaignStep === 0 ? (
              <>
                <p className="caption">
                  <FormattedMessage
                    id="stopCampaign::reason::why"
                    defaultMessage="Why do you want to stop this campaign?"
                  />
                </p>
                <Dropdown
                  id="stopReason"
                  name="stopReason"
                  options={stopReasonOptions}
                  value={stopReason}
                  defaultSelectStyles={{
                    control: {
                      height: "2.75rem",
                      paddingTop: "0",
                      paddingRight: "0",
                      fontSize: isWide ? "1rem" : "0.75rem",
                      textAlign: "left",
                      minWidth: "unset",
                    },
                    option: {
                      height: "2.75rem",
                      paddingTop: "0",
                    },
                    valueContainer: {
                      height: "2.75rem",
                      paddingTop: "0",
                    },
                    inputContainer: {
                      height: "2.75rem",
                      paddingTop: "0",
                    },
                    input: {
                      padding: "0",
                      margin: "0",
                    },
                  }}
                  onChange={(data) => {
                    setStopReason(data)
                  }}
                />
                <p className="caption">
                  <FormattedMessage
                    id="stopCampaign::reason::provideMoreDetails"
                    defaultMessage="Provide more details"
                  />
                </p>
                <TextInput
                  newDesign
                  id="details"
                  name="details"
                  type="textarea"
                  defaultValue={details}
                  value={details}
                  maxLength={500}
                  onChange={(e) => setDetails(e.target.value)}
                />
              </>
            ) : (
              <div className="text">
                <FormattedMessage
                  id="stopCampaign::reason::confirm"
                  defaultMessage="Campaign will stop receiving donations and you could find it under tab of finished campaigns."
                />
              </div>
            )}
            <Button
              data-testid="btn-stop-campaign"
              color="red"
              label="Stop"
              className={`btn-stop-campaign ${
                stopCampaignStep === 0 ? `step1` : `step2`
              }`}
              disabled={!stopReason?.value || !details}
              onClick={handleModalStopCampaign}>
              <FormattedMessage
                id="stopCampaign::reason::button"
                defaultMessage="Stop campaign"
              />
            </Button>
          </StyledModalContainer>
        </form>
      </CustomModal>
    </>
  )
}

export default CampaignListing
