import React, { Fragment, useContext, useEffect, useState } from "react"
import { injectIntl, useIntl, FormattedMessage, Link } from "gatsby-plugin-intl"
import { useFooterType } from "@tmu/hooks"
import { HomePageContext } from "@tmu/context/homePageContext"
import { LazyImage, Spinner } from "@tmu/components/common"
import { FOOTER_TYPE } from "@tmu/apollo/constants"
import { faCloudSun } from "@fortawesome/free-solid-svg-icons/faCloudSun"
import { faMapLocationDot } from "@fortawesome/free-solid-svg-icons/faMapLocationDot"
import { faShoppingBag } from "@fortawesome/pro-solid-svg-icons/faShoppingBag"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { getAllScreenTypes } from "@tmu/utils/mediaQueries"
import { StyledJoinPageWrapper } from "@tmu/global/page-addons/how-it-works.styles"
import {
  StyledWrapper,
  StyledStoryBox,
  StyledStoryBoxHeader,
  StyledStoryBoxTitle,
  StyledStoryBoxText,
  StyledStoryBoxOverlay,
  StyledStoryBoxImage,
  StyledStoryBoxMobileText,
  StyledBenefitList,
  StyledBenefit,
  StyledBenefitCard,
  StyledBenefitFontIcon,
  StyledBenefitTitle,
  StyledBenefitDescription,
  StyledBenefitLink,
  StyledSectionDivider,
} from "@tmu/global/page-addons/merchants-page.styles"
import SEO from "@tmu/src/components/seo"

const { CLOUDFLARE_IMAGE_URL } = process.env

const MerchantsPage = ({ location }) => {
  const pathname = location?.pathname
  const { formatMessage, locale } = useIntl()
  const { setHeaderConfigData } = useContext(HomePageContext)
  const [firstLoad, setFirstLoad] = useState(false)
  useFooterType({ footerType: FOOTER_TYPE.WIDE })

  useEffect(() => {
    setHeaderConfigData({ transparent: true })
  }, [])

  const { isTablet, isDesktop, isWide } = getAllScreenTypes()

  const imageHeight = isWide ? 640 : isDesktop ? 480 : isTablet ? 394 : 336

  const bannerData = {
    header: formatMessage({
      id: "join::merchants::heroHeader",
      defaultMessage:
        "Support charities, while increasing your clients awareness and sales",
    }),
    title: (
      <FormattedMessage
        id="join::merchants::heroTitle"
        defaultMessage="We provide online and offline service plans. Choose the plan that fits you better. Explore others who has already joined in <span>PAC Stores.</span>"
        values={{
          span: (...chunks) => (
            <a href="/offers?offer=true&store=true">
              {chunks.map((chunk, i) => (
                <Fragment key={i}>{chunk}</Fragment>
              ))}
            </a>
          ),
        }}>
        {(...chunks) => (
          <p>
            {chunks.map((chunk, i) => (
              <Fragment key={i}>{chunk}</Fragment>
            ))}
          </p>
        )}
      </FormattedMessage>
    ),
    image:
      CLOUDFLARE_IMAGE_URL + "/static/assets/images/join/working_woman.png",
  }

  const benefitCards = [
    {
      title: formatMessage({
        id: "join::merchants::title",
        defaultMessage: "Online stores",
      }),
      desc: formatMessage({
        id: "join::merchants::description",
        defaultMessage:
          "Create PAC Offers of your products so your customers could  get a discount paying with PACs, encourage donations and increase sales of your e-commerce website",
      }),
      fontIcon: faCloudSun,
      linkText: (
        <>
          <FormattedMessage
            id="join::merchants::linkText"
            defaultMessage="Online merchant's guide"
          />
          <span>&#8250;</span>
        </>
      ),
      link: "/join/merchant/online",
    },
    {
      title: formatMessage({
        id: "join::merchants::title2",
        defaultMessage: "Physical stores",
      }),
      desc: formatMessage({
        id: "join::merchants::description2",
        defaultMessage:
          "Become charity friendly store, no matter if you have a website or not.  Give your customers in-store discounts for donating to a charity of your choice. All your customer will have to do is to scan a QR-code to your campaign on his cell phone",
      }),
      fontIcon: faMapLocationDot,
      linkText: (
        <>
          <FormattedMessage
            id="join::merchants::linkText2"
            defaultMessage="Physical store guide"
          />
          <span>&#8250;</span>
        </>
      ),
      link: "/join/merchant/offline",
    },
    {
      title: formatMessage({
        id: "join::merchants::title3",
        defaultMessage: "Professional services ",
      }),
      desc: formatMessage({
        id: "join::merchants::description3",
        defaultMessage:
          "Join our professional services marketplace to boost yor visibility. You will get an individual store page and pages for each service. Set different price packages or PAC discounts ",
      }),
      fontIcon: faShoppingBag,
      linkText: (
        <>
          <FormattedMessage
            id="join::merchants::linkText3"
            defaultMessage="Professional services guide"
          />
          <span>&#8250;</span>
        </>
      ),
      link: "/join/merchant/internal",
    },
  ]

  useEffect(() => {
    setFirstLoad(true)
  }, [])

  const textData = (
    <StyledStoryBoxTitle>{bannerData?.title}</StyledStoryBoxTitle>
  )

  if (!firstLoad) return <Spinner />

  return (
    <StyledJoinPageWrapper>
      <SEO
        lang={locale}
        title={formatMessage({
          id: "join::merchants::joinAsMerchant",
          defaultMessage: "Join as merchants",
        })}
        description="We provide online and offline service plans. Choose the plan that fits you better"
        pathname={pathname}
      />
      <StyledWrapper>
        <div>
          <StyledStoryBox>
            <StyledStoryBoxText>
              <StyledStoryBoxHeader>{bannerData?.header}</StyledStoryBoxHeader>
              {isTablet && textData}
            </StyledStoryBoxText>
            {!isTablet && <StyledStoryBoxOverlay></StyledStoryBoxOverlay>}
            <StyledStoryBoxImage>
              <LazyImage
                altName="join as merchants"
                src={bannerData?.image}
                width="auto"
                height={imageHeight}
                fit="cover"
              />
            </StyledStoryBoxImage>
          </StyledStoryBox>
          {!isTablet && (
            <StyledStoryBoxMobileText>{textData}</StyledStoryBoxMobileText>
          )}
        </div>
        <StyledBenefitList>
          <StyledBenefit>
            {benefitCards?.map((e, i) => (
              <StyledBenefitCard key={i}>
                <StyledBenefitFontIcon>
                  <FontAwesomeIcon icon={e?.fontIcon} />
                </StyledBenefitFontIcon>
                {e?.title && (
                  <StyledBenefitTitle>{e?.title}</StyledBenefitTitle>
                )}
                {e?.desc && (
                  <StyledBenefitDescription>{e?.desc}</StyledBenefitDescription>
                )}
                {!isTablet && e?.linkText && (
                  <>
                    <StyledBenefitLink>
                      <Link to={e?.link}>{e?.linkText}</Link>
                    </StyledBenefitLink>
                    {i !== benefitCards.length - 1 && <StyledSectionDivider />}
                  </>
                )}
              </StyledBenefitCard>
            ))}
          </StyledBenefit>
          {isTablet && (
            <StyledBenefit>
              {benefitCards?.map((e, i) => (
                <StyledBenefitCard key={i}>
                  <StyledBenefitLink>
                    <Link to={e?.link}>{e?.linkText}</Link>
                  </StyledBenefitLink>
                </StyledBenefitCard>
              ))}
            </StyledBenefit>
          )}
        </StyledBenefitList>
      </StyledWrapper>
    </StyledJoinPageWrapper>
  )
}

export default injectIntl(MerchantsPage)
