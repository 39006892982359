import styled from "@emotion/styled"
import { css } from "@emotion/react"

export const StyledBankInfoFieldSet = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  gap: 2rem;
  ${(p) =>
    p.isUKBank
      ? p.isPartner
        ? css`
            grid-template-areas:
              "header header"
              "legalName legalName"
              "bank country"
              "sortCode accountNumber"
              "bic bic";
          `
        : css`
            grid-template-areas:
              "header header"
              "legalName legalName"
              "bank country"
              "sortCode accountNumber";
          `
      : p.isPartner
      ? css`
          grid-template-areas:
            "header header"
            "legalName legalName"
            "bank country"
            "iban bic";
        `
      : css`
          grid-template-areas:
            "header header"
            "legalName legalName"
            "bank country"
            "iban iban";
        `};
`

export const StyledHeader = styled.div`
  grid-column: 1 / 4;
  grid-area: header;
`

export const StyledField = styled.div`
  ${(p) =>
    p.gridArea &&
    css`
      grid-area: ${p.gridArea};
    `}
`
