import React from "react"
import { Router } from "@reach/router"
import { CharityListing } from "@tmu/components/campaigns"
import { PartnerDetail } from "@tmu/components/partners"
import PrivateRoute from "@tmu/components/PrivateRoute"
import { CampaignDonationForm } from "@tmu/components/campaigns"

import { injectIntl } from "gatsby-plugin-intl"

const Campaigns = () => (
  <Router basepath="/:locale">
    <PartnerDetail path="/charities/:slug" />
    <PrivateRoute
      path="/charities/:slug/donate"
      component={CampaignDonationForm}
    />
  </Router>
)

export default injectIntl(Campaigns)
