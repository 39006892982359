import styled from "@emotion/styled"

export const StyledSlice = styled.div`
  margin: 3rem -1rem 0 0;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    margin: 3rem auto 0;
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    margin: 5rem auto;
  }
`
export const StyledStoryBox = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin: auto;
  height: 13.5rem;

  &.row-one {
    background-color: ${(p) => p.theme.colors.whiteSmoke};
  }

  &.row-two {
    background-color: ${(p) => p.theme.colors.white};
    margin-left: -1rem;
    padding-left: 1rem;
  }

  img {
    width: 100%;
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    padding-left: 2rem;
    &.row-two {
      justify-content: end;
      margin-left: 0;
      padding-left: 0;
    }
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    height: 13.5rem;
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    height: 18.5625rem;
  }
`

export const StyledTitle = styled.p`
  font-size: 1.125rem;
  line-height: 1.5rem;
  font-weight: ${(p) => p.theme.fontWeights.semiBolder};
  font-family: ${(p) => p.theme.fontFamilies.headline};
  color: ${(p) => p.theme.colors.gondola};
  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    font-size: 1.5rem;
    line-height: 2rem;
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    font-size: 2rem;
    line-height: 2.625rem;
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 3rem;
    line-height: 4.0625rem;
  }
`

export const StyledDescription = styled.div`
  color: ${(p) => p.theme.colors.black};
  font-size: 0.75rem;
  line-height: 1.125rem;
  font-family: ${(p) => p.theme.fontFamilies.regular};
  font-weight: ${(p) => p.theme.fontWeights.liteMedium};
  margin-top: 0.75rem;
  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    font-size: 1rem;
    line-height: 1.375rem;
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    font-size: 1rem;
    line-height: 1.375rem;
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 1.5rem;
    line-height: 2.25rem;
  }
`

export const StyledLink = styled.div`
  cursor: pointer;
  margin-top: 2rem;
  color: ${(p) => p.theme.colors.pacificBlue};
  font-size: 1rem;
  line-height: 1.5rem;
  font-family: ${(p) => p.theme.fontFamilies.headline};
  font-weight: ${(p) => p.theme.fontWeights.semiBolder};

  svg {
    display: none;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    svg {
      display: inline;
      margin-left: 1rem;
    }
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
`

export const StyledText = styled.div`
  overflow: hidden;
  z-index: 999;
  width: 60%;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    &.right {
      width: 50%;
      padding: 0 1rem;
    }
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    &.right {
      width: 70%;
      padding: 0 1rem;
    }
  }
`

export const StyledImage = styled.div`
  width: 50%;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  -webkit-mask-image: linear-gradient(to right, transparent 0%, black 100%);
  mask-image: linear-gradient(to right, transparent 0%, black 50%);

  img {
    height: 100%;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    &.left-to-right {
      left: 0;
      -webkit-mask-image: linear-gradient(to left, transparent 0%, black 50%);
      mask-image: linear-gradient(to left, transparent 0%, black 50%);
    }
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    width: 30%;
  }
`
