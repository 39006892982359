import { useQuery } from "@apollo/client"
import { LazyImage, Spinner, Swiper } from "@tmu/components/common"
import { OTHER_PARTNERS_QUERY } from "@tmu/src/apollo/dashboard/queries/partner"
import { getAllScreenTypes } from "@tmu/src/utils/mediaQueries"
import { FormattedMessage } from "gatsby-plugin-intl"
import { useRef } from "react"
import {
  StyledOtherPartner,
  StyledOtherPartnerLine,
  StyledOtherPartnerTitle,
  StyledOtherPartnersWrapper,
  StyledSlice,
} from "./index.styles"

const SliceOtherPartners = () => {
  const ref = useRef()

  const { loading, data } = useQuery(OTHER_PARTNERS_QUERY)

  const { isTablet, isDesktop, isWide } = getAllScreenTypes()

  const partners = data?.otherAssociates?.edges

  return (
    <StyledOtherPartnersWrapper ref={ref}>
      {loading ? (
        <Spinner />
      ) : (
        <>
          <StyledOtherPartnerTitle>
            <FormattedMessage
              id="homepage::partners::otherPartners"
              defaultMessage="Other Partners"
            />
          </StyledOtherPartnerTitle>

          <StyledSlice data-testid="other-partners">
            <Swiper isSmall={true} otherPartners={true}>
              {partners?.map(({ node: partner }) => {
                const imgSize = {
                  src: partner?.logoUrl,
                  height: isWide ? 110 : isDesktop ? 61 : isTablet ? 46 : 42,
                }

                return (
                  <StyledOtherPartner>
                    <LazyImage
                      altName={`partner-image`}
                      {...imgSize}
                      fit="cover"
                    />
                  </StyledOtherPartner>
                )
              })}
            </Swiper>
          </StyledSlice>
        </>
      )}
    </StyledOtherPartnersWrapper>
  )
}

export default SliceOtherPartners
