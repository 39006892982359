import React, { Fragment, useEffect } from "react"
import { navigate, useIntl, FormattedMessage } from "gatsby-plugin-intl"
import { SignUpForm, Callback } from "@tmu/components/auth"
import { set } from "@tmu/utils/storage"
import { useAuth, useToast } from "@tmu/hooks"
import { useMediaQuery } from "beautiful-react-hooks"
import queryString from "query-string"
import theme from "@tmu/global/theme"
import { StyledLink } from "@tmu/global/page-addons/flat-page.styles"
import { isBrowser, isTokenExpired } from "@tmu/utils/auth"
import { get } from "@tmu/utils/storage"

const { CLOUDFLARE_IMAGE_URL } = process.env

const SignUp = ({ location }) => {
  const { success: successToast, error: errorToast } = useToast()
  const { formatMessage } = useIntl()
  const { callAddReferralMutation } = useAuth()
  const paramOptions = { arrayFormat: "comma" }
  const params = queryString.parse(location.search, paramOptions)
  const next = params?.next?.replace(/\/(en|it|es)/g, "") ?? "/"
  const nextUrl = queryString.stringifyUrl({
    url: next,
    query: params,
  })
  const referralCode = params?.referral || ""
  set("referralCode", referralCode)
  const isAuthenticated = !isTokenExpired(get("token"))
  const isWide =
    typeof window !== "undefined" &&
    useMediaQuery("(min-width:" + theme.breakpoints.wide + ")")

  const imageSize = {
    width: isWide ? 240 : 141,
    height: isWide ? 240 : 141,
  }

  useEffect(() => {
    if (isAuthenticated) {
      if (params?.referral) {
        callAddReferralMutation(params?.referral)
          ?.then(({ data, errors }) => {
            if (data?.addReferralCode?.success === true) {
              successToast(
                formatMessage({
                  id: "signUp::addReferralSuccess",
                  defaultMessage:
                    "Your referral code has been added successfully.",
                })
              )
            } else {
              const errorTextId = errors?.[0]?.message?.includes(
                "does not exist"
              )
                ? "signup::referral::doesNotExist"
                : errors?.[0]?.message?.includes("does not match")
                ? "signup::referral::emailNotMatch"
                : errors?.[0]?.message?.includes("already used")
                ? "signup::referral::alreadyUsed"
                : "signup::auth::errorOccured"

              errorToast(
                formatMessage({
                  id: errorTextId,
                  defaultMessage:
                    "An error occured. Please contact TMU support!",
                })
              )
            }
          })
          .catch((err) => {
            formatMessage({
              id: "signup::auth::errorOccured",
              defaultMessage: "An error occured. Please contact TMU support!",
            })
          })
      }
      navigate(nextUrl)
    }
  }, [isAuthenticated])

  if (params.success === "true" && params.code === "email-not-verified") {
    return (
      <>
        <Callback
          dataTestId="signup-success"
          description={
            referralCode ? (
              <FormattedMessage
                id="signup::register::successReferralMessage"
                defaultMessage="Thank you for joining TMU. Enjoy your free PACs in our <store>PAC Store</store>. You can start changing the world from our <campaign>Campaigns</campaign> but first please  make sure to verify your account to sign in."
                values={{
                  store: (...chunks) => (
                    <StyledLink
                      key="visit-store"
                      to="/offers?offer=true&store=true">
                      {chunks.map((chunk, i) => (
                        <strong key={i}>{chunk}</strong>
                      ))}
                    </StyledLink>
                  ),
                  campaign: (...chunks) => (
                    <StyledLink
                      key="visit-campaign"
                      to="/campaigns?campaign=true&charity=true&event=true">
                      {chunks.map((chunk, i) => (
                        <strong key={i}>{chunk}</strong>
                      ))}
                    </StyledLink>
                  ),
                }}>
                {(...chunks) => (
                  <p>
                    {chunks.map((chunk, i) => (
                      <Fragment key={i}>{chunk}</Fragment>
                    ))}
                  </p>
                )}
              </FormattedMessage>
            ) : (
              <div className="signup-register-success">
                <img
                  className="signup-success-icon-trust-image"
                  src={
                    CLOUDFLARE_IMAGE_URL +
                    "/static/assets/images/icon-trust.svg"
                  }
                  alt={`signup-success-icon-trust-banner`}
                  {...imageSize}
                />
                <FormattedMessage
                  id="signup::register::success"
                  defaultMessage="Thank you for joining TrustMeUp!"
                  tagName="h4"
                />
                <div className="signup-success-message1">
                  <FormattedMessage
                    id="signup::register::successMessage1"
                    defaultMessage="Verify your account to continue."
                  />
                </div>
                {params?.email ? (
                  <div className="signup-success-message2">
                    <FormattedMessage
                      id="signup::register::successMessage2"
                      defaultMessage="We sent a confirmation letter to"
                    />{" "}
                    {window?.atob(params?.email)}
                  </div>
                ) : null}
              </div>
            )
          }
          clickUrl=""
          buttonLabel={<></>}
        />
      </>
    )
  }
  return (
    isBrowser &&
    !isAuthenticated && (
      <SignUpForm
        location={location}
      />
    )
  )
}

export default SignUp