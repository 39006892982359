import React from "react"
import PropTypes from "prop-types"
import { getAllScreenTypes } from "@tmu/utils/mediaQueries"
import { CommonToggle, Date, LazyImage, Money } from "@tmu/components/common"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons"
import { Link, navigate } from "gatsby-plugin-intl"
import {
  faCalendarStar,
  faHandHoldingHeart,
  faHomeHeart,
} from "@fortawesome/pro-solid-svg-icons"
import { REVIEW_STATUS, VISIBILITY_STATUS } from "@tmu/apollo/constants"
import {
  StyledCampaignTableRow,
  StyledCampaignContainer,
  StyledCampaignName,
  StyledCampaignType,
  StyledCampaignPrivacy,
  StyledCampaignDate,
  StyledCampaignDonation,
  StyledCampaignAmountContainer,
  StyledCampaignFundedAmount,
  StyledCampaignGoalAmount,
} from "./index.styles"
import { OFFER_TYPE } from "@tmu/apollo/constants"

const CampaignTableRow = ({
  style,
  slug,
  index,
  id,
  image,
  imageWidth,
  imageHeight,
  name,
  campaignType,
  isUnlisted,
  visibilityStatus,
  startsAt,
  deadline,
  fundedAmount,
  goalAmount,
  donationCount,
  status,
  onStopCampaign,
  isCharityCampaign,
  handlePrivacyClick,
  ...rest
}) => {
  const isDefaultCampaign = !onStopCampaign
  const { isDesktop } = getAllScreenTypes()
  const isCorporate = rest?.store?.defaultOffer?.offerType === OFFER_TYPE.BRAND
  const isOffline = rest?.store?.defaultOffer?.offerType === OFFER_TYPE.OFFLINE

  return (
    <>
      <StyledCampaignTableRow
        style={style}
        data-testid={
          style ? `defaultCampaign-row-${index}` : `campaign-row-${index}`
        }
        disabled={visibilityStatus === VISIBILITY_STATUS.ARCHIVED}>
        <StyledCampaignContainer>
          {isDesktop ? (
            <LazyImage
              src={
                isCorporate || isOffline
                  ? rest?.partner?.logo
                  : image ||
                    rest?.images?.edges?.[0]?.node?.image ||
                    rest?.images?.[0]
              }
              altName={
                style
                  ? `defaultCampaign-row-image-${index}`
                  : `campaign-row-image-${index}`
              }
              width={imageWidth}
              height={imageHeight}
            />
          ) : null}
          <Link to={`/campaigns/${slug}`} aria-label={"campaign " + slug}>
            <StyledCampaignName title={name}>{name}</StyledCampaignName>
          </Link>
        </StyledCampaignContainer>
        <StyledCampaignType>
          {campaignType === "EV" ? (
            <FontAwesomeIcon icon={faCalendarStar} />
          ) : style ? (
            <FontAwesomeIcon icon={faHomeHeart} />
          ) : (
            <FontAwesomeIcon icon={faHandHoldingHeart} />
          )}
        </StyledCampaignType>
        <StyledCampaignPrivacy
          disabled={isDefaultCampaign}
          onClick={() => handlePrivacyClick(id, visibilityStatus)}>
          {visibilityStatus === VISIBILITY_STATUS.LISTED &&
          rest?.startsAt !== null ? (
            <FontAwesomeIcon icon={faEye} />
          ) : (
            <FontAwesomeIcon icon={faEyeSlash} />
          )}
        </StyledCampaignPrivacy>
        <StyledCampaignDate>
          {startsAt ? (
            <Date
              value={startsAt}
              showYear={true}
              year="2-digit"
              month="2-digit"
              day="2-digit"
              hour={null}
              minute={null}
            />
          ) : (
            "-"
          )}
        </StyledCampaignDate>
        <StyledCampaignDate>
          {deadline ? (
            <Date
              value={deadline}
              showYear={true}
              year="2-digit"
              month="2-digit"
              day="2-digit"
              hour={null}
              minute={null}
            />
          ) : (
            "-"
          )}
        </StyledCampaignDate>
        <StyledCampaignDonation>{donationCount}</StyledCampaignDonation>
        <StyledCampaignAmountContainer>
          <StyledCampaignFundedAmount>
            {fundedAmount ? <Money value={fundedAmount} /> : "-"}
          </StyledCampaignFundedAmount>
          <StyledCampaignGoalAmount>
            {goalAmount ? <Money value={goalAmount} /> : null}
          </StyledCampaignGoalAmount>
        </StyledCampaignAmountContainer>
        <CommonToggle
          className="toggle"
          controlled
          checked={
            visibilityStatus !== VISIBILITY_STATUS.ARCHIVED &&
            status !== "ENDED"
              ? true
              : false
          }
          handleChange={() => {
            visibilityStatus === VISIBILITY_STATUS.ARCHIVED
              ? isCharityCampaign
                ? navigate(`/dashboard/campaign/edit/${slug}`)
                : {}
              : onStopCampaign({ id, visibilityStatus })
          }}
          disabled={
            isDefaultCampaign || status === REVIEW_STATUS.ENDED
              ? true
              : !isCharityCampaign &&
                visibilityStatus === VISIBILITY_STATUS.ARCHIVED
          }
        />
      </StyledCampaignTableRow>
    </>
  )
}

CampaignTableRow.propTypes = {
  style: PropTypes.string,
  index: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  id: PropTypes.string,
  image: PropTypes.object,
  imageWidth: PropTypes.number,
  imageHeight: PropTypes.number,
  name: PropTypes.string,
  campaignType: PropTypes.string,
  isUnlisted: PropTypes.bool,
  startsAt: PropTypes.string,
  deadline: PropTypes.string,
  fundedAmount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  goalAmount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  donationCount: PropTypes.number,
  status: PropTypes.string,
  onStopCampaign: PropTypes.func,
}

export default CampaignTableRow
