import React, {
  Fragment,
  useEffect,
  useContext,
  useCallback,
  useState,
} from "react"
import PropTypes from "prop-types"
import { navigate, useIntl } from "gatsby-plugin-intl"
import "@emotion/react"
import { useLocation } from "@reach/router"
import { ThemeProvider } from "@emotion/react"
import theme from "@tmu/global/theme"
import GlobalStyle from "@tmu/global/GlobalStyle"
import FontStyles from "@tmu/global/FontStyles"
import { Button, Header, Footer, CookieMessage } from "@tmu/components/common"
import { useSalesIQ } from "@tmu/hooks"
import { HomePageContext } from "@tmu/context/homePageContext"
import { FOOTER_TYPE } from "@tmu/apollo/constants"
import "@fortawesome/fontawesome-svg-core/styles.css"
import { config } from "@fortawesome/fontawesome-svg-core"
import { useAuth, useToast } from "@tmu/hooks"
import { getAllScreenTypes } from "@tmu/utils/mediaQueries"

let toastId

const Layout = ({ children }) => {
  const { pathname } = useLocation()
  const next = pathname ? pathname.replace(/\/(en|it|es)/g, "") : "/user-space"
  const { formatMessage, locale } = useIntl()
  const { isAuthenticated } = useAuth()
  const { info: infoToast, toast, TOAST_THEME } = useToast()
  const { footerType } = useContext(HomePageContext)
  config.autoAddCss = false
  const { isTablet } = getAllScreenTypes()
  const [prevLocale, setPrevLocale] = useState(locale)

  const hideFooterArr = [
    "signup",
    "signin",
    "password-recovery",
    "reset-password",
    "/donate",
    "/donation-success",
    "/dashboard",
    "/terms",
    "/policy",
  ]

  const hideFooter = new RegExp(hideFooterArr.join("|")).test(pathname)

  const getInfoToast = useCallback(() => {
    if (!isAuthenticated) {
      toastId = setTimeout(() => {
        infoToast(
          <div className="register-info-toast-free-account-title">
            <h4>
              {formatMessage({
                id: "signUp::titleFreeAccount",
                defaultMessage: "Create your free account",
              })}
            </h4>
            <p>
              {formatMessage(
                {
                  id: "signUp::subtitleFreeAccount",
                  defaultMessage:
                    "You will receive a <pacs>10 Euro/PAC</pacs> gift for use as a discount on your purchases. Additionally, you'll get a tax-deductible receipt after each donation.",
                },
                {
                  pacs: (...chunks) => (
                    <span key="pacs" className="pacs">
                      {chunks.map((chunk, i) => (
                        <Fragment key={i}>{chunk}</Fragment>
                      ))}
                    </span>
                  ),
                }
              )}
            </p>
            <div>
              <Button
                data-testid="create-account"
                color="blue"
                onClick={() => navigate(`/signup?next=${next || "/"}`)}
                className="register-info-toast-create-account-btn">
                {formatMessage({
                  id: "signUp::createAccount",
                  defaultMessage: "Create Account",
                })}
              </Button>
            </div>
            <div className="register-info-toast-flex">
              <span>
                {formatMessage({
                  id: "signIn::or",
                  defaultMessage: "or",
                })}
              </span>
              <Button
                data-testid="sign-in"
                variant="link"
                text={formatMessage({
                  id: "signIn::signIn",
                  defaultMessage: "Sign In",
                })}
                onClick={() => {
                  navigate(`/signin?next=${next || "/"}`)
                }}
                className="register-info-toast-sign-in-btn"
              />
            </div>
          </div>,
          toast.POSITION.BOTTOM_RIGHT,
          null,
          false,
          TOAST_THEME.LIGHT,
          "register-info-toast"
        )
      }, 30000) // 30 seconds delay
    }
  }, [isAuthenticated, locale, prevLocale])

  useEffect(() => {
    if (isAuthenticated || !isTablet) {
      toast.dismiss()
      toastId && clearTimeout(toastId)
      return
    } else {
      getInfoToast()
    }

    if (locale !== prevLocale) {
      setPrevLocale(locale)
      toast.dismiss()
      toastId && clearTimeout(toastId)
    }
  }, [isAuthenticated, isTablet, locale, prevLocale])

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <FontStyles />
      <CookieMessage />
      <Header />
      <div className="page-wrapper">{children}</div>
      {!hideFooter && footerType !== FOOTER_TYPE.HIDE && <Footer />}
      {useSalesIQ &&
        useSalesIQ(
          "https://salesiq.zoho.eu/widget",
          "1528036924802e013fd16cea7787c58542678492d1a4b2b0d843951d37d45636299c7a1b158026b1647f3128ed5f10e8"
        )}
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
