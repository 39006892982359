import { gql } from "@apollo/client"
import { partnerListingParts } from "@tmu/apollo/storefront/fragments/partner"
import { capitalize } from "@tmu/utils/string"

export const PARTNER_LISTING_QUERY = gql`
  query PartnerListing(
    $country: String
    $orderBy: String
    $first: Int
    $after: String
    $categories: [ID]
    $search: String
    $slug: String
  ) {
    allPartners(
      first: $first
      after: $after
      country: $country
      orderBy: $orderBy
      categories: $categories
      search: $search
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          ...partnerListingParts
        }
      }
    }
  }
  ${partnerListingParts}
`

export const PARTNER_GET_STARTED_PAGE_QUERY = gql`
  query {
    allPartners(first: 8, isFeatured: true, orderBy: "-isFeatured,-created") {
      edges {
        node {
          ...partnerListingParts
        }
      }
    }
  }
  ${partnerListingParts}
`

export const PARTNER_DETAIL_QUERY_PARTNER = ({ locale }) => {
  const localeCapital = capitalize(locale)

  return gql`
    query PartnerDetail($slug: String!) {
      partner(slug: $slug) {
        id
        slug
        name
        logo
        logo${localeCapital}
        images {
          edges {
            node {
              id
              image
            }
          }
        }
    }
  }`
}
export const PARTNER_DETAIL_QUERY = ({ locale }) => {
  const localeCapital = capitalize(locale)

  return gql`
    query PartnerDetail($slug: String!) {
      partner(slug: $slug) {
        id
        slug
        name
        displayName
        logo${localeCapital}
        description
        description${localeCapital}
        shortDescription
        shortDescription${localeCapital}
        whySupport${localeCapital}
        taxId
        website
        youtube
        instagram
        facebook
        twitter
        linkedin
        donationCount
        fundedAmount
        isEligibleForDonationPreference
        isEligibleForSupporterEvent
        isEligibleForSupporterCollection
        defaultCampaign {
          id
          slug
          name
          name${localeCapital}
          status
          pacMultiplier
          isAvailable
          goalAmount
        }
        images {
          edges {
            node {
              id
              image
            }
          }
        }
        supporterCampaigns: campaigns(first: 100, isSupporter: true, orderBy: "-created") {
          edges {
            node {
              name
              name${localeCapital}
              isListed
              slug
              created
              fundedAmount
              image{
                url
              }
              images {
                edges {
                  node {
                    id
                    image
                  }
                }
              }
            }
          }
        }
        partnerCampaigns: campaigns(first: 100, isSupporter: false, isStoreSupporter: false) {
          edges {
            node {
              name
              name${localeCapital}
              isListed
              slug
              isDefaultCampaign
              images{
                edges {
                  node {
                    id
                    image
                  }
                }
              }
            }
          }
        }
      }
      topDonor: allDonors(first: 1,partner_Slug: $slug, orderBy: "-total") {
        edges {
          node {
            id
            total
            latestDonationDate
            isAnonymous
            user {
              fullName
              profileImage
            }
          }
        }
  } 
      latestDonors: allDonors(first: 5, partner_Slug: $slug, orderBy: "-latestDonationDate") {
        totalCount
        edges {
          node {
            id
            total
            latestDonationDate
            isAnonymous
            user {
              id
              fullName
              profileImage
            }
          }
        }
      }
    }`
}

export const GET_ALL_DONATION_ELIGIBLE_PARTNERS = gql`
  query DonationEligiblePartners($first: Int) {
    allCampaigns(first: $first, fundraiserType: ["charities"]) {
      edges {
        node {
          id
          name
          partner {
            id
            taxId
          }
        }
      }
    }
  }
`

export const ALL_SUBSCRIPTION_PRODUCTS = gql`
  query allSubscriptionProducts {
    allSubscriptionProducts(first: 1) {
      edges {
        node {
          id
          name
          subscriptionAmountOne
          subscriptionAmountTwo
          subscriptionAmountThree
          descriptionOneEn
          descriptionOneIt
          descriptionOneEs
          descriptionTwoEn
          descriptionTwoIt
          descriptionTwoEs
          descriptionThreeEn
          descriptionThreeIt
          descriptionThreeEs
          campaign {
            id
            name
          }
        }
      }
    }
  }
`
