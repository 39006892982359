import styled from "@emotion/styled"

export const StyledCheckboxContainer = styled.div`
  text-align: left;
  position: relative;
  width: 100%;

  label {
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
    user-select: none;
    padding-left: 2rem;
    a {
      margin-bottom: 0.9375rem;
      cursor: pointer;
      text-decoration: none;
    }

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 1.25rem;
      width: 1.25rem;
      z-index: 2;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }

    .checkmark {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      box-sizing: border-box;
      height: 1.25rem;
      width: 1.25rem;
      opacity: 0.5;
      border-radius: 0.25rem;
      background-color: ${(p) => p.theme.colors.white};
      border: 0.0625rem solid
        ${(p) => p.theme.colors[p.error && p.touched ? "red" : "disabled"]};
    }

    &:hover input ~ .checkmark {
      border: 0.0625rem solid ${(p) => p.theme.colors.blue};
    }

    input:checked ~ .checkmark {
      background-color: ${(p) => p.theme.colors.blue};
      border: 0.0625rem solid ${(p) => p.theme.colors.blue};
      opacity: 1;
    }

    .checkmark:after {
      content: "";
      position: absolute;
      display: none;
    }

    input:checked ~ .checkmark:after {
      display: block;
    }

    .checkmark:after {
      left: 0.325rem;
      top: 0.04rem;
      width: 0.3rem;
      height: 0.625rem;
      border: solid ${(p) => p.theme.colors.white};
      border-width: 0 0.1875rem 0.1875rem 0;
      transform: rotate(40deg);
      opacity: 1;
      background: ${(p) => p.theme.colors.blue};
    }
    .text-message {
      width: fit-content;
      line-height: normal;
      margin: 0;
      button {
        float: none;
        @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
          font-size: 1.5rem;
        }
      }
    }

    .isSubscribed {
      font-size: 1rem;
      color: rgb(99, 106, 117);
    }
  }
  .field-error {
    position: absolute;
    height: 1rem;
    padding-left: 2rem;
  }
  .input-feedback {
    bottom: 0;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    label {
      padding-left: 3rem;
      font-size: 1.5rem;
      input {
        height: 1.5rem;
        width: 1.5rem;
      }
      .checkmark {
        height: 1.5rem;
        width: 1.5rem;
      }
      .checkmark:after {
        left: 0.4rem;
        top: 0.04rem;
        width: 0.4rem;
        height: 0.825rem;
      }
    }
    .field-error {
      padding-left: 3rem;
    }
    p,
    a {
      font-size: 1.5rem !important;
    }
  }
`
