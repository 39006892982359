import React, { createContext, useContext, useCallback } from "react"
import { toast, ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { TOAST_THEME } from "@tmu/apollo/constants"

const ToastContext = createContext()

const useProvideToast = () => {
  const success = useCallback(
    (
      message,
      position = toast.POSITION.TOP_RIGHT,
      onClose = () => {},
      autoClose,
      theme = TOAST_THEME.COLORED,
      className
    ) => {
      toast.success(message, {
        position,
        onClose,
        autoClose,
        theme,
        className,
      })
    }
  )

  const error = useCallback(
    (
      message,
      position = toast.POSITION.TOP_RIGHT,
      onClose = () => {},
      autoClose,
      theme = TOAST_THEME.COLORED,
      className
    ) => {
      toast.error(message, {
        position,
        onClose,
        autoClose,
        theme,
        className,
      })
    }
  )

  const info = useCallback(
    (
      message,
      position = toast.POSITION.TOP_RIGHT,
      onClose = () => {},
      autoClose,
      theme = TOAST_THEME.COLORED,
      className
    ) => {
      toast.info(message, {
        position,
        onClose,
        autoClose,
        theme,
        className,
      })
    }
  )

  const warning = useCallback(
    (
      message,
      position = toast.POSITION.TOP_RIGHT,
      onClose = () => {},
      autoClose,
      theme = TOAST_THEME.COLORED,
      className
    ) => {
      toast.warning(message, {
        position,
        onClose,
        autoClose,
        theme,
        className,
      })
    }
  )

  return { success, error, info, warning, toast, TOAST_THEME }
}

export function ToastProvider({ children }) {
  const toast = useProvideToast()

  return (
    <ToastContext.Provider value={toast}>
      <ToastContainer
        containerId="tmu-global-messaging"
        autoClose={1200}
        hideProgressBar
        newestOnTop={true}
        closeOnClick
        pauseOnFocusLoss
        pauseOnHover
        draggable
        theme={TOAST_THEME.COLORED}
        icon={false}
        limit={3}
      />
      {children}
    </ToastContext.Provider>
  )
}

export const useToast = () => {
  return useContext(ToastContext)
}
