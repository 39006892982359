import { partnerParts } from "./partner"

export const partnerCampaignListingParts = `
  fragment partnerCampaignListingParts on CampaignNode {
    id
    slug
    nameEn
    nameIt
    nameEs
    isAvailable
    images {
      edges {
        node {
          id
          image
          order
          __typename
        }
      }
    }
    partner {
      id
      name
      displayName
      logo
      logoEn
      logoIt
      logoEs
      __typename
    }
    descriptionEn
    descriptionIt
    descriptionEs
    shortDescriptionEn
    shortDescriptionIt
    shortDescriptionEs
    isUnlisted
    visibilityStatus
    eventAddress
    eventMapLink
    eventStartsAt
    virtualEventLink
    enableDonationNote
    tiers {
      edges {
        node {
          id,
          campaign {
            id
            name
          },
          description,
          donationAmount,
          donationAmountOperator,
          maxTickets,
          isUnlimitedTickets,
          descriptionEn,
          descriptionIt,
          descriptionEs,
          __typename
        }
      }
    }
    goalAmount
    fundedAmount
    donationCount
    startsAt
    deadline
    campaignType
    status
    created
    __typename
  }
`

export const partnerCampaignListingReportParts = `
  fragment partnerCampaignListingReportParts on CampaignNode {
    id  
    status
    nameEn
    nameIt
    nameEs
    __typename
  }
`

export const partnerCampaignDetailParts = `
  fragment partnerCampaignDetailParts on CampaignNode {
    id
    slug
    nameEn
    nameIt
    nameEs
    image
    stripePublicKey
    images {
      edges {
        node {
          id
          image
          order
          __typename
        }
      }
    }
    descriptionEn
    descriptionIt
    descriptionEs
    shortDescriptionEn
    shortDescriptionIt
    shortDescriptionEs
    isUnlisted
    enableDonationNote
    eventAddress
    eventMapLink
    eventStartsAt
    virtualEventLink
    tiers {
      edges {
        node {
          id,
          campaign {
            id
            name
          },
          description,
          donationAmount,
          donationAmountOperator,
          maxTickets,
          isUnlimitedTickets,
          descriptionEn,
          descriptionIt,
          descriptionEs,
          __typename
        }
      }
    }
    video
    goalAmount
    fundedAmount
    donationCount
    created
    deadline
    startsAt
    campaignType
    isAvailable
    status
    faqs {
        edges {
          node {
            id
            questionEn
            questionIt
            questionEs
            answerEn
            answerIt
            answerEs
          }
        }
      }
      updates {
        edges {
          node {
            id
            titleEn
            titleIt
            titleEs
            contentEn
            contentIt
            contentEs
            created
            modified
            date
            image
          }
        }
      }
    categories {
      edges {
        node {
          id
          slug
          nameEn
          nameIt
          nameEs
          __typename
        }
      }
    }
    partner {
      id
      donationCount
      fundedAmount
      taxId
      __typename
    }
  }
`

export const partnerCampaignDetailPartsForPartner = `
  fragment partnerCampaignDetailPartsForPartner on CampaignNode {
    id
    slug
    nameEn
    nameIt
    nameEs
    images {
      edges {
        node {
          id
          image
          order
          __typename
        }
      }
    }
    descriptionEn
    descriptionIt
    descriptionEs
    shortDescriptionEn
    shortDescriptionIt
    shortDescriptionEs
     supportedCampaign{
        id
        slug
        name
        nameEn
        nameIt
        nameEs
      }
    video
    goalAmount
    fundedAmount
    donationCount
    created
    deadline
    startsAt
    campaignType
    isVoucher
    isReservationRequired
    isAvailable
    status
    isUnlisted
    enableDonationNote
    visibilityStatus
    eventAddress
    eventMapLink
    eventStartsAt
    virtualEventLink
    tiers {
      edges {
        node {
          id,
          campaign {
            id
            name
          },
          description,
          donationAmount,
          donationAmountOperator,
          maxTickets,
          isUnlimitedTickets,
          descriptionEn,
          descriptionIt,
          descriptionEs,
          __typename
        }
      }
    }
    faqs {
        edges {
          node {
            id
            questionEn
            questionIt
            questionEs
            answerEn
            answerIt
            answerEs
          }
        }
      }
      updates {
        edges {
          node {
            id
            titleEn
            titleIt
            titleEs
            contentEn
            contentIt
            contentEs
            created
            modified
            date
            image
          }
        }
      }
    categories {
      edges {
        node {
          id
          slug
          nameEn
          nameIt
          nameEs
          __typename
        }
      }
    }
    partner {
      id
      name
      logo
      logoEn
      logoEs
      logoIt
      images {
        edges {
          node {
            id
            image
            order
            __typename
          }
        }
    }
    }
  }
`
export const merchantCampaignDetailParts = `
  fragment merchantCampaignDetailParts on CampaignNode {
    id
    slug
    nameEn
    nameIt
    nameEs
    descriptionEn
    descriptionIt
    descriptionEs
    shortDescriptionEn
    shortDescriptionIt
    shortDescriptionEs
    video
    goalAmount
    fundedAmount
    donationCount
    created
    deadline
    startsAt
    campaignType
    isAvailable
    status
    isUnlisted,
    visibilityStatus,
    eventAddress
    eventMapLink
    eventStartsAt
    virtualEventLink
    enableDonationNote
    partner {
      id
      taxId
    }
  }
`

export const campaignCategoryParts = `
  fragment campaignCategoryParts on CampaignCategoryNode {
    id
    slug
    nameEn
    nameIt
    nameEs
    icon
    __typename
  }
`
export const partnerCampaignUpdateParts = `
  fragment partnerCampaignUpdateParts on CampaignUpdateNode {
    id
    isActive
    title
    titleEn
    titleIt
    titleEs
    content
    contentEn
    contentIt
    contentEs
    date
    image
    created
    modified
    campaign {
      id
      name
    }
    __typename
  }
`
export const partnerCampaignFaqParts = `
  fragment partnerCampaignFaqParts on CampaignFaqNode {
    id
    isActive
    created
    modified
    question
    questionEn
    questionIt
    questionEs
    answer
    answerEn
    answerIt
    answerEs
    campaign {
      id
      name
    }
    __typename
  }
`

export const partnerDonationTierParts = `
fragment partnerDonationTierParts on DonationTierNode {
  id
  description
  descriptionEn
  descriptionEs
  descriptionIt
  donationAmount
  donationAmountOperator
  maxTickets
  isUnlimitedTickets
  isSoldOut
  campaign {
    id
    name
  }
  __typename
}`

export const campaignCategoryPartsForDonateByShopping = `
  fragment campaignCategoryPartsForDonateByShopping on CampaignNode {
    id
    slug
    name
    nameEn
    nameIt
    nameEs
    deadline
    image {
      url
      cache
    }
    descriptionEn
    descriptionEs
    descriptionIt
    stripePublicKey
    goalAmount
    donationCount
    fundedAmount
    campaignType
    partner {
      logo
      logoEn
      logoEs
      logoIt
      fundedAmount
      donationCount
      slug
      displayName
      name
      taxId
    }
    faqs {
      edges {
        node {
          id
          questionEn
          questionIt
          questionEs
          answerEn
          answerIt
          answerEs
        }
      }
    }
    __typename
  }
`
