import { faPlus } from "@fortawesome/pro-light-svg-icons/faPlus"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  Button,
  CustomModal,
  Date,
  Logo,
  Money,
  SaleDetail,
  Spinner,
  Table,
} from "@tmu/components/common"
import { EmptyTable } from "@tmu/components/dashboard/dashboardCommon"
import { StyledPageActions } from "@tmu/src/global/page-addons/dashboard.styles"
import theme from "@tmu/src/global/theme"
import { useMerchants } from "@tmu/src/hooks"
import { getAllScreenTypes } from "@tmu/utils/mediaQueries"
import { FormattedMessage, useIntl } from "gatsby-plugin-intl"
import { useEffect, useState } from "react"
import {
  StyledPacContainer,
  StyledPageWrapper,
  StyledTable,
} from "./index.styles"

const SalesTable = ({ searchText, filters }) => {
  const { formatMessage } = useIntl()
  const [detailStatus, setDetailStatus] = useState(false)
  const [selectedRow, setSelectedRow] = useState(null)
  const [sortInfo, setSortInfo] = useState([{ id: "date", desc: true }])
  const [firstLoad, setFirstLoad] = useState(false)
  const {
    callMerchantOrders,
    callMerchantOrdersRefetch,
    sales,
    pageInfo,
    loading,
    fetchMore,
  } = useMerchants()
  const { isTablet, isWide } = getAllScreenTypes()
  var orderCallInterval = null

  useEffect(() => {
    callMerchantOrders({ variables: { search: searchText, ...filters } }).then(
      (r) => {
        setFirstLoad(true)
      }
    )
  }, [])

  useEffect(() => {
    clearTimeout(orderCallInterval)
    orderCallInterval = setTimeout(
      () => callMerchantOrdersRefetch({ search: searchText, ...filters }),
      1000
    )
  }, [searchText])

  useEffect(() => {
    if (Object.keys(filters)?.length) {
      callMerchantOrdersRefetch({ search: searchText, ...filters })
    }
  }, [filters])

  const data = !sales
    ? []
    : sales?.map(({ node }) => {
        return {
          id: node?.id,
          date: node?.created,
          offerName: node?.cartItems?.edges?.[0]?.node?.offer?.name,
          pacAmount: node?.pacAmount,
          total: node?.total,
          sale: node,
        }
      })

  const openDonationDetail = (row) => {
    setSelectedRow(row)
    setDetailStatus(true)
  }

  const closeDonationDetail = () => {
    setSelectedRow(null)
    setDetailStatus(false)
  }

  const columns = [
    {
      Header: (props) => (
        <div className="header-text extra-padding">
          {isTablet ? (
            <FormattedMessage
              id="dashboard::tableHeader::date"
              defaultMessage="Date"
            />
          ) : (
            <FormattedMessage
              id="dashboard::tableHeader::offerAndDate"
              defaultMessage="Offer and Date"
            />
          )}
        </div>
      ),
      Cell: ({ value, row }) => {
        return (
          <div>
            {isTablet ? (
              <p>
                <Date value={value} showYear={true} second="numeric" />
              </p>
            ) : (
              <>
                <p>{row?.values?.offerName}</p>
                <p className="mobile-date">
                  <Date value={value} showYear={true} second="numeric" />
                </p>
              </>
            )}
          </div>
        )
      },
      accessor: "date",
      disableSort: true,
    },
    {
      Header: (props) => (
        <div className="header-text extra-padding">
          <FormattedMessage
            id="dashboard::tableHeader::offerName"
            defaultMessage="Offer Name"
          />
        </div>
      ),
      Cell: ({ row, value }) => {
        return <div>{value}</div>
      },
      accessor: "offerName",
      disableSort: true,
    },
    {
      Header: (props) => (
        <div className="header-text align-right">
          <FormattedMessage
            id="dashboard::tableHeader::pacsSpend"
            defaultMessage="Pac Spent"
          />
        </div>
      ),
      Cell: ({ value }) => {
        return (
          <StyledPacContainer className="align-right">
            <div>
              - <Money value={value} currency={false} />
            </div>
            <Logo
              className="pac-icon"
              width={isWide ? 25 : 19}
              height={isWide ? 32 : 24}
              fill={theme?.colors?.disabled}
            />
          </StyledPacContainer>
        )
      },
      accessor: "pacAmount",
      disableSort: true,
    },
    {
      Header: (props) => (
        <div className="header-text align-right">
          <FormattedMessage
            id="dashboard::tableHeader::amount"
            defaultMessage="Amount"
          />
        </div>
      ),
      Cell: ({ value }) => {
        return (
          <div className="align-right">
            <Money value={value} />
          </div>
        )
      },
      accessor: "total",
      disableSort: true,
    },
  ]

  const hiddenColumns = isTablet ? [] : ["offerName", "pacAmount"]

  const handleRowClick = (row, index) => {
    openDonationDetail(row)
    if (typeof onRowSelect === "function") {
      onRowSelect(row, index)
    }
  }

  const handleSort = (sortBy) => {
    setSortInfo(sortBy)

    const sortValue = getSortValue(sortBy)
  }

  const getSortValue = (sortBy) => {
    const sortData = sortBy?.at(0)

    const direction = sortData?.desc === true ? "-" : ""
    let sortField = null

    switch (sortData?.id) {
      case "date":
        sortField = "created"
        break
      case "amount":
        sortField = "amount"
        break
      case "pacs":
        sortField = "earnedPac"
        break

      default:
        sortField = null
        break
    }
    return sortField ? direction + sortField : null
  }

  const handleLoadMore = () => {
    fetchMore({
      variables: {
        after: pageInfo?.endCursor,
        orderBy: getSortValue(sortInfo),
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        const newEdges = fetchMoreResult?.allOrders?.edges
        const pageInfo = fetchMoreResult?.allOrders?.pageInfo

        const newResult = newEdges?.length
          ? {
              ...prev,
              allOrders: {
                ...prev.allOrders,
                edges: [...prev.allOrders?.edges, ...newEdges],
                pageInfo,
              },
            }
          : prev

        return newResult
      },
    })
  }

  return !firstLoad || loading ? (
    <Spinner condensed />
  ) : sales?.length > 0 ? (
    <StyledPageWrapper>
      <StyledTable>
        <Table
          columns={columns}
          data={data}
          hiddenColumns={hiddenColumns}
          headerLineSeperator={true}
          onRowClick={!isTablet ? handleRowClick : null}
          initialSort={sortInfo}
          handleSort={handleSort}
          options={{ sort: true }}
        />
      </StyledTable>
      <CustomModal
        isModalOpen={!isTablet && selectedRow && detailStatus}
        cancelButtonAction={closeDonationDetail}
        children={
          <SaleDetail
            order={selectedRow?.original?.sale}
            rowData={selectedRow}
          />
        }
        isMobile={!isTablet}
        isCloseIcon={true}
        style={{
          overflow: "hidden",
        }}
      />
      {pageInfo?.hasNextPage && (
        <StyledPageActions align="center" data-testid="view-more">
          <Button
            data-testid="button-view-more"
            label="View More"
            variant="text"
            onClick={handleLoadMore}>
            <FontAwesomeIcon icon={faPlus} />
            <FormattedMessage id="ui::viewMore" defaultMessage="View More" />
          </Button>
        </StyledPageActions>
      )}
    </StyledPageWrapper>
  ) : (
    <EmptyTable
      emptyMessage={
        <FormattedMessage
          id="dashboard::sales::empty"
          defaultMessage="It seems there are no sales yet"
        />
      }
    />
  )
}

export default SalesTable
