import styled from "@emotion/styled"
import { css } from "@emotion/react"

export const StyledDonationOptionsWrapper = styled.div``

export const StyledDescriptionSection = styled.div`
  .description {
    margin: 1.5rem 0 !important;
    height: 7.5rem;
    label {
      transform: translate(-0.5rem, -7.7rem) scale(0.8) !important;
    }
  }
  button {
    width: 100%;
    margin: 2rem 0;
  }
`

export const StyledDonationOptionItem = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 0.5rem;
  margin-top: 2rem;

  .currency-icon:before {
    content: "€" !important;
    top: 0.1187rem;
    position: relative;
    /*position: absolute;
    margin-left: -1.5625rem;
    top: 30%;*/
  }

  /*.float-container {
    padding-left: unset;
  }*/

  input {
    padding: 1rem 0.1rem;
  }
`

export const StyledShareOptionsWrapper = styled.div`
  margin-top: 2rem;
  ${(p) =>
    p.disabled &&
    css`
      pointer-events: none;
    `}
`

export const StyledShareOptionsItem = styled.div`
  margin-bottom: 1rem;
  border: none !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1rem;
  line-height: 1.25rem;
  background: none !important;
  color: ${(p) => p.theme.colors.pitchBlack};
  &:hover {
    background: none !important;
  }
`
