import { gql } from "@apollo/client"

export const ME_QUERY_STR = `
  query Me {
    me {
      id
      fullName
      displayName
      profileImage
      firstName
      lastName
      email
      certifiedBusinessEmail
      pacBalance
      city
      country
      region
      postCode
      addressLine1
      addressLine2
      taxId
      phoneNumber
      isDonor
      isPartner
      isMerchant
      isSubscribed
      communicationLanguage
      isAnonymousDonor
      accountType
      isStaff
      isActive
      intercomIdentity
      isNewlyRegisteredAndGotSignupGift
      merchantUsers {
        edges {
          node {
            id
            store {
              id
              name
              displayName
              logo
              defaultOffer{
                offerType
              }
            }
            finalPermissions
            apiRole {
              id
              name
              codename
              accessType
            }
          }
        }
      }
      activeStore {
        id
        name
        logo
        displayName
        defaultOffer {
          offerType
        }
      }
      userPreference {
        campaign {
          id
          name
          nameEn
          nameIt
          nameEs
          pacMultiplier
          image {
            url
            cache
          }
          partner {
            id
            logo
            logoEn
            logoIt
            logoEs
          }
        }
        campaignCategories {
          edges {
            node {
              id
              name
            }
          }
        }
        productCategories {
          edges {
            node {
              id
              name
              nameEn
              nameIt
              nameEs
            }
          }
        }
      }
    }
  }
`

export const ME_QUERY = gql`
  ${ME_QUERY_STR}
`

export const REFERRAL_CODE = gql`
  query ReferralCode($code: String!, $email: String) {
    referral(code: $code, email: $email) {
      code
      validUntil
      escalation
      params
    }
  }
`

export const PARTNER_API_PERMISSIONS = gql`
  query PartnerApiPermissions {
    me {
      id
      partnerUsers {
        edges {
          node {
            id
            apiRole {
              id
              name
              codename
              accessType
              apiPermissions {
                edges {
                  node {
                    codename
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export const MERCHANT_API_PERMISSIONS = gql`
  query MerchantApiPermissions {
    me {
      id
      merchantUsers {
        edges {
          node {
            id
            finalPermissions
          }
        }
      }
    }
  }
`

export const SINGLE_MERCHANT_API_PERMISSIONS = gql`
  query SingleMerchantApiPermissions($id: ID!) {
    merchantUser(id: $id) {
      id
      finalPermissions
      apiRole {
        id
        name
        codename
        accessType
      }
    }
  }
`

export const NOTIFICATIONS_QUERY = gql`
  query AllNotifications {
    allNotifications(isRead: false, first: 10) {
      edges {
        node {
          content
          href
          isRead
          id
        }
      }
    }
  }
`

export const USER_DONATION_PREFERENCE_QUERY = gql`
  query UserDonationPreference {
    me {
      id
      userPreference {
        campaign {
          id
          name
        }
      }
    }
  }
`

export const OFFER_LINK_WITH_TOKEN = gql`
  query OfferLinkWithToken($offerId: ID) {
    offerLinkWithToken(offerId: $offerId) {
      offerId
      offerLink
    }
  }
`
export const ALL_SUBSCRIPTION_PRODUCTS = gql`
  query allSubscriptionProducts($partner: ID, $campaign: ID) {
    allSubscriptionProducts(first: 1, partner: $partner, campaign: $campaign) {
      edges {
        node {
          id
          name
          subscriptionAmountOne
          subscriptionAmountTwo
          subscriptionAmountThree
          descriptionOne
          descriptionOneEn
          descriptionOneIt
          descriptionOneEs
          descriptionTwo
          descriptionTwoEn
          descriptionTwoIt
          descriptionTwoEs
          descriptionThree
          descriptionThreeEn
          descriptionThreeIt
          descriptionThreeEs
          campaign {
            id
            name
          }
        }
      }
    }
  }
`

export const MERCHANT_PAC_RECONCILIATION_QUERY = gql`
  query MerchantPacReconciliation($id: ID!) {
    merchantPacReconciliation(id: $id) {
      id
      campaign {
        id
        name
        slug
      }
      offer {
        id
        name
        slug
      }
      user {
        id
        fullName
      }
      transaction {
        id
      }
      status
      strategy
      currency
      totalAmount
      offerMaxPacDiscount
      totalPacsRequired
      donationAmount
      normalizedDonationAmount
      pacMultiplierSurplus
      userPacBalanceAfterPurchase
      netEarnedPacsAfterDonation
      pacAmountAfterNormalizedDonation
      userEmailStatus
      storeEmailStatus
    }
  }
`

export const DONORS_LIST_QUERY = gql`
  query AllDonorsQuery(
    $first: Int
    $campaign_Slug: String
    $partner_Slug: String
    $orderBy: String
    $after: String
  ) {
    allDonors(
      first: $first
      campaign_Slug: $campaign_Slug
      partner_Slug: $partner_Slug
      orderBy: $orderBy
      after: $after
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          id
          user {
            id
            fullName
            profileImage
          }
          isAnonymous
          latestDonationDate
          total
        }
      }
    }
  }
`

export const USER_EXISTS_QUERY = gql`
  query UserExists($email: String) {
    userExists(email: $email)
  }
`
