import React, { useState } from "react"
import theme from "@tmu/global/theme"
import { useAuth } from "@tmu/hooks"
import { LanguageBar } from "@tmu/components/common"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowLeft } from "@fortawesome/pro-solid-svg-icons/faArrowLeft"
import {
  StyledGetStartedButtonContainerMobile,
  StyledGetStartedButtonMobile,
  StyledLanguageMenuItem,
  StyledLine,
  StyledMobileLanguageBar,
  StyledMobileMenuItem,
  StyledMobileMenuItemList,
  StyledMobileMenuWrapper,
  StyledMobileNavBarLogo,
} from "../index.styles"
import NavbarLogo from "./NavbarLogo"
import { navigate, FormattedMessage } from "gatsby-plugin-intl"
import { getAllScreenTypes } from "@tmu/utils/mediaQueries"
import {
  StyledLanguageHeader,
  StyledLanguageHeaderContainer,
} from "../../Footer/index.styles"

const MobileMenu = ({ menuItems, closeModal, handleSignup }) => {
  const { isAuthenticated } = useAuth()
  const [languageStatus, setLanguageStatus] = useState(false)
  const { isTablet } = getAllScreenTypes()

  let menuItemsToList = isAuthenticated
    ? menuItems
    : menuItems?.filter((item) => item?.public)

  if (!isTablet) {
    menuItemsToList = menuItemsToList?.filter((item) => item?.mobile === true)
  }

  const languageList = <LanguageBar isList={true} disabled height={24} />

  const languageSelector = (
    <div
      onClick={() => {
        setLanguageStatus(false)
      }}>
      <StyledLanguageHeaderContainer>
        <div>
          <FontAwesomeIcon icon={faArrowLeft} />
        </div>
        <StyledLanguageHeader>
          <FormattedMessage id="nav::language" defaultMessage="Language" />
        </StyledLanguageHeader>
      </StyledLanguageHeaderContainer>
      {languageList}
    </div>
  )

  return (
    <StyledMobileMenuWrapper>
      <StyledMobileNavBarLogo
        onClick={() => {
          closeModal()
        }}>
        <NavbarLogo flat={false} width={150} height={50} />
      </StyledMobileNavBarLogo>
      <StyledMobileMenuItemList>
        {isAuthenticated
          ? languageStatus
            ? languageSelector
            : menuItemsToList?.map((item, index) => {
                return (
                  <>
                    <StyledMobileMenuItem
                      key={item?.label}
                      onClick={() => {
                        if (typeof item?.handler === "function") {
                          item?.handler()
                        }

                        if (item?.link?.length > 0) {
                          navigate(item?.link)
                        }
                        closeModal()
                      }}>
                      {item.rawText === "language" ? (
                        <StyledLanguageMenuItem
                          onClick={(e) => {
                            e.stopPropagation()
                            e.preventDefault()
                            setLanguageStatus(true)
                          }}>
                          <div>{item?.label}</div>
                          <div className="language">
                            <LanguageBar disabled height={16} />
                          </div>
                        </StyledLanguageMenuItem>
                      ) : (
                        item?.label
                      )}
                    </StyledMobileMenuItem>
                    <StyledLine />
                  </>
                )
              })
          : languageList}
      </StyledMobileMenuItemList>
      {!isAuthenticated && (
        <StyledMobileLanguageBar onClick={closeModal}>
          <StyledGetStartedButtonContainerMobile>
            <StyledGetStartedButtonMobile
              to="/signup"
              color={theme.colors.pacificBlue}
              onClick={handleSignup}
              data-testid="btn-get-started">
              <FormattedMessage
                id="nav::getStartedMobile"
                defaultMessage="Sign in / Sign up"
              />
            </StyledGetStartedButtonMobile>
          </StyledGetStartedButtonContainerMobile>
        </StyledMobileLanguageBar>
      )}
    </StyledMobileMenuWrapper>
  )
}

export default MobileMenu
